<template>
  <div :class="['input-select', read_only ? 'disabled' : '']">
    <!-- usando il v-model non ri riusciva a chiamare isvalid perchè il .value dell\'input risultava vuoto -->
    <input ref="input" class="fake-elm" name="input-code" type="text"
           v-model="currentItem.value"
           autocomplete="off"
           :required="required"/>
    <input name="input-label" type="hidden" v-model="currentItem.label" />
    <input name="input-search" type="text"
           :class="['form-control input-current', isValid ? '': 'is-invalid']"
           v-model="searchString"
           autocomplete="off"
           :readonly="read_only"
           @focus="scrollAndEnsureVisible(true)"/>
    <action-icon :class="['input-command', showOptions && !read_only ? 'opened' : 'closed']"
                 @click="scrollAndEnsureVisible(!showOptions)">
      <chevron-down-icon></chevron-down-icon>
    </action-icon>
    <div ref="optionslist" :class="['input-options', showOptions && !read_only ? '' : 'hidden']">
      <div v-for="(option, index) in filteredOptions"
           v-bind:key="index"
           @click="select(option)"
           :class="['input-option', option.value === currentItem.value ? 'selected' : '']">{{ option.label }}</div>
      <div v-if="filteredOptions.length === 0"
           class="input-option no-results">
        {{ $t('layout.list.no-results') }}
      </div>
    </div>
    <div class="invalid-feedback position-absolute">
      {{ $t('message.please-provide-valid-value') }}
    </div>
  </div>
</template>

<script>
import { includes } from 'lodash'
import ActionIcon from '../layout/ActionIcon'
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown'

export default {
  name: 'InputSelect',
  components: { ChevronDownIcon, ActionIcon },
  data () {
    return {
      externalChange: false,
      searchString: '',
      currentItem: {
        value: null,
        label: ''
      },
      showOptions: false,
      isValid: true
    }
  },
  props: {
    currentValue: {
      validator: prop => typeof prop === 'string' || prop === null || typeof prop === 'number',
      default: null
    },
    read_only: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    selectAndClean: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => {
        if (process.env.NODE_ENV === 'development') {
          return [
            {
              value: 0,
              label: 'zero 00000000 000000000 00000000000',
              search: '0 zero 00000000 000000000 00000000000'
            },
            {
              value: 1,
              label: 'one',
              search: '1 one'
            },
            {
              value: 2,
              label: 'one2',
              search: '2 one2'
            }
          ]
        } else {
          return []
        }
      }
    }
  },
  computed: {
    filteredOptions () {
      return this.options.filter((opt) => {
        if (this.searchString === this.currentItem.label) {
          return true
        } else {
          return includes(opt.search.toLowerCase(), this.searchString.toLowerCase()) ||
            includes(opt.id, this.searchString)
        }
      }).sort((a, b) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
      })
    },
    currentItemValue () {
      return this.currentItem.value
    }
  },
  methods: {
    scrollAndEnsureVisible (visible) {
      this.showOptions = visible
      // console.log(visible)
      if (visible) {
        this.$nextTick(() => {
          let selectedDomElement = this.$refs.optionslist.querySelector('.selected')
          if (selectedDomElement) {
            this.$refs.optionslist.scrollTop = selectedDomElement.offsetTop
          }
        })
      }
    },
    checkValidity () {
      this.isValid = this.$refs.input.checkValidity()
      // if (this.$canLog(2)) console.log('[InputSelect]', this.$refs.input, this.$refs.input.value, this.$refs.input.checkValidity())
      this.$emit('changeValidity', { target: this.$refs.input, value: this.isValid })
    },
    clean () {
      this.currentItem = {
        value: null,
        label: ''
      }
      this.searchString = ''
    },
    select (item) {
      this.externalChange = false
      this.currentItem = item
      this.searchString = item.label
      this.showOptions = false
    }
  },
  mounted () {
    this.$refs.input.value = this.currentItem.value
    this.searchString = this.currentItem.label
    this.checkValidity()
  },
  watch: {
    currentItemValue: {
      handler: function (value, oldValue) {
        if (value !== oldValue) {
          this.$nextTick(() => {
            this.checkValidity()
            if (!this.externalChange) {
              this.$emit('selected', this.currentItem)
              if (this.selectAndClean) {
                this.externalChange = true
                this.clean()
              }
            }
          })
        }
      }
    },
    currentValue: {
      handler: function (value, oldValue) {
        // se entrambi null o undefined non devono cambiare valore
        if ((value || oldValue) && value !== oldValue) {
          if (this.$canLog(2)) console.log('[InputSelect] currentValue changed', value, oldValue)
          let result = this.options.filter((opt) => opt.value === value)
          if (result.length === 0) {
            if (this.$canLog(1)) console.warn('[InputSelect] currentValue not found', value, oldValue)
          }
          if (this.$canLog(2)) console.log('[InputSelect] currentItem ', result[0])

          this.externalChange = true
          this.currentItem = result.length > 0 ? result[0] : { value: null, label: '' }

          this.searchString = this.currentItem.label
          if (this._isMounted) {
            this.$refs.input.value = this.currentItem.value
            this.checkValidity()
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .input-select {
    position: relative;

    &.disabled {
      .input-command {
        color: $light-grey;
      }
    }
    .fake-elm {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    .input-current {
      padding-right: 2em;
      text-overflow: ellipsis;

      &.is-invalid {
        border-color: #f86c6b !important;
      }
    }

    .input-command {
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      color: #23282c;

      font-size: 1.4em;
      /*background-color: white;*/

      &.opened {
        transform: rotate(180deg) translateY(50%);
      }
    }

    .input-options {
      position: absolute;
      z-index: $zindexInteraction;

      background-color: white;
      border: 1px solid #E4E7EA;
      border-top: 0;
      width: 100%;

      max-height: 250px;
      overflow-y: auto;

      &.hidden {
        display: none;
      }

      .input-option {
        padding: .5em 1em;

        &:not(.no-results) {
          cursor: pointer;

          &:hover {
            background-color: $light-grey;
          }

          &.selected {
            font-weight: bold;
            background-color: $tableRow;
          }
        }

        &.no-results {
          font-style: italic;
          color: $light-grey;
        }
      }
    }
  }
</style>