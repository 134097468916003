import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home'
import NotImplemented from './views/Errors/NotImplemented'
import Guidelines from './views/Guidelines.vue'
import Dashboard from './views/Dashboard.vue'
import Settings from './views/Settings'

import Processes from './views/Status/Processes'
import MonitoredMachines from './views/Status/MonitoredMachinesList'
import AnomaliesList from './views/Anomalies/List'
import AnomaliesDetails from './views/Anomalies/Details'
import ServicesList from './views/Services/List'
import ServicesEdit from './views/Services/Edit'
import AccessDenied from './views/Errors/AccessDenied'
import MachinesList from './views/Machines/List'
import MachinesEdit from './views/Machines/Edit'
import DriversList from './views/Drivers/List'
import DriversEdit from './views/Drivers/Edit'
import MappingsList from './views/Mappings/List'
import MappingsEdit from './views/Mappings/Edit'
import DataChecksList from './views/DataChecks/List'
import QueriesList from './views/Queries/List'

import store from '@/store'
import { oAuth2Config } from '@/config/oauth2.config.js'
import { OAuth2Client } from '@/lib/oauth2'
import oAuth2RouterMiddleware from '@/lib/oauth2/oauth-middleware'
import permissionRouterMiddleware from '@/lib/permissions/router-middleware'
import helpers from './lib/helpers'
import { has } from 'lodash'

import Cookies from 'js-cookie'
import { isAuthenticated } from './lib/oauth2'

const oauth2Cli = new OAuth2Client(oAuth2Config)

Vue.use(Router)

let enableAuthProtection = true // @TODO settare di conseguenza quando OIDServer è settato correttamente
if (!enableAuthProtection) {
  console.error('*********************************************************************\n' +
    'OID AUTH Ignorata, ricordarsi di abilitarla quando il server funziona\n' +
    '*********************************************************************')
}

const router = new Router({
  mode: 'history',
  base: has(window, 'dcsClientCfg.router.base') ? window.dcsClientCfg.router.base : '/', // process.env.NODE_ENV === 'production' ? window.dscClientCfg.BaseURL : '/',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        isPublic: true
      }
    },
    {
      path: '/dashboard',
      component: Dashboard,
      name: 'dashboard',
      meta: {
        isPublic: false
      }
    },
    {
      path: '/settings',
      component: Settings,
      name: 'settings',
      meta: {
        isPublic: false
      }
    },
    {
      path: '/dashboard',
      component: Dashboard,
      name: 'login',
      meta: {
        isPublic: false
      }
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        isPublic: true
      }
    },
    {
      path: '/end',
      name: 'endSession',
      meta: {
        isPublic: true
      }
    },
    {
      path: '/guidelines',
      name: 'guidelines',
      component: Guidelines,
      meta: {
        isPublic: true
      }
    },
    // OID Auth
    {
      path: '/callback',
      name: 'AuthCallback',
      meta: {
        isAuthCallback: true,
        isPublic: true
      }
    },
    // API
    {
      path: '/status/processes',
      name: 'dcs.status.processinfo',
      component: Processes,
      meta: {
        isPublic: false
      }
    },
    {
      path: '/status/monitored-machines',
      name: 'dcs.status.monitoredmachines',
      component: MonitoredMachines,
      meta: {
        isPublic: false
      },
      children: [
        {
          path: ':id',
          name: 'dcs.monitored-machines.edit',
          component: MachinesEdit,
          props: true,
          meta: {
            isPublic: false
          }
        }
      ]
    },
    {
      path: '/status/versions',
      name: 'dcs.status.versioninfo',
      component: NotImplemented,
      meta: {
        isPublic: false
      }
    },
    {
      path: '/machines',
      name: 'dcs.machines.list',
      component: MachinesList,
      meta: {
        isPublic: false
      },
      children: [
        {
          path: ':id',
          name: 'dcs.machines.edit',
          component: MachinesEdit,
          meta: {
            isPublic: false
          }
        }
      ]
    },
    {
      path: '/drivers',
      name: 'dcs.drivers.list',
      component: DriversList,
      meta: {
        isPublic: false
      },
      children: [
        {
          path: ':id',
          name: 'dcs.drivers.edit',
          component: DriversEdit,
          meta: {
            isPublic: false
          }
        }
      ]
    },
    {
      path: '/devices',
      name: 'dcs.devices.list',
      component: NotImplemented,
      meta: {
        isPublic: false
      }
    },
    {
      path: '/mappings',
      name: 'dcs.mappings.list',
      component: MappingsList,
      meta: {
        isPublic: false
      },
      children: [
        {
          path: ':id',
          name: 'dcs.mappings.edit',
          component: MappingsEdit,
          meta: {
            isPublic: false
          }
        }
      ]
    },
    {
      path: '/anomalies',
      name: 'dcs.anomalies.list',
      component: AnomaliesList,
      meta: {
        isPublic: false
      },
      children: [
        {
          path: ':id',
          name: 'dcs.anomalies.details',
          component: AnomaliesDetails,
          meta: {
            isPublic: false
          }
        }
      ]
    },
    {
      path: '/services/:type',
      name: 'dcs.services.list',
      component: ServicesList,
      meta: {
        isPublic: false
      },
      children: [
        {
          path: ':id',
          name: 'dcs.services.edit',
          component: ServicesEdit,
          meta: {
            isPublic: false
          }
        }
      ]
    },
    {
      path: '/data-checks',
      name: 'dcs.data-checks.list',
      component: DataChecksList,
      meta: {
        isPublic: false
      }
    },
    {
      path: '/queries/:category',
      name: 'dcs.queries.list',
      component: QueriesList,
      meta: {
        isPublic: false
      }
    },
    {
      path: '/access-denied',
      name: 'dcs.error.access-denied',
      component: AccessDenied,
      meta: {
        isPublic: true
      }
    }
  ]
})

const showAccessDenied = (to, next) => {
  if (to.name === 'dcs.error.access-denied') {
    next()
  } else {
    next({
      name: 'dcs.error.access-denied',
      replace: true
    })
  }
}

router.beforeEach((to, from, next) => {
  if (helpers.canLog(2)) console.log('[Router] beforeEach', to.name)
  oAuth2RouterMiddleware({ store: store, vuexNamespace: 'oauth2', oauth2Cli: oauth2Cli }, to, from, next)
    .then((result) => {
      if (result) {
        if (helpers.canLog(2)) console.log('[Router] oAuth2 granted', result)
        let requestedUrl = Cookies.get('requested_url')
        if (helpers.canLog(2)) console.log('[Router] requestedUrl from cookie', requestedUrl)
        Cookies.remove('requested_url')
        if (requestedUrl !== undefined) {
          next(requestedUrl)
        } else {
          permissionRouterMiddleware({ store: store }, to, from, next)
            .then((result) => {
              if (result) {
                if (helpers.canLog(2)) console.log('[Router] Permission granted', result)
                if (to.name === 'home' && isAuthenticated()) {
                  if (helpers.canLog(4)) console.log('[Router] Go to Dashboard')
                  next({ name: 'dashboard' })
                } else {
                  next()
                }
              } else {
                if (helpers.canLog(2)) console.log('[Router] Permission denied')
                showAccessDenied(to, next)
              }
            })
            .catch((e) => {
              // next({ name: '404' })
              if (helpers.canLog(2)) console.log('[Router] Permission denied (2)', e.message, to.name)
              showAccessDenied(to, next)
            })
        }
      }
    })
    .catch((e) => {
      if (helpers.canLog(2)) console.log('[Router] middleware rejected ' + e.message)
    })
})

export default router
