<template>
  <div class="form-row input-text">
    <label :for="name"
           :class="['col-form-label', labelClass]">
      <span v-tooltip="tooltip">{{ label }}:</span>
    </label>
    <div :class="['input-cell mb-4', inputClass]">
      <input-select v-if="loaded"
                    :id="name"
                    :currentValue="currentValue"
                    :options="options"
                    :read_only="read_only"
                    :required="required"
                    @changeValidity="onChangeValidity"
                    @selected="($item) => { $emit('selected', $item); $emit('input', $item.value) }"></input-select>
      <div v-else class="input-loading form-control">{{ $t('message.loading') }}</div>
    </div>
  </div>
</template>

<script>
import InputSelect from './InputSelect'

export default {
  name: 'InputFormSelect',
  components: { InputSelect },
  props: {
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: 'col-md-2'
    },
    inputClass: {
      type: String,
      default: 'col-md-10'
    },
    name: {
      type: String,
      default: 'auto-' + Math.floor(Math.random() * Math.floor(9999)).toString()
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      validator: prop => typeof prop === 'string' || prop === null || typeof prop === 'number',
      default: null
    },
    read_only: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: () => [
        {
          value: 0,
          label: 'zero 00000000 000000000 00000000000',
          search: '0 zero 00000000 000000000 00000000000'
        },
        {
          value: 1,
          label: 'one',
          search: '1 one'
        },
        {
          value: 2,
          label: 'one2',
          search: '2 one2'
        }
      ]
    },
    tooltip: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChangeValidity (e) {
      let theForm = e.target.closest('form')
      if (theForm) {
        theForm.classList.add('was-validated')
      } else {
        if (this.$canLog(2)) console.log('[InputFormSelect ' + this.name + '] cannot find containing form', e.target)
      }
      this.$emit('changeValidity', e)
    }
  },
  computed: {
    currentValue () {
      return this.loaded ? this.value : null
    }
  }
}
</script>

<style lang="scss">
</style>