<template>
  <div class="form-row input-text">
    <label :for="name" class="col-2 col-form-label">{{ label }}:</label>
    <div class="col-10 mb-4 text-error">
      {{ $t('message.error') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorFormElement',
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: Math.random().toString()
    }
  }
}
</script>

<style lang="scss" scoped>
.text-error {
  padding-top: .375em;
}
</style>