<template>
  <div class="container-fluid processes-info">
    <div class="card">
      <div class="card-header text-uppercase">
        <format-list-bulleted-icon></format-list-bulleted-icon>
        {{ $t('menu.process-info') }}
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <refresher class="float-right"
                       :seconds="autoRefresh('ProcessInfo')"
                       :enabled="refresherEnabled"
                       @tick="onRefresherTick"></refresher>
          </div>
        </div>
        <div v-if="loaded">
        <div class="row" v-for="(entry, index) in data.entries" v-bind:key="index">
          <div class="col-md-2 text-capitalize font-weight-bold">
            {{ entry.label }}
          </div>
          <div class="col-auto">
            {{ entry.value }}
          </div>
        </div>
        </div>
        <div class="row chart mt-5 justify-content-around" v-if="loaded">
          <div class="col-md-5" v-for="(entry, index) in data.systemTimeSeries" v-bind:key="index">
            <div class="chart-wrapper">
              <a class="ml-4" href="#" @click.prevent="resetZoom('chart' + index)">{{ $t('layout.chart.reset-zoom') }}</a>
              <time-serie :id="'chart' + index"
                          :points="entry.points"
                          :title="entry.timeSeries.localizedName"
                          :x-label="$t('layout.chart.time')"
                          :y-label="entry.yAxisLabel"
                          :ref="'chart' + index"></time-serie>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted'
import Refresher from '../../components/Refresher'
import { events } from '@/lib/messages'

import TimeSerie from '../../components/charts/TimeSerie'

export default {
  name: 'Processes',
  components: { TimeSerie, Refresher, FormatListBulletedIcon },
  data: () => {
    return {
      loaded: false,
      refresherEnabled: false
    }
  },
  computed: {
    ...mapGetters({
      data: 'statuses/processInfos',
      autoRefresh: 'settings/tableAutoRefresh'
    })
  },
  methods: {
    ...mapActions({
      load: 'statuses/loadProcessInfos'
    }),
    resetZoom (refName) {
      events.$emit('resetZoom', refName)
    },
    onRefresherTick () {
      this.refresherEnabled = false
      this.load(true).finally(() => {
        events.$emit('refresh-page')
        this.refresherEnabled = true
      })
    }
  },
  mounted () {
    this.load().finally(() => {
      this.loaded = true
      this.refresherEnabled = true
    })
  }
}
</script>

<style lang="scss" scoped>
.row.chart {
  &:last-of-type {
    &:after {
      @include media-breakpoint-up(md) {
        content: "";
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      }
    }
  }
}
  .chart-wrapper {
    a {
      font-size: .8em;
      color: $dark-grey;

      &:hover{
        color: $slate-grey;
      }
    }
  }

</style>