<template>
  <div class="col-auto">
    <h1>Access denied</h1>
    <p>You haven't the right permission to view this resource</p>
  </div>
</template>

<script>
export default {
  name: 'AccessDenied'
}
</script>

<style scoped>

</style>