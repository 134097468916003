import { render, staticRenderFns } from "./logsOperations.vue?vue&type=template&id=513f86e8&scoped=true&"
import script from "./logsOperations.vue?vue&type=script&lang=js&"
export * from "./logsOperations.vue?vue&type=script&lang=js&"
import style0 from "./logsOperations.vue?vue&type=style&index=0&id=513f86e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "513f86e8",
  null
  
)

component.options.__file = "logsOperations.vue"
export default component.exports