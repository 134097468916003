const publicConfig = window.dcsClientCfg

export const oAuth2Config = {
  clientId: publicConfig.OAuth.Client.id, // process.env.VUE_APP_OAUTH_CLIENT_ID,
  clientSecret: publicConfig.OAuth.Client.secret, // process.env.VUE_APP_OAUTH_CLIENT_SECRET,
  accessTokenUri: publicConfig.OAuth.URIs.TokenEndpoint, // process.env.VUE_APP_OAUTH_TOKEN_ENDPOINT_URI,
  authorizationUri: publicConfig.OAuth.URIs.Authorization, // process.env.VUE_APP_OAUTH_AUTHORIZATION_URI,
  userInfoUri: publicConfig.OAuth.URIs.UserInfo, // process.env.VUE_APP_OAUTH_USERINFO_URI,
  endSessionUri: publicConfig.OAuth.URIs.EndSession, // process.env.VUE_APP_OAUTH_END_SESSION_URI,
  // loginUri: '/login',
  redirectUri: 'callback', // process.env.VUE_APP_OAUTH_REDIRECT_URI,
  postLogoutUri: 'end',
  scopes: publicConfig.OAuth.Scope // process.env.VUE_APP_OAUTH_SCOPE
}