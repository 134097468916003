<template>
  <div :class="['exit', data.Enabled ? 'enabled' : '', position]">
    <div class="row-actions">
      <div class="abilita"
           @click="data.Enabled = !data.Enabled">
        <div class="exit-number">{{ number + 1 }}</div>
        <div class="bin-icon">
          <BasketDisabledIcon v-if="!data.Enabled"></BasketDisabledIcon>
          <component v-else
                     :is="icon"></component>
        </div>
      </div>
      <div class="row-actions-more" @click="(e) => { e.target.classList.toggle('open') }">
        <action-icon @click="$emit('delete-action', { number: number, position: position })">
          <minus-circle-icon :title="$t('layout.edit.panel-routing-remove-exit')"></minus-circle-icon>
        </action-icon>
      </div>
    </div>
    <div class="ricerca">
      <input-select :options="articlesAsOptionsForSelect"
                    :select-and-clean="true"
                    :read_only="selectDisabled"
                    @selected="addArticle"></input-select>

    </div>
    <div class="valore">
      <textarea v-model="data.Specifications" v-autoscroll></textarea>
    </div>
  </div>
</template>

<script>
import InputSelect from '../InputSelect'
import BasketDisabledIcon from './BasketDisabledIcon'
import BasketTopIcon from './BasketTopIcon'
import BasketLeftIcon from './BasketLeftIcon'
import BasketRightIcon from './BasketRightIcon'
import ActionIcon from '../../layout/ActionIcon'
import MinusCircleIcon from 'vue-material-design-icons/MinusCircle'
import { mapActions, mapGetters } from 'vuex'
import { Autoscroll } from '@/directives/autoscroll'
import DotsHorizontalIcon from 'vue-material-design-icons/DotsHorizontal'

export default {
  name: 'Exit',
  components: { DotsHorizontalIcon, MinusCircleIcon, ActionIcon, BasketTopIcon, BasketLeftIcon, BasketRightIcon, BasketDisabledIcon, InputSelect },
  directives: {
    Autoscroll
  },
  data: () => {
    return {
      enabled: false,
      articlesLoaded: false,
      showRowActions: false
    }
  },
  props: {
    position: {
      type: String,
      default: 'left' // top, right, top right
    },
    number: {
      type: Number,
      default: -1
    },
    data: {
      type: Object,
      default: () => { return { id: '-', Enabled: false } }
    },
    selectDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      articles: 'articles/all'
    }),
    articlesAsOptionsForSelect () {
      if (this.articles === null) {
        return []
      }
      return this.articles.map((itm) => {
        return {
          value: itm.code,
          label: '[' + itm.code + '] ' + itm.description,
          search: itm.code + ' ' + itm.description
        }
      })
    },
    dataEnabled () {
      return this.data.Enabled
    },
    dataSpecifications () {
      return this.data.Specifications
    },
    icon () {
      let name = this.position.replace('top right', 'top').replace('top left', 'top')
      return 'Basket' + name.charAt(0).toUpperCase() + name.slice(1) + 'Icon'
    }
  },
  methods: {
    ...mapActions({
      loadArticles: 'articles/load'
    }),
    addArticle (item) {
      this.data.Specifications = (this.data.Specifications + '\n' + item.value).trim()
    }
  },
  mounted () {
    // this.loadArticles().finally(() => {
    //   this.articlesLoaded = true
    // })
  },
  watch: {
    dataSpecifications: {
      handler: function (value, oldValue) {
        if (value !== oldValue) {
          this.$emit('updateCode', { id: this.number, data: this.data })
        }
      }
    },
    dataEnabled: {
      handler: function (value, oldValue) {
        if (value !== oldValue) {
          this.$emit('updateCode', { id: this.number, data: this.data })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.exit {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 80px;
  width: 100%;

  justify-content: center;
  align-items: stretch;

  .abilita {
    background-color: $white;
  }

  &.enabled {
    .abilita {
      color: $white;
      background-color: $cerulean;
      .exit-number {
        color: $white;
      }
    }

    .ricerca, .valore {
      color: $black;
      textarea { color: $black; }
    }

    &.right {
      .abilita {
        background-color: $danger;
      }
    }

    &.top {
      .abilita {
        background-color: $gold;
      }
    }
  }

  &.right {
    flex-direction: row-reverse;

    .abilita, .ricerca, .valore {
      margin: 0 0 0 5px;
    }

    .row-actions {
      .abilita {
        margin-right: 0;
        margin-left: 20px;
      }

      .row-actions-more {
        right: calc(100% - 20px);
        left: auto;
        transform: translateX(calc(100% - 12px));

        &:hover {

        }
      }
    }

    .valore {
      flex-direction: row-reverse;

      textarea {
      }
    }
  }

  &.top {
    .routes {
      opacity: 0;
    }
  }

  textarea {
    border: 0;
    color: $dark-grey;
    resize: none;
  }

  .abilita, .ricerca, .valore {
    position: relative;
    margin: 0 5px 0 0;
    border: 1px solid $light-grey;
    border-radius: 3px;
    color: $dark-grey;
  }

  .row-actions {
    position: relative;
    cursor: pointer;

    .abilita {
      width: 45px;
      margin-right: 20px;
      height: 100%;
      cursor: pointer;
      color: $light-grey;
      position: relative;
      z-index: $zindexInteraction - 1;

      .exit-number {
        display: block;
        text-align: center;
        padding-top: 5px;
      }

      .bin-icon {
        $width: 20px;
        position: absolute;
        bottom: 10px;
        left: calc(50% - #{$width} / 2);
        width: $width;
        height: auto;
      }
    }

    .row-actions-more {
      position: absolute;
      z-index: $zindexInteraction - 2;
      height: 100%;
      top: 0;
      left: calc(100% - 20px);
      width: 45px;

      transform: translateX(calc(-100% + 12px));
      transition: opacity 0.15s  ease-out, transform 0.2s ease-out;

      background-color: $light-grey;
      opacity: .6;

      border-radius: 3px;

      &.open {
        opacity: 1;
        transform: none;
      }

      .action-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        font-size: 1.5em;
        color: $red;
      }
    }
  }

  .ricerca, .valore {
    flex-grow: 1;
  }

  .valore {
    textarea {
      width: 100%;
      height: 100%;

      outline: none;
    }
  }

}
</style>