<template>
  <div class="form-row input-multi-toggle">
    <label :for="name" :class="['col-form-label', labelClass]">{{ label }}:</label>
    <div :class="['mb-4', inputClass]">
      <div class="options-wrapper">
        <div @click="onInput(option.value)"
             :class="['option', option.value === currentValue ? 'selected' : '']"
             v-for="(option, index) in options" v-bind:key="index">
          {{ option.label }}
        </div>
      </div>
      <div class="invalid-feedback position-absolute">
        {{ $t('message.please-provide-valid-value') }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InputFormMultiToggle',
  props: {
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: 'col-md-2'
    },
    inputClass: {
      type: String,
      default: 'col-md-10'
    },
    name: {
      type: String,
      default: 'auto-' + Math.floor(Math.random() * Math.floor(9999)).toString()
    },
    read_only: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => [
        {
          value: 0,
          label: 'zero 00000000 000000000 00000000000'
        },
        {
          value: 1,
          label: 'one'
        },
        {
          value: 2,
          label: 'one2'
        }
      ]
    },
    value: {
      validator: prop => typeof prop === 'boolean' || prop === null,
      default: false
    }
  },
  data () {
    return {
      currentValue: null,
      isValid: true
    }
  },
  computed: {
  },
  methods: {
    onInput (e) {
      this.currentValue = e
      this.$emit('input', e)
    }
  },
  mounted () {
    this.currentValue = this.value
  },
  watch: {
    value: {
      handler: function (value, oldVal) {
        if (this.$canLog(4)) console.log('[MultiToggle] value changed', value, oldVal, this.currentValue)
        if (value !== oldVal && value !== this.currentValue) {
          if (this.$canLog(4)) console.log('[MultiToggle] setting new value', value)
          this.currentValue = value
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .input-multi-toggle {
    border-color: transparent;
    width: auto;
    margin-bottom: 0;

    .options-wrapper {
      display: flex;
      flex-direction: row;

      .option {
        padding: .3rem .9rem;
        border: 1px solid;
        border-right: 0;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:first-of-type {
          border-bottom-left-radius: 3px;
          border-top-left-radius: 3px;
        }

        &:last-of-type {
          border-bottom-right-radius: 3px;
          border-top-right-radius: 3px;
          border-right: 1px solid;
        }

        &.selected {
          background-color: $botticelli;
        }
      }
    }
  }
</style>