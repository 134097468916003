import Cookies from 'js-cookie'
import helpers from '../helpers'

export const objectAssign = (objects) => {
  return objects.reduce(function (r, o) {
    Object.keys(o || {}).forEach(function (k) {
      r[k] = o[k]
    })
    return r
  }, {})
}

export const parseJwt = (token) => {
  try {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  } catch (error) {
    return {}
  }
}

export const tokenExp = (token) => {
  if (token) {
    const parsed = parseJwt(token)
    // console.log(parsed)
    return parsed.exp ? parsed.exp : null
  }
  return null
}

export const tokenIsExpired = (token) => {
  const tokenExpiryTime = tokenExp(token)
  if (tokenExpiryTime) {
    let now = Date.now() / 1000
    // console.log(tokenExpiryTime, now, (tokenExpiryTime < now) ? 'EXPIRED!' : 'OK')
    return tokenExpiryTime < now
  }
  return false
}

export const generateCode = () => {
  let r = Math.random().toString(36).substring(7)
  return r
}

export const generateHttpBasicAuth = (clientId, clientSecret) => {
  var token = clientId + ':' + clientSecret
  var hash = btoa(token)
  return 'Basic ' + hash
}

export function idToken () {
  return Cookies.get('id_token')
}

export function accessToken () {
  return Cookies.get('access_token')
}

export const isAuthenticated = () => {
  let accessToken = Cookies.get('access_token')
  if (accessToken !== undefined && !tokenIsExpired(accessToken)) {
    if (helpers.canLog(3)) console.log('[oAuth lib] isAuthenticated? yes!')
    return true
  }
  // if (state.id_token && !tokenIsExpired(state.id_token)) {
  //   return true
  // }
  if (helpers.canLog(3)) console.log('[oAuth lib] isAuthenticated? no!')
  return false
}