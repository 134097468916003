import Swagger from 'swagger-client'
import { apiConfig } from '@/config/api.config'
import axios from 'axios'
import Cookies from 'js-cookie'
import helpers from '@/lib/helpers'

function loadSpecFile (specUrl, storage) {
  return new Promise((resolve, reject) => {
    if (helpers.canLog(2)) console.log('[Swagger] loadSpecFile')
    let theSpecFile = localStorage.getItem(storage)
    if (theSpecFile === null) {
      if (helpers.canLog(2)) console.log('[Swagger] loadSpecFile from url', specUrl)
      axios({
        url: specUrl,
        method: 'GET',
        responseType: 'application/json'
      }).then((data) => {
        localStorage.setItem(storage, JSON.stringify(data.data))
        resolve(localStorage.getItem(storage))
      }).catch((e) => reject(e))
    } else {
      if (helpers.canLog(2)) console.log('[Swagger] loadSpecFile from localstore')
      resolve(theSpecFile)
    }
  })
}

export function clearApiDef () {
  localStorage.removeItem(apiConfig.localStorageSpecKeyControl)
  localStorage.removeItem(apiConfig.localStorageSpecKeyData)
  if (helpers.canLog(2)) {
    console.log('[Swagger] removing spec cache',
      apiConfig.localStorageSpecKeyControl, apiConfig.localStorageSpecKeyData)
  }
}

export function controlApi (accessToken) {
  return new Promise((resolve, reject) => {
    if (helpers.canLog(2)) console.log('[Swagger] client loading', Cookies.get('locale'))
    loadSpecFile(apiConfig.controlAPISpecUrl, apiConfig.localStorageSpecKeyControl).then((theSpecFile) => {
      let config = {
        spec: JSON.parse(theSpecFile),
        mode: 'no-cors',
        requestInterceptor: (req) => {
          req.headers.Authorization = 'Bearer ' + accessToken
          req.headers.Accept = '*/*'
          if (Cookies.get('locale')) {
            req.headers['Accept-Language'] = Cookies.get('locale')
          }
          return req
        }
      }

      Swagger(config).then((client) => {
        if (helpers.canLog(3)) console.log('[Swagger] client loaded; errors?', client.errors)
        resolve(client)
      }).catch((e) => {
        reject(e)
      })
    }).catch((e) => reject(e))
  })
}

export function dataApi (accessToken) {
  return new Promise((resolve, reject) => {
    if (helpers.canLog(2)) console.log('[Swagger] client loading', Cookies.get('locale'))
    loadSpecFile(apiConfig.dataAPISpecUrl, apiConfig.localStorageSpecKeyData).then((theSpecFile) => {
      let config = {
        spec: JSON.parse(theSpecFile),
        mode: 'no-cors',
        requestInterceptor: (req) => {
          req.headers.Authorization = 'Bearer ' + accessToken
          req.headers.Accept = '*/*'
          if (Cookies.get('locale')) {
            req.headers['Accept-Language'] = Cookies.get('locale')
          }
          return req
        }
      }

      Swagger(config).then((client) => {
        if (helpers.canLog(3)) console.log('[Swagger] client loaded; errors?', client.errors)
        resolve(client)
      }).catch((e) => {
        reject(e)
      })
    }).catch((e) => reject(e))
  })
}