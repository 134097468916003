import store from '@/store'
import routerMiddleware from './router-middleware.js'
import permissionsModule from './vuex-module.js'
import { events } from '@/lib/messages'
import { get } from 'lodash'
import helpers from '@/lib/helpers'

const permissionsPlugin = {
  install: (Vue) => {
    events.$on('logged-in', () => {
      if (helpers.canLog(2)) console.log('[Perms] logged-in received')
      store.dispatch('permissions/list')
    })
    events.$on('logged-out', () => {
      if (helpers.canLog(2)) console.log('[Perms] logged-out received')
    })
    Vue.prototype.$hasPerms = (path) => {
      return permissionResolver(path)
    }
    Vue.prototype.$bitPerms = (i, bits) => {
      // let i = where.indexOf(what) + 1
      let perm = bits & i
      // console.log('[bitperms] ' + (perm ? 'allowed to ' : 'NOT allowed to ') + i)
      return perm !== 0
    }
  }
}
export default permissionsPlugin

export const permissionResolver = (path) => {
  let perms = store.getters['permissions/get']
  if (typeof perms.dcs !== 'undefined') {
    let pathPermission = get(perms, path)
    switch (pathPermission) {
      case true:
        // console.log('[perms] Allowed to ', path)
        return true
      case false:
        if (helpers.canLog(1)) console.warn('[Perms] NOT Allowed to', path)
        // throw new Error('not allowed')
        return false
      default:
        // if (helpers.canLog(3)) console.log('[Perms] no infos: bypass', path)
        return true
    }
  } else {
    if (helpers.canLog(3)) console.log('[Perms] no infos: bypass (2)', path)
    return true
  }
}

export const permissionRouterMiddleware = routerMiddleware
export const permission = permissionsModule