<template>
  <div :data-id="index" :class="['alert text-center', getClass(message)]" @click="dismiss(index)">
    <span class="close-icon"><close-icon></close-icon></span>
    <p class="message-title text-center" v-if="message.title">{{ message.title }}</p>
    {{ message.text }}

  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { messageTypes } from '@/lib/messages'
import CloseIcon from 'vue-material-design-icons/Close'

export default {
  name: 'Message',
  components: { CloseIcon },
  props: {
    message: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapMutations({
      removeMessage: 'messages/removeMessage'
    }),
    getClass (message) {
      switch (message.level) {
        case messageTypes.ERROR:
          return 'alert-danger'
        case messageTypes.WARNING:
          return 'alert-warning'
        case messageTypes.SUCCESS:
          return 'alert-success'
        default:
          return 'alert-info'
      }
    },
    dismiss (index) {
      this.removeMessage(index)
    },
    delayedDismiss () {
      let timeout = 3000
      if (this.message.level === messageTypes.ERROR) {
        timeout = 10000
      }
      setTimeout(() => {
        this.dismiss(this.index)
      }, timeout)
    }
  },
  mounted () {
    this.delayedDismiss()
  },
  updated () {
    this.delayedDismiss()
  }
}
</script>
<style lang="scss" scoped>
  .message-title {
    border-bottom: dotted 1px;
  }
</style>