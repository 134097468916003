<template>
  <table class="vuetable table table-bordered table-hover table-sm mb-0">
    <thead>
      <th v-for="(column, index) in columns" v-bind:key="index" :class="{'sortable': column.sortable, 'collapsed': collapsedMap.indexOf(index) >= 0}">
        <a href="#" @click.prevent="toggleColumn(index)" class="collapse-command">
          <minus-box-outline-icon :title="''" v-if="collapsedMap.indexOf(index) < 0" ></minus-box-outline-icon>
          <plus-box-outline-icon :title="column.title" v-else></plus-box-outline-icon>
        </a>
        <span v-if="collapsedMap.indexOf(index) < 0">{{ column.title }}</span>
        <a href="#" @click.prevent="changeSort(column)" class="sort-command" v-if="collapsedMap.indexOf(index) < 0">
          <unfold-more-horizontal-icon v-if="column.sortable && sortColumn !== column.key"></unfold-more-horizontal-icon>
          <chevron-down-icon v-if="sortColumn === column.key && sortDirection === 'DESC'"></chevron-down-icon>
          <chevron-up-icon v-if="sortColumn === column.key && sortDirection === 'ASC'"></chevron-up-icon>
        </a>
      </th>
    </thead>
    <tbody>
      <tr v-for="(row, index) in data" v-bind:key="index">
        <td v-for="(col, index) in row" v-bind:key="index" :class="{'collapsed': collapsedMap.indexOf(index) >= 0}">
          <span class="mobile-caption d-block d-md-none">
            <a href="#" @click.prevent="toggleColumn(index)" class="collapse-command">
              <minus-box-outline-icon :title="''" v-if="collapsedMap.indexOf(index) < 0" ></minus-box-outline-icon>
              <plus-box-outline-icon :title="''" v-else></plus-box-outline-icon>
            </a>
            {{ columns[index].title }}</span>
          <span v-if="collapsedMap.indexOf(index) < 0">{{ col }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { events } from '@/lib/messages'
import UnfoldMoreHorizontalIcon from 'vue-material-design-icons/UnfoldMoreHorizontal'
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown'
import ChevronUpIcon from 'vue-material-design-icons/ChevronUp'
import MinusBoxOutlineIcon from 'vue-material-design-icons/MinusBoxOutline'
import PlusBoxOutlineIcon from 'vue-material-design-icons/PlusBoxOutline'

export default {
  name: 'QueryTable',
  components: { PlusBoxOutlineIcon, MinusBoxOutlineIcon, ChevronUpIcon, ChevronDownIcon, UnfoldMoreHorizontalIcon },
  data () {
    return {
      columns: [],
      data: [],
      numResult: 0,
      runQuery: null,
      sortColumn: null,
      sortDirection: null
    }
  },
  props: {
    query: {
      type: Object,
      required: true
    },
    currentPage: {
      type: Number,
      default: 1
    },
    rowsPerPage: {
      type: Number,
      default: 15
    }
  },
  computed: {
    ...mapGetters({
      collapsedColumnsMap: 'settings/hiddenColumns'
    }),
    collapsedMap () {
      console.log(this.collapsedColumnsMap(this.$options.name))
      return this.collapsedColumnsMap(this.$options.name)
    },
    queryTs () {
      return this.query.timestamp
    }
  },
  methods: {
    ...mapMutations({
      setCollapsed: 'settings/hiddenColumns'
    }),
    ...mapActions({
      send: 'queries/send'
    }),
    toggleColumn (index) {
      console.log(index)
      let collapsed = this.collapsedMap
      if (collapsed.indexOf(index) >= 0) {
        collapsed.splice(collapsed.indexOf(index), 1)
      } else {
        collapsed.push(index)
      }
      this.setCollapsed({ key: this.$options.name, value: collapsed })
    },
    changeSort (column) {
      if (column.sortable) {
        if (this.sortColumn === column.key) {
          if (this.sortDirection === 'DESC') {
            this.sortDirection = 'ASC'
          } else {
            this.sortDirection = 'DESC'
          }
        } else {
          this.sortDirection = 'ASC'
        }
        this.sortColumn = column.key
        this.loadData()
      }
    },
    loadData () {
      let query = {
        key: this.runQuery.key,
        appliedFilters: this.runQuery.appliedFilters,
        pageIndex: this.currentPage,
        pageSize: this.rowsPerPage,
        sortFieldKey: this.sortColumn,
        isSortDescending: this.sortDirection === 'DESC'
      }
      this.send(query).then((data) => {
        this.rawData = JSON.parse(data)
        this.data = this.rawData.rows
        this.columns = this.rawData.columns.map((column, index) => {
          return {
            key: column.key,
            title: column.name,
            sortable: column.isNotSortable ? null : column.key
          }
        })
        this.numResult = this.rawData.pagingHeader.totalItemsCount
      })
    }
  },
  mounted () {
    events.$on('change-language', () => {
      if (this.$canLog(2)) console.log('[' + this.$options.name + '] invoked change language')
      this.loadData()
        .then(() => { if (this.$refs.vuetable) this.$refs.vuetable.reload() })
    })
    events.$on('refresh-page', () => {
      if (this.$canLog(2)) console.log('[' + this.$options.name + '] invoked refresh page')
      this.loadData()
    })
  },
  watch: {
    queryTs: {
      handler: function (value, oldValue) {
        if (value !== oldValue) {
          if (this.$canLog(3)) console.log('[QueryTable] query updated')
          this.runQuery = this.query
          this.loadData()
        }
      },
      immediate: true
    },
    numResult: {
      handler: function (value, oldValue) {
        if (value !== oldValue) {
          this.$emit('numResultChanged', value)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>