<template>
  <li :class="['nav-item nav-dropdown', '']">
    <a class="nav-link nav-dropdown-toggle" v-toggle-open>
      <toolbox-outline-icon :title="$t('menu.services')"></toolbox-outline-icon>
      {{ $t('menu.services') }}
    </a>
    <ul class="nav-dropdown-items" v-if="loaded">
      <li class="nav-item" v-for="serviceType in data" v-bind:key="serviceType.type">
        <router-link class="nav-link small" :to="{ name: 'dcs.services.list' , params: { type: serviceType.type }}">
          <wrench-outline-icon :title="serviceType.localizedName"></wrench-outline-icon>
          {{ serviceType.localizedName }}
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script>
import SidebarGroupToggler from '@/mixins/SidebarGroupToggler'
import ToolboxOutlineIcon from 'vue-material-design-icons/ToolboxOutline'
import WrenchOutlineIcon from 'vue-material-design-icons/WrenchOutline'
import { events } from '@/lib/messages'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SidebarGroupServices',
  components: { WrenchOutlineIcon, ToolboxOutlineIcon },
  mixins: [ SidebarGroupToggler ],
  data () {
    return {
      loaded: false,
      eventSystem: events
    }
  },
  computed: {
    ...mapGetters({
      data: 'services/allTypes'
    })
  },
  methods: {
    ...mapActions({
      load: 'services/loadTypes'
    }),
    reload (force = false) {
      this.loaded = false
      this.load(true).finally(() => {
        this.loaded = true
      })
    }
  },
  mounted () {
    this.reload()
    this.eventSystem.$on('change-language', () => {
      if (this.$canLog(2)) console.log('[SidebarGroupServices] invoked change language')
      this.reload(true)
    })
  }
}
</script>

<style lang="scss" scoped>
</style>