<template>
  <modal class="drivers-edit modal-edit"
         v-show="originalDriver.id !== null"
         :requestConfirmBeforeClose="hasModification"
         :confirmCloseMessage="$t('layout.edit.save-before-exit')"
         :return-route-name="'dcs.drivers.list'">
    <div slot="header">{{ $t('layout.edit.drivers-edit') }}: <span v-if="mainInfos.loaded">{{ this.originalDriver.model }} {{ $t('layout.edit.configuration')}}</span></div>

    <div slot="body">
      <form class="needs-validation"
            ref="editform"
            v-on:submit.prevent="() => { return false }"
            novalidate>
        <div class="">
          <div v-if="mainInfos.loaded && !mainInfos.error">
            <input-form-text :class="'form-row'"
                        v-model="mainInfos.data.model"
                        :label="$t('layout.edit.model')"
                        :name="'model'"
                        :required="true"
                        @changeValidity="checkDetailsEditorValidity($event)"></input-form-text>
            <input-form-text :class="'form-row'"
                             v-model="mainInfos.data.signature"
                             :label="$t('layout.edit.signature')"
                             :name="'signature'"
                             :required="true"
                             @changeValidity="checkDetailsEditorValidity($event)"></input-form-text>
          </div>
        </div>
      </form>
    </div>

    <template slot="footer">
      <div class="col-md-2">
        <button type="button"
                @click.prevent="() => { if (isNew) { callNew(); } else { callSave() }}"
                :class="[
                'btn btn-outline-success btn-block text-capitalize',
                $hasPerms('dcs.drivers.actions.save') ? 'active' : 'disabled'
              ]">
          {{ $t('layout.button.save') }}
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Modal'
import { events } from '@/lib/messages'
import InputFormText from '@/components/forms/InputFormText'
import { apiConfig } from '@/config/api.config.js'
import helpers from '@/lib/helpers'

export default {
  name: 'ServiceEditModal',
  components: { InputFormText, Modal },
  data () {
    return {
      helpers: helpers,
      mainInfos: {
        loaded: false,
        error: false,
        data: null
      },
      originalDriver: {
        id: null,
        model: null,
        signature: null
      },
      id: null,
      isDetailsEditorValid: true,
      eventSystem: events,
      apiConfig: apiConfig
    }
  },
  props: {
  },
  computed: {
    ...mapGetters({
      driversList: 'drivers/all'
    }),
    currentDriver () {
      return {
        id: this.originalDriver.id,
        model: this.mainInfos.data ? this.mainInfos.data.model : null,
        signature: this.mainInfos.data ? this.mainInfos.data.signature : null
      }
    },
    hasModification () {
      return !(helpers.stripSpacesNewlines(this.currentDriver.model) === helpers.stripSpacesNewlines(this.originalDriver.model) &&
        helpers.stripSpacesNewlines(this.currentDriver.signature) === helpers.stripSpacesNewlines(this.originalDriver.signature))
    },
    isNew () {
      return this.originalDriver.id === 'new'
    },
    hasSomethingInError () {
      return this.mainInfos.error
    }
  },
  methods: {
    ...mapActions({
      getMainInfos: 'drivers/get',
      save: 'drivers/save',
      new: 'drivers/new'
    }),
    checkDetailsEditorValidity (e) {
      e.target.closest('form').classList.add('was-validated')
      this.isDetailsEditorValid = this.$el.querySelectorAll('form :invalid').length === 0
    },
    callNew () {
      if (this.hasSomethingInError) {
        this.$messages.error({ message: this.$t('message.cannot-save-data-error') })
        return false
      }
      if (!this.isDetailsEditorValid) {
        this.$messages.error({ message: this.$t('message.please-check-your-data') })
      } else {
        let driver = {
          model: this.mainInfos.data.model,
          signature: this.mainInfos.data.signature
        }
        if (this.$canLog(4)) console.log('[Drivers] new', driver)
        this.new(driver).then((data) => {
          this.$messages.success({ message: this.$t('message.success') })
        }).then(() => {
          this.eventSystem.$emit('refresh-page')
          this.$router.push({ name: 'dcs.drivers.list' })
        }).catch((e) => {
          // console.log(parameters)
          // this.$messages.error({
          //   title: this.$t('message.error'),
          //   message: (e.response && e.response.body) ? e.response.body : e.message
          // })
        })
      }
    },
    callSave () {
      if (this.hasSomethingInError) {
        this.$messages.error({ message: this.$t('message.cannot-save-data-error') })
        return false
      }
      if (!this.isDetailsEditorValid) {
        this.$messages.error({ message: this.$t('message.please-check-your-data') })
      } else {
        let driver = this.currentDriver
        if (this.$canLog(4)) console.log('[Drivers] new', driver)
        this.save(driver).then((data) => {
          this.$messages.success({ message: this.$t('message.success') })
        }).then(() => {
          this.eventSystem.$emit('refresh-page')
          try {
            this.$broadcast().postMessage('DriversList')
          } catch (e) {
            if (this.$canLog(0)) console.error(e.message)
          }
          this.$router.push({ name: 'dcs.drivers.list' })
        }).catch((e) => {
          // console.log(parameters)
          // this.$messages.error({ title: this.$t('message.error'), message: (e.response && e.response.body) ? e.response.body : e.message })
        })
      }
    },
    loadDriver () {
      this.getMainInfos(this.$route.params.id).then((data) => {
        if (this.$canLog(3)) console.log('[Driver] main infos', data)
        this.mainInfos.data = helpers.smartJsonCheckParse(data)
        if (this.mainInfos.data !== undefined) {
          this.originalDriver.model = this.mainInfos.data.model
          this.originalDriver.signature = this.mainInfos.data.signature
          if (this.$canLog(2)) console.log('[Driver] main infos ok')
          this.mainInfos.error = false
        } else {
          this.mainInfos.error = true
        }
      }).catch((e) => {
        this.mainInfos.error = true
      }).finally(() => {
        this.mainInfos.loaded = true
      })
    },
    createDriver () {
      this.mainInfos = {
        loaded: true,
        error: false,
        data: {
          model: '',
          signature: ''
        }
      }
    }
  },
  created () {
    this.originalDriver.id = this.$route.params.id
  },
  mounted () {
    this.mainInfos.data = null
    if (this.originalDriver.id !== null) {
      if (this.originalDriver.id !== 'new') {
        this.loadDriver()
      } else {
        this.createDriver()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-pane-content-if {
  .row {
    height: 100%;
  }
}

/*.h-auto-less-button {*/
/*  height: auto; //calc(100% - 1.5em - .375rem * 2 - 1em);*/
/*}*/

.h-button {
  height: calc(1.5em + .375rem * 2);
  margin-top: 1em;
}
</style>