<template>
  <modal class="anomalies-edit modal-edit"
         v-show="id !== null"
         :return-route-name="'dcs.anomalies.list'">
    <div slot="header">{{ $t('layout.edit.details') }}: <span v-if="loaded">{{ data.type.code }}</span></div>

    <template slot="body">
      <form class="needs-validation"
            ref="editform"
            v-on:submit.prevent="checkValidityOnSubmit"
            novalidate>

        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#details" role="tab" aria-controls="details">
              {{ $t('layout.edit.anomalies-edit-details') }}</a>
          </li>
        </ul>
        <div class="tab-content">
          <!-- PANNELLO DETAILS ************************ -->
          <div class="tab-pane active" id="details" role="tabpanel">
            <div v-if="loaded">
              <input-form-text :class="'form-row'"
                               v-model="data.type.code"
                               :label="$t('layout.edit.code')"
                               :tooltip="helpTextContent('anomalies.edit.code')"
                               :tooltip_hover="false"
                               :read_only="true"></input-form-text>
              <input-form-text :class="'form-row'"
                               v-model="data.type.localizedName"
                               :label="$t('layout.edit.name')"
                               :read_only="true"></input-form-text>
              <input-form-text :class="'form-row'"
                               v-model="data.machineName"
                               :label="$t('layout.edit.machine-name')"
                               :read_only="true"></input-form-text>
              <input-form-text :class="'form-row'"
                               v-model="data.itemCode"
                               :label="$t('layout.edit.serial-number')"
                               :read_only="true"></input-form-text>
              <input-form-textarea class="input-description" v-model="data.detailMessage"
                                   :label="$t('layout.edit.description')"
                                   :read_only="true"></input-form-textarea>
              <input-form-textarea class="input-hint" v-model="data.hint"
                                   :label="$t('layout.edit.hint')"
                                   :read_only="true"></input-form-textarea>
              <input-form-text :class="'form-row'"
                               v-model="data.opened"
                               :label="$t('layout.edit.opened')"
                               :tooltip="helpTextContent('anomalies.edit.opened')"
                               :tooltip_hover="false"
                               :read_only="true"></input-form-text>
              <div class="" v-show="data.closed !== ''">
                <input-form-text :class="'form-row'"
                                 v-model="data.selectedAction"
                                 :label="$t('layout.edit.selected-action')"
                                 :tooltip="helpTextContent('anomalies.edit.selected-action')"
                                 :tooltip_hover="false"
                                 :read_only="true"></input-form-text>
                <input-form-text :class="'form-row'"
                                 v-model="data.closed"
                                 :label="$t('layout.edit.closed')"
                                 :tooltip="helpTextContent('anomalies.edit.closed')"
                                 :tooltip_hover="false"
                                 :read_only="true"></input-form-text>
              </div>
            </div>
            <form-row-message v-else
                              :text="!loaded ? $t('message.loading') : $t('message.error')"
                              :isError="false"></form-row-message>
          </div>
        </div>
      </form>
    </template>
    <template slot="footer">
      <div class="col-md-2" v-show="loaded && data.closed === ''">
        <button type="button" v-if="loaded"
                @click.prevent="actionRetry(data.id, $hasPerms('dcs.anomalies.actions.retry'))"
                :class="[
                  'btn btn-outline-success action-retry btn-block text-capitalize',
                  $hasPerms('dcs.anomalies.actions.retry') && $bitPerms(anomaliesActions.indexOf('Retry')+1, data.supportedActions) ? 'active' : 'disabled'
                ]">
          {{ $t('layout.button.retry') }}
        </button>
      </div>
      <div class="col-md-2" v-show="loaded && data.closed === ''">
        <button type="button" v-if="loaded"
                @click.prevent="actionIgnore(data.id, $hasPerms('dcs.anomalies.actions.ignore'))"
                :class="[
                  'btn btn-outline-danger action-ignore btn-block text-capitalize',
                  $hasPerms('dcs.anomalies.actions.ignore') && $bitPerms(anomaliesActions.indexOf('Ignore')+1, data.supportedActions) ? 'active' : 'disabled'
                ]">
          {{ $t('layout.button.ignore') }}
        </button>
      </div>
    </template>
     <!-- <div class="row" v-show="!loaded && id !== null">
        <div class="col-md-12 text-center loading">{{ $t('message.loading') }}</div>
      </div>
      <div class="row" v-for="(value, key) in data" v-bind:key="key" v-show="loaded">
        <div class="col-md-2">{{ key }}:</div><div class="col-10" v-html="value"></div>
      </div>
    </div>-->
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Modal'
import FormRowMessage from '../../components/modal_edit/FormRowMessage'
import InputFormText from '../../components/forms/InputFormText'
import InputFormTextarea from '../../components/forms/InputFormTextarea'
// import { anomaliesActions } from '@/lib/bitactions'
import { events } from '@/lib/messages'
import HelpTextsMixin from '../../mixins/HelpTextsMixin'

export default {
  name: 'AnomalyDetailsModal',
  components: { InputFormTextarea, InputFormText, FormRowMessage, Modal },
  mixins: [ HelpTextsMixin ],
  data () {
    return {
      loaded: false,
      data: null,
      id: null
    }
  },
  computed: {
    ...mapGetters({
      anomaliesActions: 'anomalies/allActions'
    })
  },
  methods: {
    ...mapActions({
      loadActions: 'anomalies/loadActions',
      getAnomaly: 'anomalies/loadAnomaly',
      handle: 'anomalies/handle'
    }),
    actionIgnore (ids, enabled = true) {
      if (!enabled) { return }
      let parameters = {
        ids: [ ids ],
        action: this.anomaliesActions.indexOf('Ignore') + 1
      }
      if (this.$canLog(2)) console.log('[Anomalies] action ignore', parameters)
      this.callAction(parameters)
    },
    actionRetry (ids) {
      let enabled = this.$hasPerms('dcs.anomalies.actions.retry')
      if (!enabled) { return }
      let parameters = {
        ids: [ ids ],
        action: this.anomaliesActions.indexOf('Retry') + 1
      }
      if (this.$canLog(2)) console.log('[Anomalies] action retry', parameters)
      this.callAction(parameters)
    },
    callAction (parameters) {
      this.handle(parameters).then(() => {
        this.reload()
        events.$emit('refresh-page')
        try {
          this.$broadcast().postMessage('AnomaliesList')
        } catch (e) {
          if (this.$canLog(0)) console.error(e.message)
        }
      }).then(() => {
        this.$messages.success({ message: this.$t('message.success') })
      })
    },
    reload () {
      return this.getAnomaly(this.id).then((data) => {
        this.data = JSON.parse(data)
      }).finally(() => {
        this.loaded = true
      })
    }
  },
  created () {
    this.id = this.$route.params.id
  },
  mounted () {
    this.loadActions()
  },
  watch: {
    id: {
      handler: function (value) {
        this.data = null
        this.loaded = false
        if (value !== null) {
          this.reload()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/ .input-description {
    textarea {
      border-color: $danger;
      height: 7rem;
    }
  }

  /deep/ .input-hint {
    textarea {
      border-color: $success;
      height: 7rem;
    }
  }
</style>