<template>
  <transition name="modal">
    <div class="modal-mask" role="document">
      <div class="modal-wrapper">
        <div class="modal-container modal-content">

          <div class="modal-header">
            <slot name="header">
            </slot>
            <div @click.prevent="closeModal($event, false)" class="close-command">
              <close-icon :title="$t('layout.button.close')"></close-icon>
            </div>
          </div>

          <div class="modal-body">
            <div class="container">
            <slot name="body">
            </slot>
            </div>
          </div>

          <div class="modal-footer" v-if="$slots.footer">
            <div class="col-md-2">
              <button type="button"
                      @click.prevent="closeModal($event, false)"
                      class="btn btn-outline-secondary btn-block text-capitalize active">
                {{ $t('layout.button.close') }}
              </button>
            </div>
            <slot name="footer">
            </slot>
          </div>
        </div>
        <modal-yes-no v-show="showConfirm"
               @click-close="showConfirm = false"
               @click-no="showConfirm = false"
               @click-yes="closeModal($event, true)">
          <div slot="header">{{ $t('layout.text.confirm') }}: </div>
          <div slot="body">
            <div class="row">
              <div class="col-12">
                {{ confirmCloseMessage }}
              </div>
            </div>
          </div>
        </modal-yes-no>
      </div>
    </div>
  </transition>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close'
import ModalYesNo from './ModalYesNo'

export default {
  name: 'Modal',
  components: { ModalYesNo, CloseIcon },
  data: () => {
    return {
      showConfirm: false
    }
  },
  props: {
    returnRouteName: {
      type: String,
      default: '',
      required: false
    },
    returnRouteParms: {
      type: Object,
      default: () => {},
      required: false
    },
    requestConfirmBeforeClose: {
      type: Boolean,
      default: false
    },
    confirmCloseMessage: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeModal (e, force = false) {
      if (this.$canLog(3)) console.log('[Modal] show confirm?', this.requestConfirmBeforeClose, force)
      if (this.requestConfirmBeforeClose && !force) {
        if (this.$canLog(3)) console.log('[Modal] show confirm')
        this.showConfirm = true
      } else {
        this.$emit('close-modal')
        if (this.returnRouteName.length > 0) {
          this.$router.push({ name: this.returnRouteName, params: this.returnRouteParms })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .close-command {
    cursor: pointer;
    margin-left: auto;
  }

  .modal-mask {
    position: fixed;
    z-index: $zIndexMessages - 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    padding-top: 60px;

    @include media-breakpoint-up(md) {
      padding-top: 0;
    }
  }

  .modal-container {
    width: 98vw;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    height: 90vh;

    @include media-breakpoint-up(md) {
      width: 80vw;
      height: 90vh;
      // overflow-y: auto;
    }
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    // margin: 5px 0;
    // padding: 5px;
    padding: 5px 0;
    flex: 0 1 100%;
    max-height: 100%;
    overflow-y: hidden;

    @include media-breakpoint-up(md) {
      // margin: 20px 0;
      // padding: 1rem;
    }

    .container {
      padding: 0 5px;
      height: 100%;

      @include media-breakpoint-up(md) {
        padding: 0 15px;
      }

      /deep/ > form, > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      /deep/ .nav-tabs {
        border-bottom-color: $dark-grey;
        margin-bottom: 1px;
      }

      /deep/ .tab-content {
        overflow-y: auto;
        height: 90%;
        max-height: 90%;
        z-index: 0;
        position: relative;
        border-top: 0;

        .tab-pane-content-if {
          overflow-y: auto;
          overflow-x: hidden;
          height: 100%;

          &.h-auto-less-button {
            height: auto; // calc(100% - 1.5em - .375rem * 2 - 1em);
          }
        }
      }
    }
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .modal-footer {
    flex-direction: row;
  }
</style>