<template>
  <transition name="modal">
    <div class="modal-mask" role="document">
      <div class="modal-wrapper">
        <div class="modal-container modal-content">

          <div class="modal-header">
            <slot name="header">
            </slot>
            <div @click.prevent="$emit('click-close')" class="close-command">
              <close-icon :title="$t('layout.button.close')"></close-icon>
            </div>
          </div>

          <div class="modal-body">
            {{ confirmCloseMessage }}
            <div class="container">
            <slot name="body">
            </slot>
            </div>
          </div>

          <div class="modal-footer">
            <div class="col-md-2">
              <button type="button"
                      @click="$emit('click-yes')"
                      class="btn btn-outline-danger btn-block text-capitalize">
                {{ $t('layout.button.yes') }}
              </button>
            </div>
            <div class="col-md-2">
              <button type="button"
                      @click.prevent="$emit('click-no')"
                      class="btn btn-outline-success btn-block text-capitalize">
                {{ $t('layout.button.no') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close'
export default {
  name: 'ModalYesNo',
  components: { CloseIcon },
  data: () => {
    return {
      showConfirm: false
    }
  },
  props: {
    confirmCloseMessage: {
      type: String,
      default: ''
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .close-command {
    cursor: pointer;
  }

  .modal-mask {
    position: fixed;
    z-index: $zIndexMessages - 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 95vw;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;

    @include media-breakpoint-up(md) {
      width: 50vw;
    }
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    // margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .modal-footer {
    flex-direction: row;
  }
</style>