<template>
  <codemirror v-model="json"
              ref="cm"
              :key="invalidator"
              :class="{'readOnly' : read_only, 'is-invalid': !codeIsValid }"
              :options="cmOptions"
              @changes="emitChange"
  ></codemirror>
</template>

<script>
import codemirror from './Codemirror'

import 'codemirror/mode/javascript/javascript'
import 'codemirror/addon/display/autorefresh'
import 'codemirror/addon/lint/lint'
import 'codemirror/addon/lint/json-lint'
import helpers from '../../lib/helpers'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/lint/lint.css'
import { cloneDeep } from 'lodash'

window.jsonlint = require('jsonlint')

export default {
  name: 'CodeEditor',
  components: {
    codemirror
  },
  data () {
    return {
      invalidator: false,
      jsonSchema: {
        type: 'object',
        required: true,
        properties: {
          o: {
            type: 'string'
          }
        }
      },
      // codemirror options
      cmOptionsDefault: {
        tabSize: 4,
        mode: {
          name: 'javascript',
          json: true
        },
        gutters: ['CodeMirror-lint-markers'],
        lint: true,
        lineNumbers: true,
        line: true,
        autoRefresh: true,
        readOnly: this.read_only
      },
      json: '',
      codeIsValid: true
    }
  },
  props: {
    code: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    },
    read_only: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cmOptions () {
      let opts = cloneDeep(this.cmOptionsDefault)
      opts.lint = this.json !== null && this.json !== ''
      return opts
    },
    codemirror () {
      return this.$refs.cm.codemirror
    }
  },
  methods: {
    emitChange (em) {
      let obj = null
      if (this.json === '' || this.json === null) {
        this.json = null
        this.codeIsValid = true
      } else {
        try {
          obj = JSON.stringify(JSON.parse(this.json))
          this.codeIsValid = true
        } catch (e) {
          // console.log(code)
          this.codeIsValid = false
        }
      }
      this.$emit('changeValidity', this.codeIsValid)
      this.$emit('updateCode', obj)
    }
  },
  watch: {
    code: {
      handler: function (value, oldValue) {
        if (this.$canLog(3)) console.log('[CodeEditor] prop changed', value, oldValue)
        // se forzo il refresh in ogni caso, codemirror perde il focus ad ogni carattere
        // in questo modo verifico se il codice che ho in editor non sia uguale a quello che
        // arriva dal parent => non è un update dovuto al fatto che sto modificando il codice
        // in codemirror ma è un update esterno (p.es che arriva dal configuration editor)
        if (value !== this.json) {
          this.json = value
          this.invalidator = !this.invalidator
          this.codeIsValid = helpers.smartJsonCheckParse(this.json) !== undefined
          this.$emit('changeValidity', this.codeIsValid)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .vue-codemirror.form-control {
    height: 100%;

    padding: 0.375rem;

    &:not(.is-invalid) {
      border-color: #e4e7ea;
    }

    &:valid {
      border-color: #e4e7ea;
    }

    /deep/ .CodeMirror {
      // height: 100%;
      height: auto;
    }

    &.readOnly {
      /deep/ .CodeMirror {
        color: $dark-grey !important;

        &.cm-s-default .cm-string {
          color: $dark-grey !important;
        }
      }
    }
  }
</style>