import Vue from 'vue'

import 'jquery'
import 'bootstrap'
import '@coreui/coreui'
import '@coreui/vue'

import Cookies from 'js-cookie'
import App from './App.vue'
import router from './router'
import store from './store'
import { OAuth2Plugin } from '@/lib/oauth2'
import { messages } from '@/lib/messages'
import broadcast from '@/lib/messages/broadcast'
import permissions from '@/lib/permissions'
import VueRouterMiddleware from 'vue-router-middleware'
import helpers from '@/lib/helpers'
import VTooltip from 'v-tooltip'

// Internationalization
import { i18n } from '@/lib/i18n'
// Pollers
// import ApiPollerQueue from '@/lib/api/poller'

const publicConfig = window.dcsClientCfg
localStorage.removeItem(publicConfig.API.StorageKey)

Vue.use(helpers)
Vue.use(OAuth2Plugin)
Vue.use(messages)
Vue.use(broadcast)
// Vue.use(ApiPollerQueue)
Vue.use(permissions)
Vue.use(VTooltip, {
  defaultDelay: { show: 500, hide: 100 },
  defaultPlacement: 'auto-start'
})
Vue.use(VueRouterMiddleware, { router })

Vue.prototype.$logLevel = window.dcsClientCfg.logLevel

Vue.config.productionTip = false

if (Cookies.get('locale')) {
  i18n.locale = Cookies.get('locale')
} else {
  Cookies.set('locale', i18n.locale)
}

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
