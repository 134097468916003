<template>
  <div class="routing-form">
    <modal class="fullscreen"
           data-backdrop="false"
           v-if="show && loaded"
           @close-modal="() => { $emit('component-closed'); show = false }">
      <template slot="header">
        <inline-help :content="helpTextContent('services.panel-routing.title')"></inline-help>
      </template>
      <div slot="body" class="routing-table" v-if="hasDataToDisplay && !parametersError">
        <!-- MENU FUNZIONI GLOBALI -->
        <div class="routing-menu">
          <div :class="['toggle', routingMenuOpen ? 'active': '']" @click="routingMenuOpen = !routingMenuOpen">
            <action-icon class="">
              <dots-horizontal-icon></dots-horizontal-icon>
            </action-icon>
          </div>
          <div :class="['routing-menu-items', routingMenuOpen ? 'opened' : '']">
            <action-icon class="add-top-action" @click="addExit('top')" :active="data.TopBins < 2">
              <plus-circle-icon></plus-circle-icon>&nbsp;{{ $t('layout.edit.panel-routing-add-top-exit')}}
            </action-icon>
            <action-icon class="add-left-action" @click="addExit('left')">
              <plus-circle-icon></plus-circle-icon>&nbsp;{{ $t('layout.edit.panel-routing-add-left-exit')}}
            </action-icon>
            <action-icon class="add-right-action" @click="addExit('right')">
              <plus-circle-icon></plus-circle-icon>&nbsp;{{ $t('layout.edit.panel-routing-add-right-exit')}}
            </action-icon>
            <action-icon class="change-order-action" @click="changeLabelingSystem('Counterclockwise')" v-if="data.LabelingSystem === 'Clockwise'">
              <rotate-left-icon></rotate-left-icon>&nbsp;{{ $t('layout.edit.panel-routing-change-counterclockwise')}}
            </action-icon>
            <action-icon class="change-order-action" @click="changeLabelingSystem('Clockwise')" v-else>
              <rotate-right-icon></rotate-right-icon>&nbsp;{{ $t('layout.edit.panel-routing-change-clockwise')}}
            </action-icon>
          </div>
        </div>

        <div class="other-fields-wrapper">
          <div class="routing-system-editor">
            <label>
              <span>Routing system:</span>&nbsp;<inline-help :content="helpTextContent('services.panel-routing.routing-system')"></inline-help>
            </label>
            <input-select class="form-input"
                          :options="routingSystemAsOptionsForSelect"
                          :currentValue="routingSystemCurrentValue"
                          :required="true"
                          @selected="(item) => { data.RoutingSystem = item.value; updateCode() }"
            ></input-select>
          </div>
          <div class="notes-editor">
            <label>Note: </label>
            <textarea v-model="data.Note"></textarea>
          </div>
        </div>

        <!-- TOP: UNA USCITA FRONTALE -->
        <routing-table-row class="top-exits inverted" v-if="data.TopBins <= 1">
          <div slot="left-content">
          </div>
          <div slot="center-content">
            <exit class=""
                  v-if="data.TopBins == 1"
                  :position="'top left'"
                  :number="exitsMap.top[0]"
                  :data="data.Exits[exitsMap.top[0]]"
                  :select-disabled="data.RoutingSystem !== 'ByArticle'"
                  @updateCode="updateCode"
                  @delete-action="deleteExit"></exit>
          </div>
          <div slot="right-content">
          </div>
        </routing-table-row>
        <!-- TOP: SCRITTA USCITA FRONTALE -->
        <routing-table-row class="exit-labels">
          <div slot="left-content"></div>
          <div slot="center-content">
            {{ $t('layout.edit.panel-routing-frontal-exit') }}
          </div>
          <div slot="right-content"></div>
        </routing-table-row>
        <!-- TOP: DUE USCITE FRONTALI -->
        <routing-table-row class="top-exits">
          <div slot="left-content">
            <exit class="" v-if="data.TopBins > 1"
                  :position="'top'"
                  :number="exitsMap.top[0]"
                  :data="data.Exits[exitsMap.top[0]]"
                  :select-disabled="data.RoutingSystem !== 'ByArticle'"
                  @updateCode="updateCode"
                  @delete-action="deleteExit"></exit>
          </div>
          <div slot="center-content" class="top-arrow">
            <top-arrow-icon :class="['top-arrow__icon', data.TopBins > 1 ? '' : 'single']" style=""></top-arrow-icon>
          </div>
          <div slot="right-content">
            <exit class="" v-if="data.TopBins > 1"
                  :position="'top right'"
                  :number="exitsMap.top[1]"
                  :data="data.Exits[exitsMap.top[1]]"
                  :select-disabled="data.RoutingSystem !== 'ByArticle'"
                  @updateCode="updateCode"
                  @delete-action="deleteExit"></exit>
          </div>
        </routing-table-row>
        <!-- RIGA CON SCRITTE USCITE LATERALI, FRECCE E GRAFICA -->
        <routing-table-row class="exit-labels">
          <div slot="left-content">
            <div style="position: absolute; right: 100px; bottom: 30px;">
              {{ $t('layout.edit.panel-routing-left-exit') }}
            </div>
            <left-arrow-icon style="width: auto; height: 50px; position: absolute; right: 10px; bottom: 20px;" class="left-arrow"></left-arrow-icon>
          </div>
          <div slot="center-content">
            <img src="../../assets/bended-rows-min.png"/>
          </div>
          <div slot="right-content">
            <div style="position: absolute; left: 100px; bottom: 30px;">
              {{ $t('layout.edit.panel-routing-right-exit') }}
            </div>
            <right-arrow-icon style="width: auto; height: 50px; position: absolute; left: 10px; bottom: 20px;" class="right-arrow"></right-arrow-icon>
          </div>
        </routing-table-row>
        <!-- LOOP USCITE LATERALI -->
        <routing-table-row>
          <div slot="left-content" class="column">
            <template v-for="exitIndex in exitsMap.left">
              <exit class=""
                    :position="'left'"
                    :data="data.Exits[exitIndex]"
                    :number="exitIndex"
                    :select-disabled="data.RoutingSystem !== 'ByArticle'"
                    v-bind:key="exitIndex"
                    @updateCode="updateCode"
                    @delete-action="deleteExit"></exit>
            </template>
          </div>
          <div slot="center-content">
            <img v-for="n in maxLateralExits"
                 v-bind:key="n"
                 src="../../assets/vertical-rows.png" />
          </div>
          <div slot="right-content" class="column">
            <template v-for="exitIndex in exitsMap.right">
              <exit class=""
                    :position="'right'"
                    :data="data.Exits[exitIndex]"
                    :number="exitIndex"
                    :select-disabled="data.RoutingSystem !== 'ByArticle'"
                    v-bind:key="exitIndex"
                    @updateCode="updateCode"
                    @delete-action="deleteExit"></exit>
            </template>
          </div>
        </routing-table-row>
        <!-- RIGA INGRESSO CON GRAFICA E SCRITTA -->
        <routing-table-row>
          <div slot="left-content">
          </div>
          <div slot="center-content">
            <img src="../../assets/vertical-rows.png" />
            <pyramid style="position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); width: 140px; z-index: 1;"></pyramid>
            <div style="position: absolute; bottom: 5px; left: 50%; transform: translateX(-50%); z-index: 2; color: white; text-transform: uppercase">{{ $t('layout.edit.panel-routing-entry') }}</div>
          </div>
          <div slot="right-content">
          </div>
        </routing-table-row>
      </div>
      <div>
        <p v-if="!hasDataToDisplay && !parametersError">
          {{ $t('layout.edit.no-data-to-display') }};
          <a href="#" @click.prevent="onClickInitialize">{{ $t('layout.edit.panel-routing-click-to-initialize') }}</a>.
        </p>
        <p v-if="parametersError">
          {{ $t('layout.message.malformed-data') }}
        </p>
      </div>
    </modal>
    <div class="row h-100 align-items-center" v-else>
      <div class="col-12 text-center">
        <h3>{{ $t('layout.edit.panel-routing-component')}}</h3>
        <a href="#" v-if="hasDataToDisplay && !parametersError"
           @click="show = true">{{ $t('layout.edit.click-to-open-routing')}}</a>
        <p v-if="!hasDataToDisplay && !parametersError">
          {{ $t('layout.edit.no-data-to-display') }};
          <a href="#" @click.prevent="onClickInitialize">{{ $t('layout.edit.panel-routing-click-to-initialize') }}</a>.
        </p>
        <p v-if="parametersError">
          {{ $t('layout.message.malformed-data') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '../Modal'
import Exit from './PanelRouting/Exit'
import Pyramid from './PanelRouting/Pyramid'
import TopArrowIcon from './PanelRouting/TopArrowIcon'
import LeftArrowIcon from './PanelRouting/LeftArrowIcon'
import RightArrowIcon from './PanelRouting/RightArrowIcon'
import RoutingTableRow from './PanelRouting/RoutingTableRow'
import helpers from '@/lib/helpers'
import ActionIcon from '../layout/ActionIcon'
import PlusCircleIcon from 'vue-material-design-icons/PlusCircle'
import DotsHorizontalIcon from 'vue-material-design-icons/DotsHorizontal'
import RotateLeftIcon from 'vue-material-design-icons/RotateLeft'
import RotateRightIcon from 'vue-material-design-icons/RotateRight'
import InputSelect from './InputSelect'
import { mapActions, mapGetters } from 'vuex'
import InlineHelp from '../InlineHelp'
import HelpTextsMixin from '../../mixins/HelpTextsMixin'

export default {
  name: 'Routing',
  components: { InlineHelp, InputSelect, RotateRightIcon, RotateLeftIcon, DotsHorizontalIcon, PlusCircleIcon, ActionIcon, RoutingTableRow, RightArrowIcon, LeftArrowIcon, TopArrowIcon, Pyramid, Exit, Modal },
  mixins: [ HelpTextsMixin ],
  props: {
    parameters: {
      type: String,
      validator: prop => typeof prop === 'string' || prop === null
    }
  },
  data: () => {
    return {
      routingMenuOpen: true,
      parametersError: false,
      data: null,
      show: true,
      loaded: false,
      articlesLoaded: false,
      exitsMap: {
        left: [],
        right: [],
        top: []
      }
    }
  },
  computed: {
    ...mapGetters({
      routingSystemList: 'services/allRoutingSystem',
      currentRoutingSytem: 'services/oneRoutingSystemByValue'
    }),
    routingSystemCurrentValue () {
      if (this.currentRoutingSytem(this.data.RoutingSystem)) {
        return this.currentRoutingSytem(this.data.RoutingSystem).routingSystem
      }
      return null
    },
    routingSystemAsOptionsForSelect () {
      return (this.routingSystemList)
        ? this.routingSystemList.map((item) => {
          return {
            value: item.routingSystem,
            label: item.localizedName,
            search: item.localizedName
          }
        }) : []
      // return [{
      //   value: 0,
      //   label: '[' + 0 + '] ' + this.data.RoutingSystem,
      //   search: this.data.RoutingSystem
      // }]
    },
    maxLateralExits () {
      return Math.max(this.data.LeftBins, this.data.RightBins)
    },
    numRows () {
      return this.data.LeftBins > this.data.RightBins ? this.data.LeftBins : this.data.RightBins
    },
    dataNote () {
      return this.data.Note
    },
    hasDataToDisplay () {
      return this.exitsMap.left.length + this.exitsMap.right.length + this.exitsMap.top.length > 0
    }
  },
  methods: {
    ...mapActions({
      loadArticles: 'articles/load',
      loadRoutingSystem: 'services/loadRoutingSystem'
    }),
    closeRoutingMenu (time = 2500) {
      setTimeout(() => {
        this.routingMenuOpen = false
      }, time)
    },
    onClickInitialize () {
      this.data = this.initializeEmptyDataset()
      this.updateCode()
    },
    initializeEmptyDataset () {
      if (this.$canLog(2)) console.log('[PanelRouting] initialize empty ds')
      return {
        RoutingSystem: this.routingSystemList[0].routingSystem,
        LeftBins: 2,
        TopBins: 2,
        RightBins: 2,
        LabelingSystem: 'Clockwise',
        Exits: [
          {
            Enabled: false,
            Specifications: ''
          },
          {
            Enabled: false,
            Specifications: ''
          },
          {
            Enabled: false,
            Specifications: ''
          },
          {
            Enabled: false,
            Specifications: ''
          },
          {
            Enabled: false,
            Specifications: ''
          },
          {
            Enabled: false,
            Specifications: ''
          }
        ],
        Note: ''
      }
      // this.updateCode()
    },
    changeLabelingSystem (type) {
      if (this.$canLog(2)) console.log('[PanelRouting] change to ' + type)
      this.data.LabelingSystem = type
      this.mapExits()
      this.closeRoutingMenu(0)
    },
    updateCode () {
      if (this.$canLog(2)) console.log('[PanelRouting] update code', this.data)
      this.$emit('updateCode', JSON.stringify(this.data))
    },
    addExit (where) {
      if (this.$canLog(2)) console.log('[PanelRouting] adding to ' + where)
      let insertAt = 0
      switch (where) {
        case 'left':
          if (this.data.LabelingSystem === 'Clockwise') {
            insertAt = this.data.LeftBins
          } else {
            insertAt = this.data.Exits.length - this.data.LeftBins
          }
          this.data.LeftBins += 1
          break
        case 'right':
          if (this.data.LabelingSystem === 'Clockwise') {
            insertAt = this.data.Exits.length - this.data.RightBins
          } else {
            insertAt = this.data.RightBins
          }
          this.data.RightBins += 1
          break
        default:
          if (this.data.TopBins === 2) { this.closeRoutingMenu(500); return }
          if (this.data.LabelingSystem === 'Clockwise') {
            insertAt = this.data.LeftBins
          } else {
            insertAt = this.data.Exits.length - this.data.RightBins
          }
          this.data.TopBins += 1
          break
      }
      this.data.Exits.splice(insertAt, 0, { Enabled: false, Specifications: '' })
      this.mapExits()
      this.closeRoutingMenu(500)
      this.updateCode()
    },
    deleteExit (data) {
      if (this.$canLog(2)) console.log('[PanelRouting] deleting ', data)
      this.data.Exits.splice(data.number, 1)
      switch (data.position) {
        case 'left': this.data.LeftBins -= 1; break
        case 'right': this.data.RightBins -= 1; break
        default: this.data.TopBins -= 1; break
      }
      this.mapExits()
    },
    validateParameters (value) {
      this.data = helpers.smartJsonCheckParse(value)
      if (this.$canLog(2)) console.log('[Routing] validate', this.data)

      if (this.data === undefined) {
        this.parametersError = true
        this.loaded = true
        return false
      }
      this.mapExits()
      this.parametersError = false
      this.loaded = true
      return true
    },
    mapExits () {
      let startFrom = 0
      this.exitsMap = {
        left: [],
        right: [],
        top: []
      }
      if (this.data.LabelingSystem === 'Clockwise') {
        console.log('[Routing] labeling Clockwise')
        if (this.data.LeftBins) {
          console.log('Mapping left from ' + startFrom + ' to ' + (startFrom + this.data.LeftBins - 1))
          for (let i = startFrom; i <= this.data.LeftBins - 1; i++) {
            this.exitsMap.left.push(i)
          }
          // la grafica viene creata dall'alto al basso, quindi inverto l'array
          this.exitsMap.left.reverse()
          startFrom += this.data.LeftBins
        }

        if (this.data.TopBins) {
          console.log('Mapping top from ' + startFrom + ' to ' + (startFrom + this.data.TopBins - 1))
          for (let i = startFrom; i <= startFrom + this.data.TopBins - 1; i++) {
            this.exitsMap.top.push(i)
          }
          startFrom += this.data.TopBins
        }

        if (this.data.RightBins) {
          console.log('Mapping right from ' + startFrom + ' to ' + (startFrom + this.data.RightBins - 1))
          for (let i = startFrom; i <= startFrom + this.data.RightBins - 1; i++) {
            this.exitsMap.right.push(i)
          }
        }
      } else {
        console.log('[Routing] labeling CounterClockwise')
        if (this.data.RightBins) {
          console.log('Mapping right from ' + startFrom + ' to ' + (startFrom + this.data.RightBins - 1))
          for (let i = startFrom; i <= this.data.RightBins - 1; i++) {
            this.exitsMap.right.push(i)
          }
          // la grafica viene creata dall'alto al basso, quindi inverto l'array
          this.exitsMap.right.reverse()
          startFrom += this.data.RightBins
        }

        if (this.data.TopBins) {
          console.log('Mapping top from ' + startFrom + ' to ' + (startFrom + this.data.TopBins - 1))
          for (let i = startFrom; i <= startFrom + this.data.TopBins - 1; i++) {
            this.exitsMap.top.push(i)
          }
          startFrom += this.data.TopBins
          // la grafica viene creata da sx a dx, quindi inverto l'array
          this.exitsMap.top.reverse()
        }

        if (this.data.LeftBins) {
          console.log('Mapping right from ' + startFrom + ' to ' + (startFrom + this.data.LeftBins - 1))
          for (let i = startFrom; i <= startFrom + this.data.LeftBins - 1; i++) {
            this.exitsMap.left.push(i)
          }
        }
      }
    }
  },
  mounted () {
    console.log('[PanelRouting] loading articles')
    Promise.all([
      this.loadArticles(),
      this.loadRoutingSystem()
    ]).finally(() => {
      this.articlesLoaded = true
    })
  },
  watch: {
    parameters: {
      handler: function (value, oldValue) {
        if (this.$canLog(2)) console.log('[PanelRouting] prop changed', value)
        if (this.$route.params.id === 'new' && value === null) {
          this.$emit('changeValidity', this.validateParameters(JSON.stringify(this.initializeEmptyDataset())))
          this.updateCode()
        } else {
          this.$emit('changeValidity', this.validateParameters(value))
        }
      },
      immediate: true
    },
    dataNote: {
      handler: function (value, oldValue) {
        if (value !== oldValue) {
          this.updateCode()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.routing-form {
  height: 100%;
}

.fullscreen {

  /deep/
  .modal-wrapper {

  }

  /deep/ .modal-container {
    width: 95vw;
    height: 95vh;

    .modal-body {
      width: 100%;
      height: 100%;
      overflow: auto;

      box-sizing: border-box;
      padding: 0;
      // margin: 1em 0;

      .container {
        max-width: none;
        padding: 0;

        .routing-menu {
          margin: 10px 10px 20px 10px;
          overflow: hidden;
          border-radius: 5px;
          position: relative;
          @include media-breakpoint-up(md) {

          }

          .toggle {
            position: absolute;

            display: flex;
            justify-content: center;
            align-items: center;

            border: 1px solid $light-grey;
            border-radius: 5px;
            width: 2.5em;
            height: 2.5em;

            z-index: $zindexInteraction;
            background-color: $white;
            cursor: pointer;

            .action-icon {
              color: $dark-grey;
            }

            &.active {
              background-color: $light-grey;
              border-color: $dark-grey;
            }
          }

          .routing-menu-items {
            height: 2.5em;

            display: inline-flex;
            padding: 0 0 0 2.5em;
            justify-content: space-between;
            align-items: stretch;

            max-width: 100%;
            overflow-x: auto;
            overflow-y: hidden;

            transform: translateX(-100%);
            opacity: 0;
            transition: opacity 0.15s  ease-out, transform 0.2s ease-out;

            &.opened {
              transform: none;
              z-index: 1;
              opacity: 1;
            }

            > a {
              border: 1px solid $light-grey;
              border-radius: 5px;
              white-space: nowrap;

              flex: 1 1 auto;
              padding: 0 10px;
              margin: 0 5px;

              display: flex;
              align-items: center;
              color: $white;

              &:hover {
                text-decoration: none;
              }

              &.add-top-action {
                background-color: $gold;
              }
              &.add-left-action {
                background-color: $cerulean;
              }
              &.add-right-action {
                background-color: $red;
              }
              &.change-order-action {
                background-color: $botticelli;
                color: $black;
              }

              &.disabled {
                background-color: $light-grey;
              }
            }
          }
        }

        .routing-table {
          .top-exits {
            .exit {
              margin-bottom: 10px;

              @include media-breakpoint-up(md) {
                margin-bottom: 0;
              }
            }
          }

          .exit-labels {
            display: none;

            @include media-breakpoint-up(md) {
              display: flex;
            }
          }

          .top-arrow__icon {
            width: 50px;
            height: auto;
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);

            &.single {
              position: relative;
              display: block;
              bottom: 0;
              margin: 10px 0;
            }
          }

          .row {
            margin: 0;
            font-size: 0;
          }

          .cushion {
            flex: 1 1 auto;
          }

          .exit-wrapper {
            padding-bottom: 10px;
            font-size: initial;
            width: calc(50% - 83px);
            flex: 0 0 auto;

            &:first-of-type {
              padding-left: 10px;
            }
            &:last-of-type {
              padding-right: 10px;
            }

            .right-arrow, .left-arrow {
              margin-top: 20px;
              height: 40px;
              width: auto;
            }
          }

          .top {
            .exit-wrapper {
              /*margin: 0 auto;*/
              display: block;
            }
          }

          .bend-wrapper {
            width: 168px;
            flex: 0 0 168px;
          }

          /*.routes-wrapper {
            width: 168px;
            flex: 0 0 168px;

            display: none;

            @include media-breakpoint-up(md) {
              display: initial;
            }
          }*/

          .entry-wrapper {
            min-width: 168px;
            font-size: initial;
            position: relative;

            .lines {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              z-index: 0;
            }

            .entry {
              position: relative;
              width: 8em;
              z-index: 1;
            }

            .entry-text {
              position: absolute;
              bottom: 0.5em;
              left: 50%;
              transform: translateX(-50%);
              text-transform: uppercase;
              color: $white;
              z-index: 2;
            }
          }

          .add-exit {
            border-radius: 3px;
            cursor: pointer;
            opacity: .1;
            &:hover {
              opacity: 1;
            }

            /deep/ .action-icon {
              color: $light-grey;

              font-size: initial;
            }
          }

          .column-left, .column-right {
            .column {
              display: flex;
              flex-direction: column;
              height: 100%;

              .exit {
                margin-bottom: 10px;
              }
            }
          }

          .other-fields-wrapper {
            text-align: center;
            display: flex;
            margin-top: 3em;
            margin-bottom: 3em;
            justify-content: center;
            flex-direction: column;

            @include media-breakpoint-up(md) {
              flex-direction: row;
            }

            .notes-editor {
              text-align: left;
              margin: 0 5px;
              textarea {
                width: 100%;
                @include media-breakpoint-up(md) {
                  width: 33vw;
                }
                height: 4em;
              }

              label {
                display: block;
                margin-right: 1em;
              }
            }

            .routing-system-editor {
              text-align: left;
              margin: 0 5px;
              .input-select {
                width: 100%;
                @include media-breakpoint-up(md) {
                  width: 33vw;
                }
              }
              label {
                display: block;
                margin-right: 1em;
              }
            }
          }
        }
      }
    }
  }
}
</style>