<template>
  <li class="nav-item nav-dropdown ">
    <a class="nav-link nav-dropdown-toggle current-language" v-toggle-open>
      <earth-icon :title="$t('menu.language')"></earth-icon>
      {{ $i18n.locale }}
    </a>
    <ul class="nav-dropdown-items">
      <li class="nav-item" v-for="locale in locales" v-bind:key="locale" v-if="locale != $i18n.locale">
        <a class="nav-link available-language" href="#" @click.prevent="() => { $i18n.locale = locale }">
          {{ locale }}
        </a>
      </li>
    </ul>
  </li>
</template>

<script>
import EarthIcon from 'vue-material-design-icons/Earth'
import SidebarGroupToggler from '@/mixins/SidebarGroupToggler'
import { i18nConfig } from '@/config/i18n.config.js'

export default {
  name: 'SidebarGroupLanguage',
  components: { EarthIcon },
  mixins: [ SidebarGroupToggler ],
  data () {
    return {
      locales: i18nConfig.locales
    }
  }
}
</script>

<style lang="scss">
.available-language, .current-language {
  text-transform: uppercase;
}
</style>