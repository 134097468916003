import ApiReusableVuexModule from '@/lib/api/ApiReusableVuexModule'

const drivers = {
  namespaced: true,
  state: () => {
    return {
      list: null,
      last_update: null,
      drivers: null
    }
  },
  getters: {
    one: (state) => (index) => { return ApiReusableVuexModule.getters.one(state)(index) },
    all: (state) => { return ApiReusableVuexModule.getters.all(state) },
    lastUpdate: (state) => { return ApiReusableVuexModule.getters.lastUpdate(state) }
  },
  mutations: { ...ApiReusableVuexModule.mutations,
    ...{
      addDrivers (state, payload) {
        state.drivers = payload
      }
    }
  },
  actions: {
    get: (context, id) => {
      return context.dispatch('action', { path: 'MachineDrivers.MachineDrivers_Get', parameters: { id: id } })
    },
    load: (context, force = false) => {
      return ApiReusableVuexModule.actions.load(context, { path: 'MachineDrivers.MachineDrivers_List', force: force })
    },
    save: (context, driver) => {
      return context.dispatch('action', { path: 'MachineDrivers.MachineDrivers_Update', parameters: { driver: driver } })
    },
    new: (context, driver) => {
      return context.dispatch('action', { path: 'MachineDrivers.MachineDrivers_Create', parameters: { driver: driver } })
    },
    delete: (context, id) => {
      return context.dispatch('action', { path: 'MachineDrivers.MachineDrivers_Delete', parameters: { id: id } })
    },
    action: (context, { path, parameters = null }) => {
      // console.log('[Store] invoke action', path, parameters)
      return ApiReusableVuexModule.actions.action(context, { path, parameters })
    }
  }
}

export default drivers