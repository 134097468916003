<template>
  <div class="container-fluid monitored-machines">
    <div class="card">
      <div class="card-header text-uppercase">
        <format-list-bulleted-icon></format-list-bulleted-icon>
        {{ $t('menu.monitored-machines') }}
      </div>
      <div class="card-body">
        <div class="row justify-content-end" v-show="loaded">
          <div class="col-md-4">
            <refresher class="float-right"
                       :seconds="autoRefresh('MonitoredMachines')"
                       :enabled="refresherEnabled"
                       @tick="onRefresherTick"></refresher>
          </div>
        </div>
        <div class="row list" v-show="loaded">
          <div class="card m-3"
               :class="'status status-' + machine.status.bootStatus.toLowerCase()"
               v-for="(machine, index) in data" v-bind:key="index">
            <div class="card-body" @click="onMachineClick(machine.id)">
              <div class="icon-wrapper">
                <component :title="machine.status.localizedName" :is="statusToIcon(machine.status.bootStatus)"></component>
              </div>
              <span class="font-weight-bold">{{ machine.name}}</span>
              <p class="mb-0 text-right">{{ machine.status.localizedName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import RecordCounter from '@/components/RecordCounter'
import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted'
import { mapGetters, mapActions } from 'vuex'
import Refresher from '../../components/Refresher'
import { events } from '@/lib/messages'

import ThumbUpOutlineIcon from 'vue-material-design-icons/ThumbUpOutline'
import ThumbDownOutlineIcon from 'vue-material-design-icons/ThumbDownOutline'
import CloseCircleOutlineIcon from 'vue-material-design-icons/CloseCircleOutline'
import AlertOutlineIcon from 'vue-material-design-icons/AlertOutline'

export default {
  name: 'MonitoredMachinesList',
  components: { AlertOutlineIcon, CloseCircleOutlineIcon, ThumbDownOutlineIcon, ThumbUpOutlineIcon, FormatListBulletedIcon, Refresher, RecordCounter },
  mixins: [ ],
  data () {
    return {
      loaded: false,
      refresherEnabled: false
    }
  },
  computed: {
    ...mapGetters({
      data: 'statuses/allMachines',
      lastUpdate: 'statuses/lastUpdate',
      autoRefresh: 'settings/tableAutoRefresh'
    })
  },
  methods: {
    ...mapActions({
      load: 'statuses/loadMonitoredMachines'
    }),
    statusToIcon (status) {
      switch (status) {
        case 'Started':
          return 'ThumbUpOutlineIcon'
        case 'Disabled':
          return 'CloseCircleOutlineIcon'
        case 'Stopped':
          return 'ThumbDownOutlineIcon'
        default:
          return 'ThumbDownOutlineIcon'
      }
    },
    onMachineClick (id) {
      if (id) {
        this.$router.push({
          name: 'dcs.monitored-machines.edit',
          params: {
            id: id,
            returnRouteName: 'dcs.status.monitoredmachines'
          }
        })
      }
    },
    onRefresherTick () {
      this.refresherEnabled = false
      this.load().finally(() => {
        this.refresherEnabled = true
      })
    },
    onRowClass (dataItem, index) {
      return dataItem.status.bootStatus.toLowerCase()
    }
  },
  mounted () {
    this.load().finally(() => {
      this.loaded = true
      this.refresherEnabled = true
    })
    events.$on('change-language', () => {
      if (this.$canLog(2)) console.log('[' + this.$options.name + '] invoked change language')
      this.load(true)
    })
    events.$on('refresh-page', () => {
      if (this.$canLog(2)) console.log('[' + this.$options.name + '] invoked refresh page')
      this.load(true)
    })
  }
}
</script>

<style lang="scss">
  .monitored-machines {
    .status {
      $box-shadow-px: 5px;

      position: relative;
      width: 20%;
      // color: $yellow;
      border-color: $yellow;
      border-width: 2px;
      cursor: pointer;
      user-select: none;
      box-shadow: 0 0 $box-shadow-px $yellow;

      &.status-started {
        .icon-wrapper {
          color: $green;
        }
        border-color: $green;
        box-shadow: 0 0 $box-shadow-px $green;
      }

      &.status-stopped {
        .icon-wrapper {
          color: $red;
        }
        border-color: $red;
        box-shadow: 0 0 $box-shadow-px $red;
      }

      &.status-disabled {
        .icon-wrapper {
          color: $slate-grey;
        }
        border-color: $slate-grey;
        box-shadow: none;
      }

      .icon-wrapper {
        color: $yellow;
        /*border: 1px solid;*/
        width: 1.7rem;
        height: 1.7rem;
        overflow: hidden;
        border-radius: 50%;
        position: absolute;
        background-color: $white;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);

        display: flex;
        /*align-items: center;*/
        justify-content: center;

        .material-design-icon {
          font-size: 1.3rem;
          svg {
            margin-bottom: 2px;
          }
        }
      }
    }
  }
</style>