<template>
  <span :aria-hidden="decorative"
        :aria-label="title"
        class=""
        role="img">
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 18.23 15.49">
      <title>{{ title }}</title>
      <g id="a71546f8-a81e-45e8-8064-c7fe4080a643" data-name="Layer 2">
        <g id="b8f27c03-a743-489f-9617-70a2084b6b50" data-name="Livello 1">
          <g class="afd0fdf0-2c91-4624-b556-3f6049f742d6">
            <polygon points="10.05 3.22 10.05 4.99 13.05 4.99 12.55 3.22 10.05 3.22"/>
            <polygon points="10.06 7.33 13.57 7.33 13.06 5.56 10.06 5.56 10.06 7.33"/>
            <path d="M13,2.11H2.08a.53.53,0,0,0-.58.46L0,10.34a.52.52,0,0,0,.57.46H14.89a.53.53,0,0,0,.58-.46l-1.9-7.77A.53.53,0,0,0,13,2.11ZM.69,10.25l1.5-7.59H12.88l1.9,7.59Z"/>
            <polygon points="5.41 3.22 2.71 3.22 2.2 4.99 5.41 4.99 5.41 3.22"/>
            <rect x="6.14" y="3.22" width="3.2" height="1.77"/>
            <polygon points="1.72 7.33 5.42 7.33 5.42 5.56 2.22 5.56 1.72 7.33"/>
            <rect x="6.15" y="5.56" width="3.2" height="1.77"/>
            <polygon points="1.32 9.69 5.42 9.69 5.42 7.92 1.82 7.92 1.32 9.69"/>
            <rect x="6.15" y="7.92" width="3.2" height="1.77"/>
            <polygon points="10.06 7.92 10.06 9.69 14.07 9.69 13.57 7.92 10.06 7.92"/>
            <path class="eaa1623a-b83b-43a7-a0ee-a691d02909b3" d="M14.22,1.19H3.3a.53.53,0,0,0-.57.46L1.23,9.42a.53.53,0,0,0,.57.46H16.12a.53.53,0,0,0,.58-.46L14.79,1.65A.52.52,0,0,0,14.22,1.19Z"/><polygon points="11.28 2.3 11.28 4.07 14.28 4.07 13.78 2.3 11.28 2.3"/>
            <polygon points="11.29 6.41 14.79 6.41 14.29 4.64 11.29 4.64 11.29 6.41"/>
            <path d="M14.22,1.19H3.3a.53.53,0,0,0-.57.46L1.23,9.42a.53.53,0,0,0,.57.46H16.12a.53.53,0,0,0,.58-.46L14.79,1.65A.52.52,0,0,0,14.22,1.19ZM1.92,9.33l1.5-7.59H14.11L16,9.33Z"/><polygon points="6.64 2.3 3.93 2.3 3.43 4.07 6.64 4.07 6.64 2.3"/><rect x="7.37" y="2.3" width="3.2" height="1.77"/><polygon points="2.94 6.41 6.65 6.41 6.65 4.64 3.45 4.64 2.94 6.41"/><rect x="7.38" y="4.64" width="3.2" height="1.77"/><polygon points="2.54 8.77 6.65 8.77 6.65 7 3.04 7 2.54 8.77"/><rect x="7.38" y="7" width="3.2" height="1.77"/><polygon points="11.29 7 11.29 8.77 15.29 8.77 14.79 7 11.29 7"/><path class="eaa1623a-b83b-43a7-a0ee-a691d02909b3" d="M15.44.31H4.53A.53.53,0,0,0,4,.77L2.45,8.54A.53.53,0,0,0,3,9H17.35a.53.53,0,0,0,.57-.46L16,.77A.53.53,0,0,0,15.44.31Z"/><polygon points="12.5 1.42 12.5 3.19 15.5 3.19 15 1.42 12.5 1.42"/><polygon points="12.51 5.53 16.02 5.53 15.52 3.76 12.51 3.76 12.51 5.53"/><path d="M15.44.31H4.53A.53.53,0,0,0,4,.77L2.45,8.54A.53.53,0,0,0,3,9H17.35a.53.53,0,0,0,.57-.46L16,.77A.53.53,0,0,0,15.44.31ZM3.14,8.45,4.64.86H15.33l1.9,7.59Z"/><polygon points="7.86 1.42 5.16 1.42 4.65 3.19 7.86 3.19 7.86 1.42"/><rect x="8.59" y="1.42" width="3.2" height="1.77"/><polygon points="4.17 5.53 7.87 5.53 7.87 3.76 4.67 3.76 4.17 5.53"/><rect x="8.6" y="3.76" width="3.2" height="1.77"/><polygon points="3.77 7.89 7.87 7.89 7.87 6.12 4.27 6.12 3.77 7.89"/><rect x="8.6" y="6.12" width="3.2" height="1.77"/><polygon points="12.51 6.12 12.51 7.89 16.52 7.89 16.02 6.12 12.51 6.12"/><circle class="eff4d2a6-29e4-459b-b540-e775c1ed8b84" cx="9.14" cy="10.66" r="4.83"/><path d="M11.58,6.74a4.63,4.63,0,0,0-5.71,7.2,4.62,4.62,0,0,0,6.53,0A4.59,4.59,0,0,0,13,8.21a.32.32,0,1,0-.54.34,4,4,0,1,1-1.26-1.26.31.31,0,0,0,.44-.1A.33.33,0,0,0,11.58,6.74Z"/></g></g></g>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'BasketDisabledIcon',
  props: {
    title: {
      type: String,
      default: 'Basket icon'
    },
    decorative: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.afd0fdf0-2c91-4624-b556-3f6049f742d6{opacity:.2;}
.eaa1623a-b83b-43a7-a0ee-a691d02909b3,.eff4d2a6-29e4-459b-b540-e775c1ed8b84{fill:#fff;}
.eaa1623a-b83b-43a7-a0ee-a691d02909b3{stroke:#fff;stroke-miterlimit:10;stroke-width:0.61px;}
</style>