import { permissionResolver } from './index'
import helpers from '@/lib/helpers'

export default ({ store }, to, from, next) => {
  return new Promise((resolve, reject) => {
    if (!to.meta.isPublic) {
      try {
        if (permissionResolver(to.name)) {
          resolve(true)
        } else {
          reject(new Error('permission denied'))
        }
      } catch (e) {
        reject(e)
      }
    } else {
      if (helpers.canLog(3)) console.log('[Perms] Allowed to ', to.name)
      resolve(true)
    }
  })
}