<template>
  <div class="form-row input-multi-select">
    <label :for="name" :class="['col-form-label', labelClass]">{{ label }}:</label>
    <div :class="['input-cell mb-4', inputClass]">
      <select v-if="loaded"
              class="form-control"
              :name="name"
              ref="input"
              multiple
              :required="required"
              v-model="currentItems">
        <option v-for="(option, i) in options" :value="option.value" v-bind:key="i">{{ option.label }}</option>
      </select>
      <div v-else class="input-loading form-control">{{ $t('message.loading') }}</div>
      <div class="invalid-feedback position-absolute">
        {{ $t('message.please-provide-valid-value') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputFormMultiSelect',
  data () {
    return {
      currentItems: [],
      isValid: true
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: 'col-md-2'
    },
    inputClass: {
      type: String,
      default: 'col-md-10'
    },
    name: {
      type: String,
      default: 'auto-' + Math.floor(Math.random() * Math.floor(9999)).toString()
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      validator: prop => typeof prop === 'object' || prop === null,
      default: null
    },
    read_only: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: () => [
        {
          value: 0,
          label: 'zero 00000000 000000000 00000000000'
        },
        {
          value: 1,
          label: 'one'
        },
        {
          value: 2,
          label: 'one2'
        }
      ]
    }
  },
  computed: {
  },
  methods: {
    checkValidity () {
      this.isValid = this.$refs.input.checkValidity()
      // if (this.$canLog(2)) console.log('[InputMultiSelect]', this.$refs.input, this.$refs.input.value, this.$refs.input.checkValidity())
      let theForm = this.$refs.input.closest('form')
      if (theForm) {
        theForm.classList.add('was-validated')
      }
      this.$emit('changeValidity', { target: this.$refs.input, value: this.isValid })
    }
  },
  watch: {
    currentItems: {
      handler: function (value, oldValue) {
        if (value !== oldValue) {
          this.$nextTick(() => {
            this.checkValidity()
            this.$emit('input', this.currentItems)
          })
        }
      }
    },
    currentValue: {
      handler: function (value, oldValue) {
        if (value !== oldValue) {
          if (this.$canLog(2)) console.log('[InputMultiSelect] currentValue changed', value, oldValue)
          let result = this.options.filter((opt) => opt.value === value).map((opt) => opt.value)
          if (result.length === 0) {
            if (this.$canLog(1)) console.warn('[InputMultiSelect] currentValue not found', value, oldValue)
          }
          if (this.$canLog(2)) console.log('[InputMultiSelect] currentItem ', result[0])
          this.currentItems = result.length > 0 ? result : []
          if (this._isMounted) {
            this.checkValidity()
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .input-multi-select {
    select {
      width: 100%;
    }
  }
</style>