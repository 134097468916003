<template>
  <div class="container-fluid data-checks-list">
    <div class="card">
      <div class="card-header text-uppercase">
        <format-list-bulleted-icon></format-list-bulleted-icon>
        {{ $t('menu.data-checks') }}
        &nbsp;
        <inline-help :content="helpTextContent('datachecks.list.title')"></inline-help>
        <record-counter :can-show="loaded"
                        :rows-per-page="rowsPerPage"
                        :num-results="data.length"></record-counter>
      </div>
      <div class="card-body">
        <div class="card">
          <div class="card-body overflow-visible">
            <div class="row filters">
              <div class="col-md-5">
                <input-form-text :label-class="'col-md-4'"
                                 :input-class="'col-auto'"
                                 :label="$t('layout.list.search-by-panel')"
                                 :value="searchByPanel"
                                 @press-enter="load"
                                 @input="(value) => { searchByPanel = value }"></input-form-text>
              </div>
              <div class="col-md-5">
                <input-form-text :label-class="'col-md-4'"
                                 :input-class="'col-auto'"
                                 :label="$t('layout.list.search-by-production-order')"
                                 :value="searchByProductionOrder"
                                 @press-enter="load"
                                 @input="(value) => { searchByProductionOrder = value }"></input-form-text>
              </div>
              <div class="col-md-2">
                <button type="button"
                        @click.prevent="load"
                        :class="[
                          'btn btn-outline-secondary action-reset btn-block text-capitalize',
                          searchByPanel || searchByProductionOrder ? 'active' : 'disabled'
                        ]">
                  {{ $t('layout.button.check') }}
                </button>
              </div>
            </div>
            <div class="row clear-filters justify-content-between" v-show="loaded">
              <div class="col-md-12">
                <rows-quantity-select :rows-per-page-list="rowsPerPageList"
                                      :value="rowsPerPage"
                                      @change="(value) => { setTableRows ({ key: $options.name, value: value }) }"></rows-quantity-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row list">
          <div class="col-12">
            <div class="text-center loading" v-show="loading">{{ $t('message.loading') }}</div>
            <vuetable
                    v-if="loaded"
                    ref="vuetable"
                    :css="tableStyle.table"
                    :api-mode="false"
                    :per-page="rowsPerPage"
                    :fields="fieldsDefinition"
                    :data-manager="dataManager"
                    :displayEmptyDataRow="true"
                    :sort-order="sortOrder"
                    pagination-path="pagination"
                    detail-row-component="detail-row-data-checks"
                    @vuetable:row-clicked="onCellClicked"
                    @vuetable:pagination-data="onPaginationData">
              <template slot="type-slot" slot-scope="props">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.type') }}</span>
                <thumb-down-outline-icon class="text-danger"
                                         :title="''"
                                         v-if="props.rowData.type === 'Failed'"
                                         v-tooltip="helpTextContent('datachecks.list.failed')"></thumb-down-outline-icon>
                <information-outline-icon class="text-secondary"
                                          :title="''"
                                          v-if="props.rowData.type === 'Info'"
                                          v-tooltip="helpTextContent('datachecks.list.info')"></information-outline-icon>
                <thumb-up-outline-icon class="text-success"
                                       :title="''"
                                       v-if="props.rowData.type === 'Ok'"
                                       v-tooltip="helpTextContent('datachecks.list.ok')"></thumb-up-outline-icon>
                <skip-next-circle-outline-icon class="text-info"
                                               :title="''"
                                               v-if="props.rowData.type === 'Skipped'"
                                               v-tooltip="helpTextContent('datachecks.list.skipped')"></skip-next-circle-outline-icon>
                <alert-outline-icon class="text-warning"
                                    :title="''"
                                    v-if="props.rowData.type === 'Warning'"
                                    v-tooltip="helpTextContent('datachecks.list.warning')"></alert-outline-icon>
              </template>
              <template slot="test-name-slot" slot-scope="props">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.test-name') }}</span>
                {{ props.rowData.testName }}
              </template>
              <template slot="result-short-slot" slot-scope="props">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.result') }}</span>
                <span v-html="props.rowData.resultShort"></span>
              </template>
            </vuetable>
          </div>
        </div>
        <div class="row paging">
          <div class="col-12">
          <vuetable-pagination-custom ref="pagination"
                                      :css="tableStyle.pagination"
                                      @vuetable-pagination:change-page="onChangePage">
          </vuetable-pagination-custom>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from 'vue'
import { events } from '@/lib/messages'
import { mapGetters, mapActions } from 'vuex'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationCustom from '@/components/VuetablePaginationCustom'
import VuetableFieldCheckboxCustom from '@/components/VuetableFieldCheckboxCustom'
import DetailRowDataChecks from '../../components/DetailRow/DataChecks'
import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted'
import VuetableMixin from '@/mixins/VuetableMixin'
import RowsQuantitySelect from '../../components/forms/RowsQuantitySelect'
import CloseIcon from 'vue-material-design-icons/Close'
import CloseCircleIcon from 'vue-material-design-icons/CloseCircle'
import OpenInNewIcon from 'vue-material-design-icons/OpenInNew'
import InputFormText from '../../components/forms/InputFormText'
import ThumbDownOutlineIcon from 'vue-material-design-icons/ThumbDownOutline'
import InformationOutlineIcon from 'vue-material-design-icons/InformationOutline'
import ThumbUpOutlineIcon from 'vue-material-design-icons/ThumbUpOutline'
import SkipNextCircleOutlineIcon from 'vue-material-design-icons/SkipNextCircleOutline'
import AlertOutlineIcon from 'vue-material-design-icons/AlertOutline'
import RecordCounter from '../../components/RecordCounter'
import InlineHelp from '../../components/InlineHelp'
import HelpTextsMixin from '../../mixins/HelpTextsMixin'

Vue.component('detail-row-data-checks', DetailRowDataChecks)

export default {
  name: 'DataChecksList',
  components: {
    InlineHelp,
    RecordCounter,
    AlertOutlineIcon,
    SkipNextCircleOutlineIcon,
    ThumbUpOutlineIcon,
    InformationOutlineIcon,
    ThumbDownOutlineIcon,
    InputFormText,
    OpenInNewIcon,
    CloseCircleIcon,
    CloseIcon,
    DetailRowDataChecks,
    RowsQuantitySelect,
    VuetableFieldCheckboxCustom,
    FormatListBulletedIcon,
    VuetablePaginationCustom,
    Vuetable
  },
  mixins: [ HelpTextsMixin, VuetableMixin ],
  data () {
    return {
      loading: false,
      showDetails: null,
      defaultFilters: {},
      searchByPanel: null,
      searchByProductionOrder: null,
      fieldsDefinition: [
        {
          name: 'type-slot',
          dataClass: 'type-icons details-enabled',
          title: this.$t('layout.list.type'),
          sortField: 'type'
        },
        {
          name: 'test-name-slot',
          dataClass: 'details-enabled',
          title: this.$t('layout.list.test-name'),
          sortField: 'testName'
        },
        {
          name: 'result-short-slot',
          dataClass: 'details-enabled',
          title: this.$t('layout.list.result'),
          sortField: 'resultShort'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      lastUpdate: 'datachecks/lastUpdate',
      rowData: 'datachecks/all'
    }),
    data () {
      let data = (this.rowData && this.rowData.localizedDataCheckResults) ? this.rowData.localizedDataCheckResults : []
      return data.map((item, index) => {
        return { ...item, id: index }
      })
    }
  },
  methods: {
    ...mapActions({
      loadByPanel: 'datachecks/loadByPanel',
      loadByProductionOrder: 'datachecks/loadByProductionOrder'
    }),
    openDetailRow (data, event) {
      event.target.closest('tr').classList.toggle('selected')
      this.$refs.vuetable.toggleDetailRow(data.id)
    },
    onCellClicked ({ data, event }) {
      this.openDetailRow(data, event)
    },
    reload () {
      this.load(true).then(() => { if (this.$refs.vuetable) this.$refs.vuetable.reload() })
    },
    load (force = false) {
      let value = this.searchByPanel === null ? this.searchByProductionOrder === null ? false : this.searchByProductionOrder : this.searchByPanel
      let method = this.searchByPanel === null ? this.searchByProductionOrder === null ? false : 'loadByProductionOrder' : 'loadByPanel'
      if (method) {
        this.loaded = false
        this.loading = true
        return this[method](value, force)
          .then(() => {
            if (this.$canLog(3)) console.log('[DataChecks] load solved')
          })
          .finally(() => {
            this.loading = false
            this.loaded = true
          })
      } else {
        return new Promise((resolve, reject) => { throw new Error('method not found: ' + method) })
      }
    }
  },
  mounted () {
    this.loaded = false
    events.$on('change-language', () => {
      if (this.$canLog(2)) console.log('[DataChecks] invoked change language')
      this.reload(true)
        .then(() => { if (this.$refs.vuetable) this.$refs.vuetable.reload() })
    })
    events.$on('refresh-page', () => {
      if (this.$canLog(2)) console.log('[DataChecks] invoked refresh page')
      this.reload(true)
      // .then(() => { this.$refs.vuetable.reload() })
    })
  },
  watch: {
    lastUpdate: {
      handler: function () {
        if (this.loaded) {
          if (this.$refs.vuetable) this.$refs.vuetable.reload()
        }
      }
    },
    searchByPanel: {
      handler: function () {
        this.searchByProductionOrder = null
      }
    },
    searchByProductionOrder: {
      handler: function () {
        this.searchByPanel = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/ .details-enabled {
    cursor: context-menu;
  }

  /deep/ .vuetable-detail-row {
    background-color: rgba(0, 0, 0, 0.075);
    &:hover {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }

  /deep/ tr.selected {
    background-color: rgba(0, 0, 0, 0.075);
  }

  /deep/ tr {
    td {
      &.type-icons {
        @include media-breakpoint-up(md) {
          width: 2em;
          font-size: 1.3rem;
          text-align: center;
        }

        .material-design-icon {
          /*width: 1rem;*/
          /*height: 1rem;*/
        }
      }
    }
  }

</style>