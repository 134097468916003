const SidebarGroupToggler = {
  directives: {
    toggleOpen: {
      inserted: function (el, binding, vnode) {
        // console.log(el.parentNode)
        el.addEventListener(
          'click',
          (e) => {
            let parent = el.parentNode
            let status = parent.classList.contains('open')

            window.requestAnimationFrame(() => {
              if (parent.classList.contains('open') === status) {
                parent.classList.toggle('open')
              }
            })
          },
          false
        )
      },
      update: function (el, binding, vnode) {
        window.requestAnimationFrame(() => {
          if (el.parentNode.querySelector('.router-link-active')) {
            el.parentNode.classList.add('open')
          }
        })
      }
    }
  }
}

export default SidebarGroupToggler