<template>
  <div class="form-row input-text">
    <label :for="name" :class="['col-form-label', labelClass]">
      <span v-tooltip="tooltip" v-if="tooltip_hover">{{ label }}:</span>
      <span v-else>{{ label }}
        <inline-help :content="tooltip"></inline-help>
        :</span>
    </label>
    <div :class="['mb-4', inputClass]">
      <input :type="inputType"
             :name="name"
             :id="name"
             class="form-control"
             ref="input"
             :value="value"
             :readonly="read_only"
             v-on:keyup.enter="$emit('press-enter')"
             @input="checkValidity"
             @blur="$emit('blur', $event)" :required="required"/>
      <div class="invalid-feedback position-absolute">
        {{ $t('message.please-provide-valid-value') }}
      </div>
    </div>
  </div>
</template>

<script>
import InlineHelp from '../InlineHelp'
export default {
  name: 'InputFormText',
  components: { InlineHelp },
  props: {
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: 'col-md-2'
    },
    inputClass: {
      type: String,
      default: 'col-md-10'
    },
    name: {
      type: String,
      default: 'auto-' + Math.floor(Math.random() * Math.floor(9999)).toString()
    },
    read_only: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      validator: prop => typeof prop === 'string' || typeof prop === 'number' || prop === null,
      required: true
    },
    type: {
      type: String,
      default: 'String'
    },
    tooltip: {
      type: String,
      default: ''
    },
    tooltip_hover: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isValid: true
    }
  },
  computed: {
    inputStep () {
      switch (this.type) {
        case 'Numeric':
          return 1
        case 'Real':
          return 'any'
        default:
          return null
      }
    },
    inputType () {
      switch (this.type) {
        case 'Numeric':
        case 'Real':
          return 'number'
        default:
          return 'text'
      }
    }
  },
  methods: {
    checkNumberValue () {
      switch (this.type) {
        case 'Numeric':
          return /^\d+$/.test(this.$refs.input.value)
        case 'Real':
          return /^-?\d+(?:[.]\d+?)?$/.test(this.$refs.input.value)
        default:
          return true
      }
    },
    checkValidity (e) {
      if (this.$canLog(3)) console.log('[InputFormText] check', this.$refs.input.value, this.$refs.input.checkValidity(), this.checkNumberValue())
      this.isValid = this.$refs.input.checkValidity() && this.checkNumberValue()
      let theForm = this.$refs.input.closest('form')
      if (theForm) {
        theForm.classList.add('was-validated')
      }
      this.$emit('changeValidity', { target: this.$refs.input, value: this.isValid })
      this.$emit('input', this.$refs.input.value)
    }
  },
  mounted () {
    this.checkValidity()
  }
}
</script>

<style scoped>

</style>