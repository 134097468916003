<template>
  <li class="nav-item nav-dropdown">
    <a href="#" class="nav-link nav-dropdown-toggle" v-toggle-open>
      <atom-icon :title="$t('menu.status')"></atom-icon>
      {{ $t('menu.status') }}
    </a>
    <ul class="nav-dropdown-items">
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'dcs.status.processinfo'}">
          <server-icon :title="$t('menu.server')"></server-icon>
          {{ $t('menu.process-info') }}
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'dcs.status.monitoredmachines'}">
          <laptop-icon :title="$t('menu.client')"></laptop-icon>
          {{ $t('menu.monitored-machines') }}
        </router-link>
      </li>
<!--      <li class="nav-item">-->
<!--        <router-link class="nav-link" :to="{ name: 'dcs.status.versioninfo'}">-->
<!--          <information-variant-icon :title="$t('menu.users')"></information-variant-icon>-->
<!--          {{ $t('menu.version-info') }}-->
<!--        </router-link>-->
<!--      </li>-->
    </ul>
  </li>
</template>

<script>
import AtomIcon from 'vue-material-design-icons/Atom'
import ServerIcon from 'vue-material-design-icons/Server'
import InformationVariantIcon from 'vue-material-design-icons/InformationVariant'
import LaptopIcon from 'vue-material-design-icons/LaptopChromebook'
import SidebarGroupToggler from '@/mixins/SidebarGroupToggler'

export default {
  name: 'SidebarGroupStatus',
  components: { LaptopIcon, InformationVariantIcon, ServerIcon, AtomIcon },
  mixins: [ SidebarGroupToggler ]
}
</script>

<style>

</style>