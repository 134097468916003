<template>
  <div>
    <modal class="services-edit modal-edit"
           v-show="id !== null"
           :requestConfirmBeforeClose="hasModification"
           :confirmCloseMessage="$t('layout.edit.save-before-exit')"
           :return-route-name="'dcs.services.list'">
      <div slot="header">{{ $t('layout.edit.service-edit') }}: <span v-if="loaded">{{ originalData.name }} {{ $t('layout.edit.configuration')}}</span></div>

      <template slot="body">
        <form v-if="loaded"
              class="needs-validation"
              ref="editform"
              v-on:submit.prevent="checkValidityOnSubmit"
              novalidate>

          <ul class="nav nav-tabs" role="tablist">
            <li :class="['nav-item', isDetailsEditorValid ? '': 'has-invalid-input']">
              <a class="nav-link active" data-toggle="tab" href="#details" role="tab" aria-controls="details">
                {{ $t('layout.edit.service-edit-details') }}</a>
            </li>
            <li :class="['nav-item', isCodeEditorValid && isComponentEditorValid ? '': 'has-invalid-input']" v-if="serviceForm">
              <a class="nav-link" @click="() => { if (isCodeEditorValid && isComponentEditorValid) { showComponent = true }}" data-toggle="tab" href="#form" role="tab" aria-controls="form">
                {{ $t('layout.edit.service-edit-' + serviceForm.toLowerCase()) }}
                <inline-help :content="helpTextContent('services.edit.service-edit-' + serviceForm.toLowerCase())" v-show="true"></inline-help>
              </a>
            </li>
            <li :class="['nav-item', isCodeEditorValid ? '': 'has-invalid-input']">
              <a class="nav-link" data-toggle="tab" href="#json" role="tab" aria-controls="json">
                {{ $t('layout.edit.service-edit-json') }}
                <inline-help :content="helpTextContent('services.edit.service-edit-json')" v-show="true"></inline-help>
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane active" id="details" role="tabpanel">
              <input-text class="form-row" v-model="data.name"
                          :label="$t('layout.edit.name')"
                          :name="'name'"
                          :required="true"
                          @changeValidity="checkDetailsEditorValidity($event)"
              ></input-text>
              <input-textarea v-model="data.description"
                              :label="$t('layout.edit.description')"
                              :name="'description'"
                              :required="true"
                              @changeValidity="checkDetailsEditorValidity($event)"></input-textarea>
            </div>
            <div :class="['tab-pane', serviceForm]" id="form" role="tabpanel" v-if="serviceForm">
              <component v-if="!isPanelRouting"
                         :is="serviceForm"
                         :parameters="data.parameters"
                         :isLoaded="loaded"
                         @changeValidity="(value) => { isComponentEditorValid = value }"
                         @updateCode="(code) => { data.parameters = code }"></component>
              <div class="row h-100 align-items-center" v-else>
                <div class="col-12 text-center">
                  <h3>{{ $t('layout.edit.panel-routing-component')}}</h3>
                  <a v-if="isCodeEditorValid" href="#"
                     @click="showComponent = true">{{ $t('layout.edit.click-to-open-routing')}}</a>
                  <p v-else>
                    {{ $t('layout.message.malformed-data') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="json" role="tabpanel">
              <code-editor :class="[isCodeEditorValid ? 's' : 'is-invalid', 'form-control']"
                           :code="JSON.stringify(JSON.parse(originalData.parameters), null, 2)"
                           @changeValidity="(isValid) => { isCodeEditorValid = isValid }"
                           @updateCode="checkCodeEditorValidity"></code-editor>
            </div>
          </div>

        </form>
        <div class="row" v-else>
          <div class="col-md-12 text-center loading">{{ $t('message.loading') }}</div>
        </div>
      </template>

      <template slot="footer">
        <div class="col-md-2">
          <button type="button"
                  @click.prevent="() => { if (isNew) { callNew() } else { callSave() }}"
                  :class="[
                  'btn btn-outline-success btn-block text-capitalize',
                  $hasPerms('dcs.services.actions.save') ? 'active' : 'disabled'
                ]">
            {{ $t('layout.button.save') }}
          </button>
        </div>
      </template>
    </modal>
    <div v-if="isPanelRouting && showComponent">
    <component :is="serviceForm"
               :parameters="data.parameters"
               :isLoaded="loaded"
               @component-closed="showComponent = false"
               @changeValidity="(value) => { isComponentEditorValid = value }"
               @updateCode="(code) => { data.parameters = code }"></component>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Modal from '@/components/Modal'
import CodeEditor from '@/components/forms/CodeEditor'
import PanelGrading from '@/components/forms/PanelGrading'
import Routing from '@/components/forms/PanelRouting'
import InputText from '@/components/forms/InputFormText'
import InputTextarea from '@/components/forms/InputFormTextarea'
import { events } from '@/lib/messages'
import helpers from '@/lib/helpers'
import { clone, get } from 'lodash'
import InlineHelp from '../../components/InlineHelp'
import HelpTextsMixin from '../../mixins/HelpTextsMixin'

export default {
  name: 'ServiceEditModal',
  components: { InlineHelp, InputText, InputTextarea, PanelGrading, Routing, CodeEditor, Modal },
  mixins: [ HelpTextsMixin ],
  data () {
    return {
      loaded: false,
      originalData: {
        id: null,
        disabled: null,
        name: '',
        description: null,
        parameters: null
      },
      data: null,
      id: null,
      type: null,
      serviceForm: null,
      isCodeEditorValid: true,
      isComponentEditorValid: true,
      isDetailsEditorValid: true,
      eventSystem: events,
      showComponent: false
    }
  },
  props: {
  },
  computed: {
    ...mapGetters({
      'all': 'services/all'
    }),
    isPanelRouting () {
      return get(this.data, 'type.type') === 'Routing'
    },
    currentService () {
      return {
        id: this.id,
        disabled: this.data ? this.data.disabled : null,
        name: this.data ? this.data.name : null,
        description: this.data ? this.data.description : null,
        parameters: this.data ? this.data.parameters : null
      }
    },
    isNew () {
      return this.data.id === 'new'
    },
    hasModification () {
      if (this.$canLog(3)) console.log('[Services Edit] Checking changes')
      if (this.$canLog(4)) console.log(helpers.stripSpacesNewlines(this.currentService.parameters))
      if (this.$canLog(4)) console.log(helpers.stripSpacesNewlines(this.originalData.parameters))
      if (this.$canLog(4)) console.log(helpers.stripSpacesNewlines(this.currentService.parameters) === helpers.stripSpacesNewlines(this.originalData.parameters))

      return !(this.currentService.disabled === this.originalData.disabled &&
        helpers.stripSpacesNewlines(this.currentService.name) === helpers.stripSpacesNewlines(this.originalData.name) &&
        helpers.stripSpacesNewlines(this.currentService.description) === helpers.stripSpacesNewlines(this.originalData.description) &&
        helpers.stripSpacesNewlines(this.currentService.parameters) === helpers.stripSpacesNewlines(this.originalData.parameters)
      )
    }
  },
  methods: {
    ...mapActions({
      action: 'services/action',
      update: 'services/update',
      new: 'services/new',
      loadArticles: 'articles/load',
      loadRoutingSystem: 'services/loadRoutingSystem'
    }),
    checkDetailsEditorValidity (e) {
      e.target.closest('form').classList.add('was-validated')
      this.isDetailsEditorValid = this.$el.querySelectorAll('#details :invalid').length === 0
    },
    saveCodeEditor (code) {
      this.data.parameters = code
    },
    checkCodeEditorValidity (code) {
      if (this.isCodeEditorValid) {
        this.saveCodeEditor(code)
      }
    },
    callNew () {
      if (!this.isCodeEditorValid || !this.isDetailsEditorValid) {
        this.$messages.error({ message: this.$t('message.please-check-your-data') })
      } else {
        let service = {
          name: helpers.createTitle(this.all, 'name', this.data.name),
          description: this.data.description,
          type: this.type,
          setupinfo: this.data.parameters
        }
        if (this.$canLog(3)) console.log('[Services] new', service)
        this.new(service).then((data) => {
          this.$messages.success({ message: this.$t('message.success') })
        }).then(() => {
          this.eventSystem.$emit('refresh-page')
          this.$router.push({ name: 'dcs.services.list' })
        }).catch((e) => {
          // console.log(parameters)
          // this.$messages.error({
          //   title: this.$t('message.error'),
          //   message: (e.response && e.response.body) ? e.response.body : e.message
          // })
        })
      }
    },
    callSave () {
      if (!this.isCodeEditorValid || !this.isDetailsEditorValid || !this.isComponentEditorValid) {
        this.$messages.error({ message: this.$t('message.please-check-your-data') })
      } else {
        let parameters = this.currentService
        if (this.$canLog(4)) console.log('[Services] save', parameters)
        this.update(parameters).then((data) => {
          this.$messages.success({ message: this.$t('message.success') })
          this.eventSystem.$emit('refresh-page')
          try {
            this.$broadcast().postMessage('ServicesList')
          } catch (e) {
            if (this.$canLog(0)) console.error(e.message)
          }
          this.$router.push({ name: 'dcs.services.list' })
        }).catch((e) => {
          console.error(e)
          // this.$messages.error({ title: this.$t('message.error'), message: (e.response && e.response.body) ? e.response.body : e.message })
        })
      }
    },
    createService () {
      this.data = {
        id: 'new',
        disabled: true,
        name: '',
        description: '',
        type: {
          localizedName: '',
          type: this.type
        },
        version: '1.0.0',
        parameters: null
      }
      this.originalData = clone(this.data)
      // carico il component
      this.loadComponent()
      this.loaded = true
    },
    loadComponent () {
      if (Object.keys(this.$options.components).indexOf(this.data.type.type) >= 0) {
        this.serviceForm = this.data.type.type
        if (this.serviceForm === 'Routing') {
          Promise.all([
            this.loadArticles(true),
            this.loadRoutingSystem(true)
          ]).finally(() => {
            console.log('[ServiceEdit] PanelRouting component data loaded')
          })
        }
        // #118 refresh lista articoli ad ogni apertura
        if (this.serviceForm === 'PanelGrading') {
          this.loadArticles(true).finally(() => {
            console.log('[ServiceEdit] PanelGrading component data loaded')
          })
        }
      } else {
        if (this.$canLog(2)) console.log('[ServiceEdit] form not available')
      }
    },
    loadService () {
      this.action({ path: 'Services.Services_Get', parameters: { id: this.id } }).then((data) => {
        // data = JSON.parse('{"id": 44,"disabled": false,"type": {"type": "Counter","localizedName": "Counter"},"name": "new Counter","description": "new Counter - Counter","version": "1.0.0","parameters": "\\\\\"CounterId\\\\\""}')
        this.data = JSON.parse(data)
        this.originalData = JSON.parse(data)
      }).then(() => {
        // carico il component
        this.loadComponent()
      }).finally(() => {
        this.loaded = true
      })
    }
  },
  created () {
    this.id = this.$route.params.id
    this.type = this.$route.params.type
  },
  mounted () {
    this.data = null
    this.loaded = false
    if (this.id !== null) {
      if (this.id !== 'new') {
        this.loadService()
      } else {
        this.createService()
      }
    }
  },
  watch: {
    // id: {
    //   handler: function (value) {
    //     this.data = null
    //     this.loaded = false
    //     if (value !== null) {
    //       this.action({ path: 'Services.Services_Get', parameters: { id: value } }).then((data) => {
    //         this.data = JSON.parse(data)
    //         this.originalData = JSON.parse(data)
    //       }).then(() => {
    //         if (Object.keys(this.$options.components).indexOf(this.data.type.type) >= 0) {
    //           this.serviceForm = this.data.type.type
    //         } else {
    //           console.log('[Service edit] form not available')
    //         }
    //       }).finally(() => {
    //         this.loaded = true
    //       })
    //     }
    //   }
    // }
  }
}
</script>

<style lang="scss">
.services-edit {
  .row {
    margin-bottom: 1em;
  }

  #details, #form {
    .label {
      text-align: right;
    }

    /*input, textarea {*/
      /*&:not(.v-select) {*/
        /*min-width: 250px;*/
      /*}*/
    /*}*/
  }

  .tab-pane {
    height: auto; //calc(400px + 4rem);
  }

  .nav-tabs {
    .tabs-wrapper {
      margin-bottom: 1em;
    }
  }

  .was-validated .form-control {
    &:valid {
      border-color: #e4e7ea;
    }

    &:not(.is-invalid) {
      &:not(:invalid) {
        border-color: #e4e7ea;
      }
    }
  }
}
</style>