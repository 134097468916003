import { get, set } from 'lodash'

const settings = {
  namespaced: true,
  state: {
    hiddenColumns: {},
    rowsPerPage: {},
    autoRefresh: {}
  },
  getters: {
    autoRefresh: (state) => {
      let data = settings.load()
      let autoRefresh = get(data, `autoRefresh`)
      if (autoRefresh) {
        return autoRefresh
      } else {
        return settings.defaults().autoRefresh
      }
    },
    tables: (state) => {
      let data = settings.load()
      let rowsPerPage = get(data, `rowsPerPage`)
      if (rowsPerPage) {
        return Object.getOwnPropertyNames(rowsPerPage)
      } else {
        return settings.defaults().rowsPerPage
      }
    },
    tableAutoRefresh: (state) => (whoami) => {
      let data = settings.load()
      let autoRefresh = get(state, `autoRefresh.${whoami}`)
      return !autoRefresh ? get(data, `autoRefresh.${whoami}`, 20) : autoRefresh
    },
    tableRows: (state) => (whoami) => {
      let data = settings.load()
      let rowsPerPage = get(state, `rowsPerPage.${whoami}`)
      return !rowsPerPage ? get(data, `rowsPerPage.${whoami}`, 15) : rowsPerPage
    },
    hiddenColumns: (state) => (whoami) => {
      let data = settings.load()
      let hiddenColumns = get(state, `hiddenColumns.${whoami}`)
      return !hiddenColumns ? get(data, `hiddenColumns.${whoami}`, []) : hiddenColumns
    },
    fontSize: (state) => {
      let data = settings.load()
      let fontSize = get(data, 'fontSize')
      if (fontSize) {
        return fontSize
      } else {
        return 'normal'
      }
    }
  },
  mutations: {
    settings (state, payload) {
      state = payload
    },
    autoRefresh (state, { key, value }) {
      let data = settings.load()
      if (typeof state.autoRefresh[key] === 'undefined') {
        state.autoRefresh = { ...state.autoRefresh, [key]: value }
      } else {
        state.autoRefresh[key] = value
      }
      set(data, `autoRefresh[${key}]`, value)
      settings.save(data)
    },
    tableRows (state, { key, value }) {
      let data = settings.load()
      if (typeof state.rowsPerPage[key] === 'undefined') {
        state.rowsPerPage = { ...state.rowsPerPage, [key]: value }
      } else {
        state.rowsPerPage[key] = value
      }
      set(data, `rowsPerPage[${key}]`, value)
      settings.save(data)
    },
    hiddenColumns (state, { key, value }) {
      let data = settings.load()
      if (typeof state.hiddenColumns[key] === 'undefined') {
        state.hiddenColumns = { ...state.hiddenColumns, [key]: value }
      } else {
        state.hiddenColumns[key] = value
      }
      set(data, `hiddenColumns[${key}]`, value)
      settings.save(data)
    },
    fontSize (state, payload) {
      let data = settings.load()
      set(data, 'fontSize', payload)
      settings.save(data)
    }
  },
  load: () => {
    let data = localStorage.getItem('dcs-settings')
    if (!data) {
      settings.save(
        settings.defaults()
      )
      return settings.defaults()
    } else {
      return JSON.parse(data)
    }
  },
  save: (data) => {
    localStorage.setItem('dcs-settings', JSON.stringify(data))
  },
  defaults: () => {
    return {
      fontSize: 'normal',
      hiddenColumns: {},
      rowsPerPage: {
        QueriesList: 15,
        AnomaliesList: 15,
        DataChecksList: 15,
        DriversList: 15,
        MachinesList: 15,
        MappingsList: 15,
        ServicesList: 15
      },
      autoRefresh: {
        ProcessInfo: 20,
        MonitoredMachines: 20,
        AnomaliesList: 20,
        MachinesList: 20,
        MachineOperations: 20,
        MachineRuntimeStatus: 20
      }
    }
  }
}

export default settings