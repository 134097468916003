<template>
  <div class="form-row input-text">
    <label :for="name" :class="['col-form-label', labelClass]">{{ label }}:</label>
    <div :class="['mb-4', inputClass]">
      <date-picker v-if="!read_only"
                   ref="input"
                   :required="required"
                   :value="value"
                   :config="datePickerConfig"
                   @input="onInput"></date-picker>
      <input v-else type="text" :name="name" :id="name" class="form-control" />
      <div class="invalid-feedback position-absolute">
        {{ $t('message.please-provide-valid-value') }}
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue-bootstrap-datetimepicker/src/component'

export default {
  name: 'InputFormDateTime',
  components: { DatePicker },
  props: {
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: 'col-md-2'
    },
    inputClass: {
      type: String,
      default: 'col-md-10'
    },
    name: {
      type: String,
      default: Math.random().toString()
    },
    read_only: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      required: false
    },
    value: {
      validator: prop => typeof prop === 'string' || typeof prop === 'number' || prop === null,
      required: true
    }
  },
  computed: {
    datePickerConfig () {
      return this.config ? this.config : {
        format: 'YYYY-MM-DD HH:mm',
        useCurrent: false,
        stepping: 30,
        showClear: true,
        showClose: true,
        icons: {
          time: 'material-icons access_time',
          date: 'material-icons calendar_today',
          up: 'material-icons arrow_drop_up',
          down: 'material-icons arrow_drop_down',
          previous: 'material-icons keyboard_arrow_left',
          next: 'material-icons keyboard_arrow_right',
          today: 'material-icons today',
          clear: 'material-icons delete',
          close: 'material-icons clear'
        }
      }
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>