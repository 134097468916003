<template>
  <div class="messages-wrapper">
    <div class="messages-list">
      <message v-for="(message, index) in messages" :index="index" v-bind:key="index" :message="message">
      </message>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Message from './Message'
export default {
  name: 'MessageList',
  components: { Message },
  computed: {
    ...mapGetters({
      'hasMessages': 'messages/hasMessages',
      'getMessage': 'messages/one',
      'messages': 'messages/all'
    })
  },
  methods: {
    ...mapMutations({
      addMessage: 'messages/addMessage',
      removeMessage: 'messages/removeMessage'
    })
  },
  created () {
    // this.addMessage({
    //   level: 0,
    //   text: 'ERROR'
    // })
    // this.addMessage({
    //   level: 1,
    //   text: 'WARN'
    // })
    // this.addMessage({
    //   level: 2,
    //   text: 'SUCC'
    // })
    // this.addMessage({
    //   level: 3,
    //   text: 'OTHER'
    // })
  }
}
</script>

<style lang="scss">
  .messages-wrapper {
    position: fixed;
    top: calc(55px + 1em);
    width: 100%;
    z-index: $zIndexMessages;

    @include media-breakpoint-up(md) {
      top: 1em;
    }

    .messages-list {
      width: 90%;
      margin: 0 auto;

      .alert {
        cursor: pointer;
        position: relative;

        .close-icon {
          float: right;
        }
      }
    }
  }

</style>