<template>
  <div class="container-fluid services-list">
    <div class="card">
      <div class="card-header text-uppercase">
        <format-list-bulleted-icon></format-list-bulleted-icon>
        {{ $t('menu.services') }} <span v-if="oneServiceTypeByType($route.params.type)">- {{ oneServiceTypeByType($route.params.type).localizedName }}</span>
        &nbsp;
        <inline-help :content="helpTextContent('services.list.title')"></inline-help>
        <record-counter :can-show="loaded"
                        :rows-per-page="rowsPerPage"
                        :num-results="totalRows"></record-counter>
      </div>
      <div class="card-body">
        <div class="card">
          <div class="card-body overflow-visible">
            <div class="row clear-filters justify-content-between" v-show="loaded">
              <div class="col-md-12">
                <rows-quantity-select :rows-per-page-list="rowsPerPageList"
                                      :value="rowsPerPage"
                                      @change="(value) => { setTableRows ({ key: $options.name, value: value }) }"></rows-quantity-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row bulks my-3" v-show="loaded">
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="callNew"
                    :class="[
                      'btn btn-outline-primary btn-block text-capitalize',
                      $hasPerms('dcs.services.actions.new') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.new') }}
            </button>
          </div>
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="callClone"
                    :class="[
                      'btn btn-outline-warning btn-block text-capitalize',
                      (selectedIds.length == 1) && $hasPerms('dcs.services.actions.clone') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.clone') }}
            </button>
          </div>
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="wantsToDelete = true"
                    :class="[
                      'btn btn-outline-danger btn-block text-capitalize',
                      (selectedIds.length > 0) && $hasPerms('dcs.services.actions.delete') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.delete') }}
            </button>
          </div>
          <div class="col-auto"></div>
        </div>
        <div class="row list">
          <div class="col-12">
            <div class="text-center loading" v-show="!loaded">{{ $t('message.loading') }}</div>
            <vuetable
                    v-if="loaded"
                    ref="vuetable"
                    :css="tableStyle.table"
                    :api-mode="false"
                    :per-page="rowsPerPage"
                    :fields="fieldsDefinition"
                    :data-manager="dataManager"
                    :displayEmptyDataRow="true"
                    :sort-order="sortOrder"
                    :row-class="onRowClass"
                    pagination-path="pagination"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:checkbox-toggled="onCheckboxToggled"
                    @vuetable:checkbox-toggled-all="onCheckboxToggledAll">
              <div slot="checkbox-slot" slot-scope="props">
                <input type="checkbox" :checked="false">
              </div>
              <div slot="service-slot" slot-scope="props">
                <router-link :to="{ name: 'dcs.services.edit', params: { id: props.rowData.id } }">{{ props.rowData.name }}</router-link>
              </div>
              <div slot="description-slot" slot-scope="props">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.description') }}:</span> {{ props.rowData.description }}
              </div>
              <div slot="version-slot" slot-scope="props">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.version') }}:</span>
                {{ props.rowData.version }}
              </div>
            </vuetable>
          </div>
        </div>
        <div class="row paging">
          <div class="col-md-12">
            <vuetable-pagination-custom ref="pagination"
                                        :css="tableStyle.pagination"
                                        @vuetable-pagination:change-page="onChangePage">
            </vuetable-pagination-custom>
          </div>
        </div>
        <div class="row bulks my-3" v-show="loaded">
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="callNew"
                    :class="[
                      'btn btn-outline-primary btn-block text-capitalize',
                      $hasPerms('dcs.services.actions.new') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.new') }}
            </button>
          </div>
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="callClone"
                    :class="[
                      'btn btn-outline-warning btn-block text-capitalize',
                      (selectedIds.length == 1) && $hasPerms('dcs.services.actions.clone') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.clone') }}
            </button>
          </div>
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="wantsToDelete = true"
                    :class="[
                      'btn btn-outline-danger btn-block text-capitalize',
                      (selectedIds.length > 0) && $hasPerms('dcs.services.actions.delete') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.delete') }}
            </button>
          </div>
          <div class="col-auto"></div>
        </div>
      </div>
    </div>
    <!-- modale conferma cancellazione ************************************ -->
    <modal-yes-no v-show="wantsToDelete"
                  :confirmCloseMessage="$t('layout.delete.are-you-sure').replace('%n%', selectedIds.length)"
                  @click-yes="() => { callDelete(); wantsToDelete = false }"
                  @click-no="wantsToDelete = false">
    </modal-yes-no>
    <router-view></router-view>
  </div>
</template>

<script>
import { events } from '@/lib/messages'
import { mapGetters, mapActions } from 'vuex'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationCustom from '@/components/VuetablePaginationCustom'
import VuetableRowHeaderCustom from '@/components/VuetableRowHeaderCustom'
import VuetableFieldCheckboxCustom from '@/components/VuetableFieldCheckboxCustom'
import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted'
import VuetableMixin from '@/mixins/VuetableMixin'
import ReloadIcon from 'vue-material-design-icons/Reload'
import CloseCircleOutlineIcon from 'vue-material-design-icons/CloseCircleOutline'
import bCollapse from 'bootstrap-vue/es/components/collapse/collapse'
import bButton from 'bootstrap-vue/es/components/button/button'
import bToggleDirective from 'bootstrap-vue/es/directives/toggle/toggle'
import Modal from '../../components/Modal'
import helpers from '@/lib/helpers'
import ModalYesNo from '../../components/ModalYesNo'
import { isEmpty } from 'lodash'
import RowsQuantitySelect from '../../components/forms/RowsQuantitySelect'
import RecordCounter from '../../components/RecordCounter'
import InlineHelp from '../../components/InlineHelp'
import HelpTextsMixin from '../../mixins/HelpTextsMixin'

export default {
  name: 'ServicesList',
  components: { InlineHelp, RecordCounter, RowsQuantitySelect, ModalYesNo, Modal, 'b-btn': bButton, 'b-collapse': bCollapse, VuetableFieldCheckboxCustom, VuetableRowHeaderCustom, CloseCircleOutlineIcon, ReloadIcon, FormatListBulletedIcon, VuetablePaginationCustom, Vuetable },
  directives: {
    'b-toggle': bToggleDirective
  },
  mixins: [ HelpTextsMixin, VuetableMixin ],
  data () {
    return {
      loaded: false,
      typesLoaded: false,
      wantsToDelete: false,
      eventSystem: events,
      fieldsDefinition: [
        {
          name: VuetableFieldCheckboxCustom,
          title: '',
          dataClass: 'align-middle'
        },
        {
          name: 'service-slot',
          title: this.$t('layout.list.name'),
          sortField: 'name'
        },
        {
          name: 'description-slot',
          title: this.$t('layout.list.description')
        },
        {
          name: 'version-slot',
          title: this.$t('layout.list.version')
        }
      ],
      sortOrder: [{
        field: 'name',
        direction: 'desc'
      }]
    }
  },
  computed: {
    ...mapGetters({
      lastUpdate: 'services/lastUpdate',
      data: 'services/all',
      oneServiceById: 'services/oneServiceById',
      oneServiceTypeByType: 'services/oneServiceTypeByType',
      serviceByName: 'services/serviceByName'
    }),
    totalRows () {
      return (!this.loaded) ? 0 : this.filterData(this.data, this.valorizedFilters).length
    },
    dataIds () {
      return this.data.map((itm) => { return itm.id })
    },
    selectedIds () {
      if (this.data) {
        if (this.$canLog(4)) console.log('[Services] selectedRows', this.selectedRows)
        let ids = this.data.filter((elm) => {
          return this.selectedRows.indexOf(elm.id) >= 0
        })
          .map((elm) => {
            return elm.id
          })
        if (this.$canLog(4)) console.log('[Services] selectedIds', ids)
        return ids
      }
      return []
    },
    defaultFilters () {
      return {
        type: this.oneServiceTypeByType(this.$route.params.type)
      }
    }
  },
  methods: {
    ...mapActions({
      loadServices: 'services/load',
      action: 'services/action',
      clone: 'services/clone'
    }),
    onRowClass (dataItem, index) {
      return dataItem.disabled ? 'disabled' : ''
    },
    findValorized (filters, defaultFilters) {
      return this.defaultFilters
    },
    filterData (data, filters) {
      if (this.$canLog(3)) console.log('[Services] filterData')
      if (this.$refs.vuetable) this.$refs.vuetable.selectedTo = []
      this.selectedRows = []
      if (isEmpty(filters)) return data
      return data.filter((el) => {
        return el.type.type === filters.type.type
        // return isMatch(el, filters)
      })
    },
    load (force = false) {
      return this.loadServices(force)
    },
    callClone () {
      if (this.selectedIds.length > 1) {
        this.$messages.warning({ message: this.$t('message.select-only-one-item') })
        return false
      }
      let theService = this.oneServiceById(this.selectedIds[0])
      let parameters = {
        disabled: false,
        name: helpers.createTitle(this.data, 'name', theService.name),
        id: this.selectedIds[0]
      }
      if (this.$canLog(3)) console.log('[Services] clone', parameters)
      this.clone(parameters).then((data) => {
        let parsed = JSON.parse(data)
        if (this.$canLog(3)) console.log('[Services] cloned', parsed)
        this.$router.push({ name: 'dcs.services.edit', params: { type: this.$route.params.type, id: parsed.id } })
      })
    },
    callNew () {
      if (this.$hasPerms('dcs.services.actions.new')) {
        this.$router.push({ name: 'dcs.services.edit', params: { type: this.$route.params.type, id: 'new' } })
      }
    },
    callDelete () {
      this.wantsToDelete = false
      this.loaded = false
      let calls = []
      let numTodelete = this.selectedIds.length
      let successes = 0
      for (let i of this.selectedIds) {
        calls.push(new Promise((resolve, reject) => {
          let parameters = {
            id: i
          }
          this.action({ path: 'Services.Services_Delete', parameters: parameters })
            .then(() => {
              if (numTodelete === 1) {
                this.$messages.success({ message: this.$t('message.success-deleted-item').replace('%id%', parameters.id) })
              }
              successes++
              resolve()
            })
            .catch((e) => {
              // this.$messages.error({ title: this.$t('message.error'), message: (e.response && e.response.body) ? e.response.body : e.message })
              reject(e)
            })
        }))
      }
      Promise.all(calls)
        .then(() => {
          this.reload(true)
            .finally(() => {
              if (numTodelete > 1 && successes > 0) {
                this.$messages.success({ message: this.$t('message.success-deleted-items').replace('%n%', successes) })
              }
              this.loaded = true
            })
        })
        .catch((e) => {
          this.loaded = true
        })
    },
    reload (force = false) {
      return new Promise((resolve, reject) => {
        this.load(force)
          .then(() => {
            if (this.$canLog(4)) console.log('[Services] load solved')
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            this.loaded = true
          })
      })
    }
  },
  mounted () {
    if (this.$canLog(4)) console.log('mounted')
    this.loaded = false
    this.reload().catch((e) => {
      // this.$messages.error({
      //   title: this.$t('message.error'),
      //   message: (e.response && e.response.body) ? e.response.body : e.message
      // })
    })
  }
}
</script>

<style lang="scss">
  .container-fluid.services-list {

    .width-auto {
      width: auto;
      display: inline-block;
    }

    .row.list, .row.clear-filters {
      margin-top: 1em;
    }

    .card-header {
      background-color: $botticelli;
    }

    .card-body {
      overflow: visible;

      &.overflow-visible {
        overflow: visible;
      }
    }
  }
</style>