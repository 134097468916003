<template>
  <div class="container-fluid machines-list">
    <div class="card">
      <div class="card-header text-uppercase">
        <format-list-bulleted-icon></format-list-bulleted-icon>
        {{ $t('menu.machines') }}
        &nbsp;
        <inline-help :content="helpTextContent('machines.list.title')"></inline-help>
        <record-counter :can-show="loaded"
                        :rows-per-page="rowsPerPage"
                        :num-results="data.length"></record-counter>
      </div>
      <div class="card-body">
        <div class="card">
          <div class="card-body overflow-visible">
            <div class="row clear-filters justify-content-between" v-show="loaded">
              <div class="col-md-12">
                <rows-quantity-select :rows-per-page-list="rowsPerPageList"
                                      :value="rowsPerPage"
                                      @change="(value) => { setTableRows ({ key: $options.name, value: value }) }"></rows-quantity-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-3" v-show="loaded">
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="callNew"
                    v-tooltip="helpTextContent('machines.button.new')"
                    :class="[
                      'btn btn-outline-primary btn-block text-capitalize',
                      $hasPerms('dcs.machines.actions.new') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.new') }}
            </button>
          </div>
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="callWantsToDelete"
                    v-tooltip="helpTextContent('machines.button.delete')"
                    :class="[
                      'btn btn-outline-danger btn-block text-capitalize',
                      (selectedIds.length > 0) && $hasPerms('dcs.services.actions.delete') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.delete') }}
            </button>
          </div>
          <div class="col-md-8">
            <refresher class="float-right"
                       :seconds="autoRefresh('MachinesList')"
                       :enabled="listRefreshEnabled"
                       @tick="onRefresherTick"></refresher>
          </div>
        </div>
        <div class="row list">
          <div class="col-12">
            <div class="text-center loading" v-show="!loaded">{{ $t('message.loading') }}</div>
            <vuetable
                    v-if="loaded"
                    ref="vuetable"
                    :css="tableStyle.table"
                    :api-mode="false"
                    :per-page="rowsPerPage"
                    :fields="fieldsDefinition"
                    :data-manager="dataManager"
                    :displayEmptyDataRow="true"
                    :sort-order="sortOrder"
                    :row-class="onRowClass"
                    pagination-path="pagination"
                    detail-row-component="detail-row-machine"
                    :detail-row-options="detailRowOptions"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:checkbox-toggled="onCheckboxToggled"
                    @vuetable:checkbox-toggled-all="onCheckboxToggledAll">
              <div slot="machine-slot" slot-scope="props">
                <span class="mobile-caption d-block d-md-none"></span>
                <router-link :to="{ name: 'dcs.machines.edit', params: { id: props.rowData.id } }">
                  {{ props.rowData.name }}
                </router-link>
              </div>
              <div slot="description-slot" slot-scope="props"
                   @click="onCellClicked({ data: props.rowData, event: $event })">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.description') }}</span>
                {{ props.rowData.description }}
              </div>
              <div slot="status-slot" slot-scope="props"
                   @click="onCellClicked({ data: props.rowData, event: $event })">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.status') }}</span>
                <span :class="['status', 'status-' + props.rowData.status.bootStatus.toLowerCase()]">
                  {{ props.rowData.status.localizedName }}
                </span>
              </div>
              <div slot="position-slot" slot-scope="props"
                   @click="onCellClicked({ data: props.rowData, event: $event })">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.position') }}</span>
                {{ props.rowData.position }}
              </div>
              <div slot="driver-slot" slot-scope="props"
                   @click="onCellClicked({ data: props.rowData, event: $event })">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.driver') }}</span>
                {{ props.rowData.machineDriverModel }}
              </div>
              <div slot="tools-slot" slot-scope="props">
                <action-icon class="row-action tools"
                             :active="props.rowData.status.bootStatus.toLowerCase() === 'started'"
                             @click="onToolsCellClicked({ data: props.rowData, event: $event })">
                  <open-in-new-icon :title="''" v-tooltip="helpTextContent('machines.list.tools')"></open-in-new-icon>
                </action-icon>
              </div>
              <div slot="row-actions" slot-scope="props" class="row-actions">
                <action-icon class="row-action start color-green"
                             :active="$hasPerms('dcs.machines.actions.start') && props.rowData.status.bootStatus.toLowerCase() !== 'started'"
                             @click="callStart(props.rowData)">
                  <play-circle-icon :title="''" v-tooltip="helpTextContent('machines.button.start')"></play-circle-icon>
                </action-icon>
                <action-icon class="row-action stop color-red"
                             :active="$hasPerms('dcs.machines.actions.stop') && props.rowData.status.bootStatus.toLowerCase() === 'started'"
                             @click="callStop(props.rowData)">
                  <stop-circle-icon :title="''" v-tooltip="helpTextContent('machines.button.stop')"></stop-circle-icon>
                </action-icon>
                <action-icon class="row-action dis color-red"
                             :active="$hasPerms('dcs.machines.actions.disable') && props.rowData.status.bootStatus.toLowerCase() !== 'started' && props.rowData.status.bootStatus.toLowerCase() !== 'disabled'"
                             @click.prevent="callDisable(props.rowData)">
                  <close-circle-icon :title="''" v-tooltip="helpTextContent('machines.button.disable')"></close-circle-icon>
                </action-icon>
              </div>
            </vuetable>
          </div>
        </div>
        <div class="row paging">
          <div class="col-12">
          <vuetable-pagination-custom ref="pagination"
                                      :css="tableStyle.pagination"
                                      @vuetable-pagination:change-page="onChangePage">
          </vuetable-pagination-custom>
          </div>
        </div>
        <div class="row bulks my-3" v-show="loaded">
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="callNew"
                    v-tooltip="helpTextContent('machines.button.new')"
                    :class="[
                      'btn btn-outline-primary btn-block text-capitalize',
                      $hasPerms('dcs.machines.actions.new') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.new') }}
            </button>
          </div>
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="callWantsToDelete()"
                    v-tooltip="helpTextContent('machines.button.delete')"
                    :class="[
                      'btn btn-outline-danger btn-block text-capitalize',
                      (selectedIds.length > 0) && $hasPerms('dcs.services.actions.delete') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.delete') }}
            </button>
          </div>
          <div class="col-auto"></div>
        </div>
      </div>
    </div>
    <!-- modale conferma cancellazione ************************************ -->
    <modal-yes-no v-show="wantsToDelete"
                  :confirmCloseMessage="$t('layout.delete.are-you-sure').replace('%n%', selectedIds.length)"
                  @click-yes="() => { callDelete(); wantsToDelete = false }"
                  @click-no="wantsToDelete = false">
    </modal-yes-no>
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from 'vue'
import { events } from '@/lib/messages'
import { mapGetters, mapActions } from 'vuex'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationCustom from '@/components/VuetablePaginationCustom'
import VuetableFieldCheckboxCustom from '@/components/VuetableFieldCheckboxCustom'
import DetailRowMachine from '../../components/DetailRow/Machine'
import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted'
import VuetableMixin from '@/mixins/VuetableMixin'
import CRUDListMixin from '@/mixins/CRUDListMixin'
import RowsQuantitySelect from '../../components/forms/RowsQuantitySelect'
import ActionIcon from '../../components/layout/ActionIcon'
import PlayCircleIcon from 'vue-material-design-icons/PlayCircle'
import StopCircleIcon from 'vue-material-design-icons/StopCircle'
import CloseIcon from 'vue-material-design-icons/Close'
import CloseCircleIcon from 'vue-material-design-icons/CloseCircle'
import OpenInNewIcon from 'vue-material-design-icons/OpenInNew'
import ModalYesNo from '../../components/ModalYesNo'
import RecordCounter from '../../components/RecordCounter'
import Refresher from '../../components/Refresher'
import InlineHelp from '../../components/InlineHelp'
import HelpTextsMixin from '../../mixins/HelpTextsMixin'

Vue.component('detail-row-machine', DetailRowMachine)

export default {
  name: 'MachinesList',
  components: {
    InlineHelp,
    Refresher,
    RecordCounter,
    ModalYesNo,
    OpenInNewIcon,
    CloseCircleIcon,
    CloseIcon,
    DetailRowMachine,
    StopCircleIcon,
    PlayCircleIcon,
    ActionIcon,
    RowsQuantitySelect,
    VuetableFieldCheckboxCustom,
    FormatListBulletedIcon,
    VuetablePaginationCustom,
    Vuetable
  },
  mixins: [ VuetableMixin, CRUDListMixin, HelpTextsMixin ],
  data () {
    return {
      eventSystem: events,
      listRefreshEnabled: true,
      closedCheckbox: false,
      showDetails: null,
      detailRowOptions: {},
      defaultFilters: {},
      fieldsDefinition: [
        {
          name: VuetableFieldCheckboxCustom,
          title: '',
          dataClass: 'align-middle'
        },
        {
          name: 'machine-slot',
          dataClass: 'details-enabled',
          title: this.$t('layout.list.name'),
          sortField: 'name'
        },
        {
          name: 'description-slot',
          title: this.$t('layout.list.description'),
          dataClass: 'details-enabled'
        },
        {
          name: 'status-slot',
          title: this.$t('layout.list.status'),
          dataClass: 'details-enabled',
          sortField: 'status.localizedName'
        },
        {
          name: 'position-slot',
          title: this.$t('layout.list.position'),
          dataClass: 'details-enabled',
          sortField: 'position'
        },
        {
          name: 'driver-slot',
          title: this.$t('layout.list.driver'),
          dataClass: 'details-enabled',
          sortField: 'machineDriverModel'
        },
        {
          name: 'tools-slot',
          title: this.$t('layout.list.tools'),
          titleClass: 'center aligned',
          dataClass: 'text-center p-0'
        },
        {
          name: 'row-actions',
          title: this.$t('layout.list.operations'),
          titleClass: 'center aligned',
          dataClass: 'text-center p-0'
        }
      ],
      sortOrder: [{
        field: 'position-slot',
        sortField: 'position',
        direction: 'asc'
      }]
    }
  },
  computed: {
    ...mapGetters({
      lastUpdate: 'machines/lastUpdate',
      data: 'machines/all',
      oneByName: 'machines/oneByName',
      autoRefresh: 'settings/tableAutoRefresh'
    })
  },
  methods: {
    ...mapActions({
      loadMachines: 'machines/load',
      delete: 'machines/delete',
      action: 'machines/action'
    }),
    openDetailRow (data, event) {
      event.target.closest('tr').classList.toggle('selected')
      this.$refs.vuetable.toggleDetailRow(data.id)
    },
    onRefresherTick () {
      this.listRefreshEnabled = false
      this.load(true).finally(() => {
        this.listRefreshEnabled = true
      })
    },
    onCellClicked ({ data, event }) {
      this.detailRowOptions = { component: 'logsOperations' }
      if (data.status.bootStatus.toLowerCase() === 'started') {
        this.openDetailRow(data, event)
      }
    },
    onToolsCellClicked ({ data, event }) {
      this.detailRowOptions = { component: 'toolsList' }
      this.openDetailRow(data, event)
    },
    onRowClass (dataItem, index) {
      return dataItem.status.bootStatus.toLowerCase()
    },
    callStart (rowData) {
      if (rowData.status.bootStatus.toLowerCase() !== 'started' && this.$hasPerms('dcs.machines.actions.start')) {
        this.callAction('Machines.Machines_Start', { machineId: rowData.id })
      }
    },
    callStop (rowData) {
      if (rowData.status.bootStatus.toLowerCase() === 'started' && this.$hasPerms('dcs.machines.actions.stop')) {
        this.callAction('Machines.Machines_Stop', { machineId: rowData.id })
      }
    },
    callDisable (rowData) {
      if (rowData.status.bootStatus.toLowerCase() !== 'started' && this.$hasPerms('dcs.machines.actions.disable')) {
        this.callAction('Machines.Machines_Disable', { machineId: rowData.id })
      }
    },
    callNew () {
      if (this.$hasPerms('dcs.machines.actions.new')) {
        this.$router.push({ name: 'dcs.machines.edit', params: { id: 'new' } })
      }
    },
    callWantsToDelete () {
      if (this.selectedIds.length > 0) {
        this.wantsToDelete = true
      }
    },
    callAction (path, parameters) {
      this.action({ path: path, parameters: parameters }).then(() => {
        this.reload(true).then(() => {
          try {
            this.$broadcast().postMessage('MachinesList')
          } catch (e) {
            if (this.$canLog(0)) console.error(e.message)
          }
          if (this.$canLog(3)) console.log('[Machines] refresh')
          requestAnimationFrame(() => {
            if (this.$refs.vuetable) this.$refs.vuetable.reload()
          })
        })
      }).then(() => {
        this.$messages.success({ message: this.$t('message.success') })
      })
    },
    load (force = false) {
      return this.loadMachines(force)
    }
    // reload (force = false) {
    //   return new Promise((resolve, reject) => {
    //     this.load(force)
    //       .then(() => {
    //         if (this.$canLog(3)) console.log('[Machines] load solved')
    //         resolve()
    //       })
    //       .catch((e) => {
    //         reject(e)
    //       })
    //       .finally(() => {
    //         this.loaded = true
    //       })
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
  .status {
    font-weight: bold;

    &.status-started {
      color: $green;
    }
    &.status-stopped,
    &.status-disabled {
      color: $slate-grey
    }
  }

  /deep/ .started .details-enabled {
    cursor: context-menu;
  }

  /deep/ .vuetable-detail-row {
    background-color: rgba(0, 0, 0, 0.075);
    &:hover {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }

  /deep/ tr.selected {
    background-color: rgba(0, 0, 0, 0.075);
  }

  tr {
    .row-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .action-icon {
      padding: 2px;
      line-height: 1.2;
      &.start.color-green {
        &.started, &.disabled {
          color: $heater;
          cursor: default;
        }
      }

      &.stop.color-red {
        &.stopped, &.disabled {
          color: $heater;
          cursor: default;
        }
      }

      &.tools {
        font-size: 1.5em;
      }
    }

    &.disabled {

    }
  }

</style>