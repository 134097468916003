import ApiReusableVuexModule from '@/lib/api/ApiReusableVuexModule'

const articles = {
  namespaced: true,
  state: ApiReusableVuexModule.state,
  getters: {
    one: (state) => (index) => { return ApiReusableVuexModule.getters.one(state)(index) },
    all: (state) => { return ApiReusableVuexModule.getters.all(state) },
    lastUpdate: (state) => { return ApiReusableVuexModule.getters.lastUpdate(state) },
    oneById: (state) => (id) => {
      let elm = state.list.filter((itm) => { return itm.id === id })
      if (elm.length > 0) {
        return elm[0]
      } else {
        return null
      }
    },
    oneByCode: (state) => (code) => {
      let elm = state.list.filter((itm) => { return itm.code === code })
      if (elm.length > 0) {
        return elm[0]
      } else {
        return null
      }
    }
  },
  mutations: { ...ApiReusableVuexModule.mutations,
    ...{
      addTypes (state, payload) {
        state.serviceTypes = payload
      }
    }
  },
  actions: {
    load: (context, force = false) => {
      return ApiReusableVuexModule.actions.load(context, { path: 'Items.Items_ArticleSearchList', force: force })
    },
    // loadTypes: (context, force = false) => {
    //   if (context.state.serviceTypes && context.state.serviceTypes.length > 0 && !force) {
    //     return new Promise((resolve) => {
    //       resolve(context.state.serviceTypes)
    //     })
    //   }
    //   return ApiReusableVuexModule.actions.load(context, { path: 'Services.Services_ServiceTypeList', method: 'addTypes' })
    // },
    action: (context, { path, parameters = null }) => {
      return ApiReusableVuexModule.actions.action(context, { path, parameters })
    }
  }
}

export default articles