<template>
  <div id="app">
    <message-list></message-list>
    <top-bar></top-bar>
    <div class="app-body">
      <side-bar v-if="isAuthenticated"></side-bar>
      <main class="main">
        <router-view />
      </main>
    </div>
    <DCS-footer></DCS-footer>

  </div>
</template>
<script>
import TopBar from '@/components/layout/TopBar'
import DCSFooter from '@/components/layout/DCSFooter'
import SideBar from '@/components/layout/SideBar'
import MessageList from '@/components/MessageList'
import { mapGetters } from 'vuex'
// import OAuthIframe from '@/lib/oauth2/OAuthIframe'

export default {
  components: { MessageList, SideBar, TopBar, DCSFooter },
  computed: {
    ...mapGetters({
      fontSize: 'settings/fontSize'
    })
  },
  methods: {
  },
  mounted () {
    document.body.classList.remove('fontxsmall', 'fontsmall', 'fontnormal')
    document.body.classList.add(`font${this.fontSize}`)
    document.documentElement.classList.remove('fontxsmall', 'fontsmall', 'fontnormal')
    document.documentElement.classList.add(`font${this.fontSize}`)
  }
}
</script>
<style lang="scss">
  @import "@/styles/app.scss";
  #app {
    font-family: 'DIN-Regular', sans-serif;
    background-color: $white;

    min-height: 100vh;

    .app-body {
      min-height: calc(100vh - 105px);
    }
    /*.app-body.sidebar-lg-show {
      margin-top: 0;
    }*/
  }
</style>
