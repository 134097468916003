<template>
  <div class="row align-items-center">
    <div :class="['col-12  text-center', isError ? 'text-error' : '']">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormRowMessage',
  props: {
    text: {
      type: String,
      required: true
    },
    isError: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>