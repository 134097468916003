<template>
  <header class="app-header navbar top-bar dsc-header">
    <router-link class="nav-brand d-none d-md-block" to="/" v-if="isAuthenticated">
      <img class="navbar-brand-full" alt="ecoprogetti" src="~@/assets/logo.svg" />
    </router-link>

    <SidebarToggler class="d-block d-lg-none" :display="'lg'" v-if="isAuthenticated">
    </SidebarToggler>

    <div class="col title text-center">
      Ecoprogetti DCS Client
    </div>

    <ul class="nav navbar-nav ml-auto nav-right">
      <li class="nav-item notext" v-if="isAuthenticated">
        <a class="nav-link" href="#" @click.prevent="onRefreshPage">
          <refresh-icon :title="$t('menu.refresh')"></refresh-icon>
        </a>
      </li>
      <li class="nav-item dropdown d-md-down-none">
        <a class="nav-link current-locale" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
          <earth-icon :title="$t('menu.language')"></earth-icon>
          {{ $i18n.locale }}
        </a>
        <div class="dropdown-menu lang">
          <a class="dropdown-item available-locale"
             href="#"
             v-for="locale in locales"
             v-bind:key="locale"
             v-if="locale != $i18n.locale"
             @click.prevent="changeLanguage(locale)">
            {{ locale }}
          </a>
        </div>
      </li>

      <li class="nav-item dropdown" v-if="isAuthenticated">
        <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
          <account-icon :title="$t('menu.user')"></account-icon>
          {{ user !== null ? user.given_name : '' }}
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <router-link class="dropdown-item" :to="{name: 'logout'}">
            <logout-variant-icon :title="$t('menu.logout')"></logout-variant-icon>
            {{ $t('menu.logout') }}
          </router-link>
          <router-link class="dropdown-item" :to="{name: 'settings'}">
            <settings-icon :title="$t('menu.settings')"></settings-icon>
            {{ $t('menu.settings') }}
          </router-link>
          <a class="dropdown-item tools" @click.prevent="clearLocalStore">
            <trash-can-outline-icon :title="$t('menu.clear-settings')"></trash-can-outline-icon>
            {{ $t('menu.clear-settings') }}
          </a>
        </div>
      </li>
    </ul>
  </header>
</template>

<script>
import Cookies from 'js-cookie'
import EarthIcon from 'vue-material-design-icons/Earth'
import RefreshIcon from 'vue-material-design-icons/Refresh'
import FaceProfileIcon from 'vue-material-design-icons/FaceProfile'
import LogoutVariantIcon from 'vue-material-design-icons/LogoutVariant'
import MenuIcon from 'vue-material-design-icons/Menu'
import SidebarToggler from './SidebarToggler'
import { mapActions, mapGetters } from 'vuex'
import { i18nConfig } from '@/config/i18n.config.js'
import { events } from '@/lib/messages'
import WrenchIcon from 'vue-material-design-icons/Wrench'
import ServerNetworkOffIcon from 'vue-material-design-icons/ServerNetworkOff'
import { apiConfig } from '@/config/api.config'
import SettingsIcon from 'vue-material-design-icons/Settings'
import TrashCanOutlineIcon from 'vue-material-design-icons/TrashCanOutline'
import AccountIcon from 'vue-material-design-icons/Account'

export default {
  name: 'TopBar',
  components: { AccountIcon, TrashCanOutlineIcon, SettingsIcon, ServerNetworkOffIcon, WrenchIcon, SidebarToggler, MenuIcon, LogoutVariantIcon, FaceProfileIcon, RefreshIcon, EarthIcon },
  data () {
    return {
      apiConfig: apiConfig,
      locales: i18nConfig.locales,
      eventSystem: events
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'oauth2/isAuthOk',
      user: 'oauth2/user'
    })
  },
  methods: {
    ...mapActions({
      getUser: 'oauth2/userInfo'
    }),
    changeLanguage (locale) {
      Cookies.set('locale', locale)
      this.$i18n.locale = locale
      this.eventSystem.$emit('change-language')
    },
    clearLocalStore () {
      localStorage.removeItem(apiConfig.localStorageSpecKey)
      localStorage.removeItem('dcs-settings')
      this.$messages.success({ message: this.$t('message.success') })
    },
    onRefreshPage () {
      if (this.$canLog(2)) console.log('[TopBar] emit refresh page')
      this.eventSystem.$emit('refresh-page')
    }
  },
  mounted () {
  },
  watch: {
    user: {
      handler: function (value) {
        if (!value) {
          this.getUser()
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .app-header.dsc-header {
    padding: 0 1em;
    top: 0;

    .nav-brand {
      .navbar-brand-full {
        height: 40px;
        width: auto;
      }
    }

    .navbar-toggler {
      color: $slate-grey;
    }

    .title {
      position: absolute;
      width: 50vw;
      left: 50%;
      transform: translateX(-50%);

      font-size: 1.2em;

      @include media-breakpoint-up(md) {
        font-size: 2em;
      }
    }

    .dropdown-menu.lang {
      min-width: 0;
      .dropdown-item {
        min-width: 50px;
      }
    }

    .available-locale, .current-locale {
      // text-transform: uppercase;
    }

    .nav-right {
      .nav-item {
        margin: 0 5px;
        &.notext {
          margin: 0;
          min-width: 2em;
        }
      }
    }
  }
</style>