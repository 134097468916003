<template>
  <div class="col-auto text-center">
    <h1>Not Implemented</h1>
    <p>Sorry but this feature is under development.</p>
  </div>
</template>

<script>
export default {
  name: 'NotImplemented'
}
</script>

<style scoped>

</style>