<template>
  <div class="container guidelines">
    <div class="row text-md-center">
      <div class="col-12">
        <h1>guida colori <span class="eco">ecoprogetti</span></h1>
      </div>
    </div>

    <div class="row">
      <div class="col-4 align-self-end">
        <h2>colori principali</h2>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-sm">
        sample
      </div>
      <div class="col-sm">
        variable
      </div>
      <div class="col-sm">
        webcolor
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample bg-black"></div>
      </div>
      <div class="col-sm">
        $black
      </div>
      <div class="col-sm">
        #000000
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample bg-white"></div>
      </div>
      <div class="col-sm">
        $white
      </div>
      <div class="col-sm">
        #FFFFFF
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample bg-orange"></div>
      </div>
      <div class="col-sm">
        $orange
      </div>
      <div class="col-sm">
        #F7941E
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample bg-slate-grey"></div>
      </div>
      <div class="col-sm">
        $slate-grey
      </div>
      <div class="col-sm">
        #58595B
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample bg-dark-grey"></div>
      </div>
      <div class="col-sm">
        $dark-grey
      </div>
      <div class="col-sm">
        #949699
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample bg-light-grey"></div>
      </div>
      <div class="col-sm">
        $light-grey
      </div>
      <div class="col-sm">
        #DCDCDC
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample bg-botticelli"></div>
      </div>
      <div class="col-sm">
        $botticelli
      </div>
      <div class="col-sm">
        #DBE7F0
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample bg-heater"></div>
      </div>
      <div class="col-sm">
        $heater
      </div>
      <div class="col-sm">
        #B3C1CA
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample bg-cerulean"></div>
      </div>
      <div class="col-sm">
        $cerulean
      </div>
      <div class="col-sm">
        #668AAF
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample bg-navy"></div>
      </div>
      <div class="col-sm">
        $navy
      </div>
      <div class="col-sm">
        #064872
      </div>
    </div>
    <div class="row text-md-center">
      <div class="col-12">
        &nbsp;
      </div>
    </div>

    <div class="row">
      <div class="col-4 align-self-end">
        <h2>colori non ufficiali</h2>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-sm">
        sample
      </div>
      <div class="col-sm">
        variabile
      </div>
      <div class="col-sm">
        webcolor
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample" style="background-color: #4dbd74"></div>
      </div>
      <div class="col-sm">
        $success
      </div>
      <div class="col-sm">
        #4dbd74
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample" style="background-color: #c8ced3"></div>
      </div>
      <div class="col-sm">
        $secondary
      </div>
      <div class="col-sm">
        #c8ced3
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample" style="background-color: #f86c6b"></div>
      </div>
      <div class="col-sm">
        $danger
      </div>
      <div class="col-sm">
        #f86c6b
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample" style="background-color: #63c2de"></div>
      </div>
      <div class="col-sm">
        $info
      </div>
      <div class="col-sm">
        #63c2de
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample" style="background-color: #20a8d8"></div>
      </div>
      <div class="col-sm">
        $link
      </div>
      <div class="col-sm">
        #20a8d8
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample" style="background-color: rgba(0, 0, 0, 0.075)"></div>
      </div>
      <div class="col-sm">
        $tableRow
      </div>
      <div class="col-sm">
        rgba(0, 0, 0, 0.075)
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-sm">
        <div class="sample" style="background-color: #ceb62f"></div>
      </div>
      <div class="col-sm">
        $gold
      </div>
      <div class="col-sm">
        #ceb62f
      </div>
    </div>

    <div class="row">
      <div class="col-auto">
        Per altri colori fare riferimento ai componenti Bootstrap.
      </div>
    </div>

    <div class="row text-md-center">
      <div class="col-12">
        <h1>demo componenti</h1>
      </div>
    </div>

    <div class="row">
      <code-editor :code="'{}'"></code-editor>
    </div>
  </div>

</template>

<script>
import CodeEditor from '../components/forms/CodeEditor'

export default {
  name: 'Guidelines',
  components: { CodeEditor },
  mounted () {
  }
}
</script>

<style lang="scss">
  .guidelines {
    padding: 50px 150px;
    .row.align-items-center {
      padding: 10px 0;
      border-bottom: 1px dashed;
    }
    .col-sm {
      text-align: center;
    }
    .sample {
      display: inline-block;
      height: 30px;
      width: 30px;
      &.bg-black {
        background-color: $black;
      }
      &.bg-white {
        background-color: $white;
      }
      &.bg-orange {
        background-color: $orange;
      }
      &.bg-slate-grey {
        background-color: $slate-grey;
      }
      &.bg-dark-grey {
        background-color: $dark-grey;
      }
      &.bg-heater {
        background-color: $heater;
      }
      &.bg-light-grey {
        background-color: $light-grey;
      }
      &.bg-botticelli {
        background-color: $botticelli;
      }
      &.bg-cerulean {
        background-color: $cerulean;
      }
      &.bg-navy {
        background-color: $navy;
      }
    }
  }
</style>