import Vue from 'vue'
import Vuex from 'vuex'

import oAuth2StoreModule from '@/lib/oauth2/vuex-module'
import permissionStoreModule from '@/lib/permissions/vuex-module'
import messages from './messages'

import machines from './machines'
import anomalies from './anomalies'
import services from './services'
import articles from './articles'
import drivers from './drivers'
import mappings from './mappings'
import datachecks from './datachecks'
import queries from './queries'
import settings from './settings'
import statuses from './statuses'

Vue.use(Vuex)

const vx = new Vuex.Store({
  state: {
    packageVersion: JSON.parse(unescape(process.env.PACKAGE_JSON || '%7Bversion%3A0%7D')).version
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion
    }
  },
  mutations: {},
  actions: {},
  modules: {
    oauth2: oAuth2StoreModule,
    messages,
    machines,
    anomalies,
    services,
    articles,
    drivers,
    mappings,
    datachecks,
    queries,
    settings,
    statuses,
    permissions: permissionStoreModule
  }
})

export default vx
