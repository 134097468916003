<template>
  <div class="container dashboard">
    <div class="row pb-0 mt-5">
      <div class="col-md-12 text-capitalize">
        <information-variant-icon :title="''"></information-variant-icon> {{ $t('layout.client-version') }} {{ cliVersion }}
      </div>
    </div>
    <div class="row pt-0 pb-2" v-if="loaded">
      <div class="col-md-12 text-capitalize">
        <information-variant-icon :title="''"></information-variant-icon> {{ $t('menu.version-info') }}:
      </div>
    </div>
    <div class="row pt-1" v-if="loaded">
      <div class="col-md-12" v-for="(value, index) in data.groups" v-bind:key="index">
        <div class="accordion"
             :id="'accordion' + index"
             data-toggle="collapse"
             :data-target="'#collapse' + index"
             aria-expanded="true"
             :aria-controls="'collapse' + index">
          <div class="card border-bottom">
            <div class="card-header" :id="'heading' + index">
                  {{ value.group }}
            </div>
            <div :id="'collapse' + index" class="collapse" :aria-labelledby="'heading' + index" :data-parent="'#accordion' + index">
              <div class="card-body">
                <table class="table table-sm mb-0">
                  <thead>
                    <tr>
                      <th class="text-capitalize border-bottom-danger" v-for="(value, key) in data.groups[index].entries[0]" v-bind:key="key">
                        {{ key }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, i) in data.groups[index].entries" v-bind:key="i">
                    <td v-for="(property, name, ind) in item" v-bind:key="ind">
                      <span class="mobile-caption d-block d-md-none text-capitalize font-weight-bold">{{ name }}</span>
                      {{ property }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoginVariantIcon from 'vue-material-design-icons/LoginVariant'
import InformationVariantIcon from 'vue-material-design-icons/InformationVariant'
import bCollapse from 'bootstrap-vue/es/components/collapse/collapse'
import bButton from 'bootstrap-vue/es/components/button/button'
import bToggleDirective from 'bootstrap-vue/es/directives/toggle/toggle'

import { events } from '@/lib/messages'

export default {
  name: 'Dashboard',
  components: { 'b-btn': bButton, 'b-collapse': bCollapse, LoginVariantIcon, InformationVariantIcon },
  mixins: [ ],
  directives: {
    'b-toggle': bToggleDirective
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      cliVersion: 'appVersion',
      user: 'oauth2/user',
      data: 'statuses/versionInfos'
    }),
    srvVersion: () => { return process.env.VUE_APP_SRV_VERSION }
  },
  methods: {
    ...mapActions({
      load: 'statuses/loadVersionInfos'
    })
  },
  mounted () {
    this.load().finally(() => { this.loaded = true })
    events.$on('change-language', () => {
      if (this.$canLog(2)) console.log('[' + this.$options.name + '] invoked change language')
      this.load(true)
    })
  }
}
</script>

<style lang="scss">
  .dashboard {
    min-height: calc(100vh - 55px);

    .row {
      padding: 3vw 0;
    }

    .logo {
      max-width: 250px;
    }
    .login-message {
      font-size: 2em;
      text-transform: capitalize;
    }
    .username {
      color: $cerulean;
    }

    .card-header {
      cursor: pointer;
    }

    .table {
      table-layout: fixed;
      /*td:nth-last-of-type(2),*/
      td:last-of-type {
        white-space: nowrap;
        width: 1%;
      }

      td:nth-last-child(2) {
        font-weight: bold;
      }
    }

    .border-bottom-danger {
      border-color: $cerulean;
    }
  }
</style>