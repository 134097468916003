<template>
  <div class="container-fluid anomalies-list">
    <div class="card">
      <div class="card-header text-uppercase">
        <format-list-bulleted-icon></format-list-bulleted-icon>
        {{ $t('menu.queries') }}
        &nbsp;
        <inline-help :content="helpTextContent('queries.list.title')"></inline-help>
        <record-counter :can-show="dataLoaded"
                        :rows-per-page="rowsPerPage"
                        :num-results="numResult"></record-counter>
      </div>
      <div class="card-body">
        <div class="card">
          <div class="card-body overflow-visible">
            <input-form-select v-show="loaded"
                               :label="$t('layout.filter.query')"
                               :labelClass="'col-md-3'"
                               :inputClass="'col-auto'"
                               :loaded="loaded"
                               :value="selectedQueryKey"
                               :options="queriesListAsOptions"
                               @selected="onSelectedQuery"></input-form-select>
            <div :class="['row collapse-command-wrapper', selectedQueryKey ? 'show' : '']">
              <div class="col-md-12">
                <hr>
              </div>
              <div class="collapse-command">
                <action-icon @click="canShowFilters = !canShowFilters">
                  <chevron-up-circle-outline-icon v-if="canShowFilters"></chevron-up-circle-outline-icon>
                  <chevron-down-circle-outline-icon v-else></chevron-down-circle-outline-icon>
                </action-icon>
              </div>
            </div>
            <b-collapse class="advanced-filters"
                        id="advancedfilters"
                        v-model="canShowFilters">
              <form class="needs-validation"
                    ref="editform"
                    v-on:submit.prevent="() => { return false }"
                    novalidate>
              <div class="form-inputs__wrapper">
                <component class="form-row" v-for="(filter, index) in query.filters" v-bind:key="index" v-if="query"
                           :is="filterComponent(filter)"
                           :label="filter.name"
                           :labelClass="'full-width'"
                           :inputClass="''"
                           :loaded="loaded"
                           :config="datePickerConfig(filter)"
                           :type="filter.type"
                           :value="query.filters[index].value"
                           :required="filter.isRequired"
                           :options="enumOptions(filter)"
                           @input="(value) => { onQueryFilterValue (index, value) }"></component>
              </div>
              <div class="row clear-filters justify-content-between" v-if="query">
                <div class="col-md-8">
                </div>
                <div class="col-md-2">
                  <button type="button"
                          @click.prevent="onClickClearFilters"
                          class="btn btn-outline-secondary action-reset btn-block text-capitalize active">
                    {{ $t('layout.button.reset') }}
                  </button>
                </div>
                <div class="col-md-2">
                  <button type="button"
                          @click.prevent="onClickRunQuery"
                          class="btn btn-outline-success action-reset btn-block text-capitalize active">
                    {{ $t('layout.button.run-query') }}
                  </button>
                </div>
              </div>
              </form>
            </b-collapse>
            <div class="row" v-if="selectedQueryKey">
              <div class="col-12">
                <rows-quantity-select :rows-per-page-list="rowsPerPageList"
                                      :value="rowsPerPage"
                                      @change="(value) => { setTableRows ({ key: $options.name, value: value }) }"></rows-quantity-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row list">
          <div class="col-12">
            <div class="text-center loading" v-show="!loaded">{{ $t('message.loading') }}</div>
            <query-table v-if="dataLoaded"
                         :query="runQuery"
                         :rows-per-page="rowsPerPage"
                         :current-page="currentPage"
                         @numResultChanged="(count) => numResult = count">
            </query-table>
          </div>
        </div>
        <div class="row paging">
          <div class="col-12">
            <query-table-paging v-if="dataLoaded"
                                :rows-per-page="rowsPerPage"
                                :num-result="numResult"
                                @change-page="(data) => currentPage = data"></query-table-paging>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import bCollapse from 'bootstrap-vue/es/components/collapse/collapse'
import bButton from 'bootstrap-vue/es/components/button/button'
import bToggleDirective from 'bootstrap-vue/es/directives/toggle/toggle'
import InputFormSelect from '@/components/forms/InputFormSelect'
import InputFormText from '@/components/forms/InputFormText'
import InputFormDateTime from '@/components/forms/InputFormDateTime'
import InputFormMultiSelect from '@/components/forms/InputFormMultiSelect'
// import InputFormSwitch from '@/components/forms/InputFormSwitch'
import InputFormMultiToggle from '@/components/forms/InputFormMultiToggle'
import { clone } from 'lodash'
import ActionIcon from '../../components/layout/ActionIcon'
import ChevronUpCircleOutlineIcon from 'vue-material-design-icons/ChevronUpCircleOutline'
import ChevronDownCircleOutlineIcon from 'vue-material-design-icons/ChevronDownCircleOutline'
import QueryTable from '../../components/QueryTable'
import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted'
import QueryTablePaging from '../../components/QueryTablePaging'
import RowsQuantitySelect from '../../components/forms/RowsQuantitySelect'
import RecordCounter from '../../components/RecordCounter'
import InlineHelp from '../../components/InlineHelp'
import HelpTextsMixin from '../../mixins/HelpTextsMixin'

export default {
  name: 'QueriesList',
  components: { InlineHelp, RecordCounter, RowsQuantitySelect, QueryTablePaging, FormatListBulletedIcon, QueryTable, ChevronDownCircleOutlineIcon, ChevronUpCircleOutlineIcon, ActionIcon, 'Boolean': InputFormMultiToggle, 'Multi': InputFormMultiSelect, 'Enum': InputFormSelect, 'String': InputFormText, 'Date': InputFormDateTime, 'DateTime': InputFormDateTime, 'Real': InputFormText, 'Numeric': InputFormText, 'b-btn': bButton, 'b-collapse': bCollapse, InputFormSelect },
  directives: {
    'b-toggle': bToggleDirective
  },
  mixins: [ HelpTextsMixin ],
  data () {
    return {
      loaded: false,
      dataLoaded: false,
      query: {
        filters: null
      },
      selectedQueryKey: null,
      runQuery: null,
      canShowFilters: false,
      currentPage: 1,
      rowsPerPageList: [ 5, 15, 30, 60, 120 ],
      numResult: 0,
      datePickerConfigDefault: {
        format: 'YYYY-MM-DD HH:mm',
        useCurrent: false,
        stepping: 30,
        showClear: true,
        showClose: true,
        icons: {
          time: 'material-icons access_time',
          date: 'material-icons calendar_today',
          up: 'material-icons arrow_drop_up',
          down: 'material-icons arrow_drop_down',
          previous: 'material-icons keyboard_arrow_left',
          next: 'material-icons keyboard_arrow_right',
          today: 'material-icons today',
          clear: 'material-icons delete',
          close: 'material-icons clear'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      queries: 'queries/all',
      tableRows: 'settings/tableRows'
    }),
    rowsPerPage () {
      return this.tableRows(this.$options.name)
    },
    category () {
      return this.$route.params.category
    },
    queriesList () {
      return this.queries ? this.queries.filter((query) => {
        return query.category.key === this.category
      }) : []
    },
    queriesListAsOptions () {
      return [
        {
          value: null,
          label: '-',
          search: ''
        }
      ].concat(this.queriesList.map((item) => {
        return {
          value: item.key,
          label: item.name,
          search: item.name
        }
      }))
    }
  },
  methods: {
    ...mapMutations({
      setTableRows: 'settings/tableRows'
    }),
    ...mapActions({
      load: 'queries/load'
    }),
    appliedFilters () {
      let f = this.query.filters.filter((item) => {
        return item.value !== null && item.value !== ''
      })
        .map((item) => {
          let val = item.value
          if (val !== 'object') {
            val = [ val ]
          }
          return {
            key: item.key,
            values: val
          }
        })
      return f
    },
    reload (force = false) {
      return new Promise((resolve, reject) => {
        this.load(force)
          .then(() => {
            if (this.$canLog(3)) console.log('[' + this.$options.name + '] load solved')
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            this.loaded = true
          })
      })
    },
    filterComponent (filter) {
      if (filter.type === 'Enum' && filter.isMultipleValue) return 'Multi'
      return filter.type
    },
    checkIfFormIsValid () {
      return this.$el.querySelectorAll('form :invalid').length === 0
    },
    onQueryFilterValue (index, value) {
      if (this.$canLog(3)) console.log('[QueriesList] Filter changed', index, value)
      this.query.filters[index].value = value
      // console.log(this.query.filters[index], this.appliedFilters())
      // console.log(this.query.filters[index])
    },
    onSelectedQuery (item) {
      if (this.$canLog(3)) console.log('[QueriesList] onSelectedQuery', item)
      this.canShowFilters = false
      this.selectedQueryKey = item.value
      if (this.selectedQueryKey) {
        let query = this.queriesList.filter((query) => {
          return query.key === this.selectedQueryKey
        })[0]
        query.filters.forEach((item) => {
          item.value = null
        })
        this.query = query
        setTimeout(() => {
          this.canShowFilters = true
        }, 500)
      }
    },
    enumOptions (filter) {
      if (filter.type === 'Boolean') {
        return [
          {
            label: 'unset',
            value: null
          },
          {
            label: 'true',
            value: true
          },
          {
            label: 'false',
            value: false
          }
        ]
      } else {
        let options = filter.allowedEnumValues ? filter.allowedEnumValues.map((item) => {
          return {
            value: item.key,
            label: item.value,
            search: item.value
          }
        }) : []

        if (!filter.isMultipleValue) {
          return [
            {
              value: null,
              label: '-',
              search: ''
            }
          ].concat(options)
        } else {
          return options
        }
      }
    },
    datePickerConfig (filter) {
      let now = new Date()
      let addMinutes = now.getMinutes() < this.datePickerConfigDefault.stepping ? 30 : 0
      let addHours = now.getMinutes() > this.datePickerConfigDefault.stepping ? 1 : 0
      let fromDatePickerConfig = clone(this.datePickerConfigDefault)
      fromDatePickerConfig.maxDate = new Date()
      fromDatePickerConfig.maxDate.setMinutes(now.getMinutes() + addMinutes)
      fromDatePickerConfig.maxDate.setHours(now.getHours() + addHours)
      switch (filter.type) {
        case 'Date':
          fromDatePickerConfig.format = 'YYYY-MM-DD'
          break
        case 'Time':
          fromDatePickerConfig.format = 'HH:mm'
          break
        default:
      }
      return fromDatePickerConfig
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onClickClearFilters () {
      this.query.filters = this.query.filters.map((filter) => {
        return { ...filter, value: null }
      })
      if (this.$canLog(3)) console.log(this.query.filters)
    },
    onClickRunQuery () {
      if (this.$canLog(3)) console.log(this.appliedFilters())

      if (!this.checkIfFormIsValid()) {
        this.$messages.error({ message: this.$t('message.please-check-your-data') })
      } else {
        this.canShowFilters = false
        this.runQuery = {
          key: this.query.key,
          appliedFilters: this.appliedFilters(),
          sortFieldKey: '',
          isSortAscending: '',
          timestamp: Date()
        }
        this.dataLoaded = true
      }
    }
  },
  mounted () {
    this.loaded = false
    this.reload().catch((e) => {
      // this.$messages.error({ title: this.$t('message.error'), message: (e.response && e.response.body) ? e.response.body : e.message })
    })
  },
  watch: {
    lastUpdate: {
      handler: function () {
        if (this.loaded) {
          this.flattenData()
        }
      }
    },
    category: {
      handler: function () {
        this.selectedQueryKey = null
        this.canShowFilters = false
      }
    },
    currentPage: {
      handler: function () {
        this.onClickRunQuery()
      }
    },
    rowsPerPage: {
      handler: function () {
        if (!this.canShowFilters) {
          this.onClickRunQuery()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .anomalies-list {
    .collapse-command-wrapper {
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition: opacity .2s ease-out;
      position: relative;

      &.show {
        height: auto;
        opacity: 1;
      }

      .collapse-command {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);

        padding: 0 .5em 0 .5em;
        background-color: $white;
        font-size: 1.5em;

        a {
          color: $dark-grey;
        }
      }
    }

    .advanced-filters {
      .form-inputs__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .form-row {
          width: 100%;
          flex: 0 0 100%;

          @include media-breakpoint-up(md) {
            width: 50%;
            flex: 0 0 50%;
            margin: 0;
          }

          /deep/ label.full-width {
            width: 100%;
          }
        }
      }
    }

    /deep/ .table.vuetable {
      font-size: .9rem;
      thead th, tbody tr td {
        white-space: nowrap;
      }
    }

    .list {
      overflow-x: auto;
    }
  }
</style>