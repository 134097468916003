<template>
  <th v-if="isHeader"
      class="vuetable-th-component-checkbox">
    <input type="checkbox"
           @change="toggleAllCheckbox($event)"
           :checked="isAllItemsInCurrentPageSelected()">
  </th>
  <td v-else
      class="vuetable-td-component-checkbox">
    <input type="checkbox"
           @change="toggleCheckbox(rowData, $event)"
           :checked="isSelected(rowData)">
  </td>
</template>
<script>
import VuetableFieldCheckboxMixin from 'vuetable-2/src/components/VuetableFieldCheckboxMixin.vue'
export default {
  name: 'VuetableFieldCheckboxCustom',
  mixins: [ VuetableFieldCheckboxMixin ],
  methods: {
    /* isSelected (rowData) {
      return rowData.displayActive ? this.vuetable.isSelectedRow(rowData[this.vuetable.trackBy]) : false
    } */
  }
}
</script>