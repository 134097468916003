<template>
  <div class="container-fluid anomalies-list">
    <div class="card">
      <div class="card-header text-uppercase">
        <format-list-bulleted-icon></format-list-bulleted-icon>
        {{ $t('menu.anomalies') }}
        &nbsp;
        <inline-help :content="helpTextContent('anomalies.list.title')"></inline-help>
        <record-counter :can-show="loaded && loadedData"
                        :rows-per-page="rowsPerPage"
                        :num-results="numResults"></record-counter>
      </div>
      <div class="card-body">
        <div class="card">
          <div class="card-body overflow-visible">
            <div class="row filters" v-show="loaded">
              <div class="col-md-3">
                <fieldset role="group" class="b-form-group form-group">
                  <label class="filter-label text-capitalize">{{ $t('layout.filter.machine') }}</label>
                  <select class="form-control" v-model="filters.MachineId" @input="onFilterChange">
                    <option value="">{{ $t('layout.filter.all') }}</option>
                    <option v-for="machine in machinesList" v-bind:key="machine.id" :value="machine.id">{{ machine.name }}</option>
                  </select>
                </fieldset>
              </div>
              <div class="col-md-3">
                <fieldset role="group" class="b-form-group form-group">
                  <label class="filter-label text-capitalize">{{ $t('layout.filter.anomaly-type') }}</label>
                  <select class="form-control" v-model="filters.Type" @input="onFilterChange">
                    <option value="">{{ $t('layout.filter.all') }}</option>
                    <option v-for="anomalyType in anomaliesTypesList" v-bind:key="anomalyType.id" :value="anomalyType.id">{{ anomalyType.name }}</option>
                  </select>
                </fieldset>
              </div>
                <input-form-text :class="'col-md-3'"
                                 :label-class="'pt-0'"
                                 :input-class="'w-100'"
                                 :label="$t('layout.list.serial-number')"
                                 :value="filters.ItemCode"
                                 @press-enter="onFilterChange"
                                 @input="(value) => { filters.ItemCode = value }"></input-form-text>
              <div class="col-md-3">
                <fieldset role="group" class="b-form-group form-group" v-if="anomaliesActions">
                  <label class="filter-label text-capitalize">{{ $t('layout.filter.selected-action') }}</label>
                  <select class="form-control" v-model="filters.SelectedAction" @input="onFilterChange">
                    <option value="">{{ $t('layout.filter.all') }}</option>
                    <option v-for="(anomaliesAction, index) in anomaliesActionsList" v-bind:key="index" :value="anomaliesAction">{{ anomaliesAction }}</option>
                  </select>
                </fieldset>
              </div>
            </div>
            <div class="row filters" v-show="loaded">
              <div class="col-md-3">
                <input-form-multi-toggle :label="$t('layout.text.show')"
                                         :label-class="'col-form-label full-width text-capitalize pt-0'"
                                         :value="filters.Handled === '' ? null : filters.Handled"
                                         :options="[
                                           { value: null, label: $t('layout.filter.all') },
                                           { value: true, label: $t('layout.filter.solved') },
                                           { value: false, label: $t('layout.filter.unsolved') },
                                         ]"
                                         @input="(value) => { filters.Handled = value === null ? '' : value; onFilterChange() }">
                </input-form-multi-toggle>
              </div>
            </div>
            <div class="row advanced-filters-command" v-show="loaded">
              <b-btn variant="link" v-b-toggle.advancedfilters
                     :aria-expanded="showAdvancedFilters ? 'true' : 'false'">
                {{ showAdvancedFilters ? $t('layout.filter.advanced-hide') : $t('layout.filter.advanced-show') }}
              </b-btn>
            </div>
            <b-collapse class="advanced-filters"
                        id="advancedfilters"
                        v-model="showAdvancedFilters"
                        v-show="loaded">
              <div class="row advanced-filters">
                <div class="col-md-3">
                  <fieldset role="group" class="b-form-group form-group">
                    <label class="filter-datefrom text-capitalize">{{ $t('layout.filter.from') }}</label>
                    <date-picker v-model="filters.OpenedFrom" @input="onFilterChange" :config="fromDatePickerOptions"></date-picker>
                  </fieldset>
                </div>
                <div class="col-md-3">
                  <fieldset role="group" class="b-form-group form-group">
                    <label class="filter-datefrom text-capitalize">{{ $t('layout.filter.to') }}</label>
                    <date-picker v-model="filters.OpenedTo" @input="onFilterChange" :config="toDatePickerOptions"></date-picker>
                  </fieldset>
                </div>
              </div>
            </b-collapse>
            <div class="row clear-filters justify-content-between" v-show="loaded">
              <div class="col-md-10">
                <rows-quantity-select :rows-per-page-list="rowsPerPageList"
                                      :value="rowsPerPage"
                                      @change="onRowsPerPageChange"></rows-quantity-select>
              </div>
              <div class="col-md-2">
                <button type="button"
                        @click.prevent="onClickClearFilters"
                        :class="[
                          'btn btn-outline-secondary action-reset btn-block text-capitalize',
                          hasFilters ? 'active' : 'disabled'
                        ]">
                  {{ $t('layout.button.reset') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-3">
          <div class="col-md-12">
            <refresher class="float-right"
                       v-show="loaded"
                       :seconds="autoRefresh('AnomaliesList')"
                       :enabled="listRefreshEnabled"
                       @tick="onRefresherTick"></refresher>
          </div>
        </div>
        <list-bulk-actions
                :disabled="selectedIds.length === 0"
                :show="loaded"
                @bulk-action-retry="actionRetry(selectedIds)"
                @bulk-action-ignore="actionIgnore(selectedIds)"></list-bulk-actions>
        <div class="row list">
          <div class="col-12">
            <div class="text-center loading" v-show="!(loaded && loadedData)">{{ $t('message.loading') }}</div>
            <table class="vuetable table table-bordered table-hover table-sm mb-0" v-if="loaded && loadedData">
              <thead>
                <th class="checkboxes">
                  <input type="checkbox" name="selectedAllIds" @input="toggleSelectAll" :checked="selectedIds.length === allPageIds.length"/>
                </th>
                <th class="openCommand"></th>
                <th v-for="(column, index) in columnsDefs"
                    @click="changeSort(column)"
                    class="sortable"
                    v-bind:key="index"
                    v-if="!column.hidden">
                  {{ column.title }}
                  <unfold-more-horizontal-icon v-if="sortColumn !== column.key && column.sortable"></unfold-more-horizontal-icon>
                  <chevron-down-icon v-if="sortColumn === column.key && sortDirection === 'ASC' && column.sortable"></chevron-down-icon>
                  <chevron-up-icon v-if="sortColumn === column.key && sortDirection === 'DESC' && column.sortable"></chevron-up-icon>
                </th>
                <th class="">{{ $t('layout.list.actions') }}</th>
              </thead>
              <tbody>
              <tr v-for="(row, index) in data" v-bind:key="index" :class="(row.closed !== null) ? 'disabled' : ''">
                <td class="text-center">
                  <input type="checkbox" name="selectedIds[]" :value="row.id" v-model="selectedIds"/>
                </td>
                <td class="text-center">
                  <action-icon @click="openDetails(row.id)">
                    <playlist-edit-icon class="action-icon" :title="''"></playlist-edit-icon>
                  </action-icon>
                </td>
                <!-- machineName -->
                <td class="text-center singleline">
                  <span class="mobile-caption d-block d-md-none">{{ columnsDefs[0].title }}</span>
                  <a href="#" @click.prevent="onSetFilterFromList('Machines', row)">{{ row[columnsDefs[0].key] }}</a>
                </td>
                <!-- anomalyType -->
                <td class="text-center">
                  <span class="mobile-caption d-block d-md-none">{{ columnsDefs[1].title }}</span>
                  <a href="#" @click.prevent="onSetFilterFromList('Type', row)">
                    <span class="anomaly anomaly-workcompletedfailed">
                      {{ row[columnsDefs[1].key] }}
                    </span>
                  </a>
                </td>
                <!-- shortMessage -->
                <td v-if="!columnsDefs[2].hidden">
                  <span class="mobile-caption d-block d-md-none">{{ columnsDefs[2].title }}</span>
                  <span class="">{{ row[columnsDefs[2].key] }}</span>
                </td>
                <!-- itemCode -->
                <td v-if="!columnsDefs[3].hidden">
                  <span class="mobile-caption d-block d-md-none">{{ columnsDefs[3].title }}</span>
                  <a href="#" @click.prevent="onSetFilterFromList('ItemCode', row)">
                    <span class="">{{ row[columnsDefs[3].key] }}</span>
                  </a>
                </td>
                <!-- selectedAction -->
                <td class="text-center singleline" v-if="!columnsDefs[4].hidden">
                  <span class="mobile-caption d-block d-md-none">{{ columnsDefs[4].title }}</span>
                  <span :class="['status', columnsDefs[4].key !== null ? 'status-' + columnsDefs[4].key : 'status-null' ]">
                  {{ row[columnsDefs[4].key] ? row[columnsDefs[4].key] : '-' }}</span>
                </td>
                <!-- opened -->
                <td class="text-center singleline" v-if="!columnsDefs[5].hidden">
                  <span class="mobile-caption d-block d-md-none">{{ columnsDefs[5].title }}</span>
                  <span>{{ row[columnsDefs[5].key] ? row[columnsDefs[5].key] : '-'}}</span>
                </td>
                <!-- closed -->
                <td class="text-center singleline"  v-if="!columnsDefs[6].hidden">
                  <span class="mobile-caption d-block d-md-none">{{ columnsDefs[6].title }}</span>
                  <span>{{ row[columnsDefs[6].key] ? row[columnsDefs[6].key] : '-'}}</span>
                </td>
                <td class="singleline">
                  <div class="row-actions">
                    <action-icon
                            class="action-retry color-green"
                            :active="$hasPerms('dcs.anomalies.actions.retry') &&
                                    $bitPerms(anomaliesActions.indexOf('Retry')+1, row.supportedActions) &&
                                    row.closed === null"
                            @click="actionRetry([row.id])">
                      <reload-icon
                              v-tooltip="helpTextContent('anomalies.row.button.retry')"
                              :title="''"></reload-icon>
                    </action-icon>
                    <action-icon
                            class="action-ignore color-red"
                            :active="$hasPerms('dcs.anomalies.actions.ignore') &&
                      $bitPerms(anomaliesActions.indexOf('Ignore')+1, row.supportedActions) &&
                      row.closed === null"
                            @click="actionIgnore([row.id])">
                      <close-circle-icon
                              v-tooltip="helpTextContent('anomalies.row.button.ignore')"
                              :title="''"></close-circle-icon>
                    </action-icon>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row paging">
          <div class="col-12">
            <query-table-paging v-if="loaded" v-show="loadedData"
                                :rows-per-page="rowsPerPage"
                                :num-result="numResults"
                                @change-page="onPageChange"></query-table-paging>
          </div>
        </div>
        <list-bulk-actions
          :disabled="selectedIds.length === 0"
          :show="loaded"
          @bulk-action-retry="actionRetry(selectedIds)"
          @bulk-action-ignore="actionIgnore(selectedIds)"></list-bulk-actions>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { events } from '@/lib/messages'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { isEqual, clone, sortBy } from 'lodash'
import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted'
import ReloadIcon from 'vue-material-design-icons/Reload'
import DatePicker from 'vue-bootstrap-datetimepicker/src/component'
import ActionIcon from '@/components/layout/ActionIcon'
import CloseCircleIcon from 'vue-material-design-icons/CloseCircle'
import UnfoldMoreHorizontalIcon from 'vue-material-design-icons/UnfoldMoreHorizontal'
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown'
import PlaylistEditIcon from 'vue-material-design-icons/PlaylistEdit'
import ChevronUpIcon from 'vue-material-design-icons/ChevronUp'
import QueryTablePaging from '../../components/QueryTablePaging'
import bCollapse from 'bootstrap-vue/es/components/collapse/collapse'
import bButton from 'bootstrap-vue/es/components/button/button'
import bToggleDirective from 'bootstrap-vue/es/directives/toggle/toggle'
import RowsQuantitySelect from '../../components/forms/RowsQuantitySelect'
import RecordCounter from '../../components/RecordCounter'
import Refresher from '../../components/Refresher'
import InputFormMultiToggle from '../../components/forms/InputFormMultiToggle'
import InputFormText from '../../components/forms/InputFormText'
import InlineHelp from '../../components/InlineHelp'
import HelpTextsMixin from '../../mixins/HelpTextsMixin'
import ListBulkActions from './ListBulkActions'

export default {
  name: 'AnomaliesList',
  components: { ListBulkActions, InputFormText, PlaylistEditIcon, InputFormMultiToggle, InlineHelp, Refresher, RecordCounter, RowsQuantitySelect, 'b-btn': bButton, 'b-collapse': bCollapse, QueryTablePaging, ChevronUpIcon, ChevronDownIcon, UnfoldMoreHorizontalIcon, CloseCircleIcon, ActionIcon, DatePicker, ReloadIcon, FormatListBulletedIcon },
  mixins: [ HelpTextsMixin ],
  directives: {
    'b-toggle': bToggleDirective
  },
  data () {
    return {
      loaded: false,
      listRefreshEnabled: false,
      loadedData: false,
      selectedIds: [],
      defaultFilters: {
        MachineId: '',
        Handled: false,
        Type: '',
        ItemCode: '',
        SelectedAction: '',
        OpenedFrom: '',
        OpenedTo: ''
      },
      filters: {
        Handled: false
      },
      showAdvancedFilters: false,
      currentPage: 1,
      rowsPerPageList: [ 5, 15, 30, 60, 120 ],
      sortColumn: 'id',
      sortDirection: 'ASC',
      datePickerOptions: {
        format: 'YYYY-MM-DD HH:mm',
        useCurrent: false,
        stepping: 30,
        showClear: true,
        showClose: true,
        icons: {
          time: 'material-icons access_time',
          date: 'material-icons calendar_today',
          up: 'material-icons arrow_drop_up',
          down: 'material-icons arrow_drop_down',
          previous: 'material-icons keyboard_arrow_left',
          next: 'material-icons keyboard_arrow_right',
          today: 'material-icons today',
          clear: 'material-icons delete',
          close: 'material-icons clear'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      lastUpdate: 'anomalies/lastUpdate',
      data: 'anomalies/all',
      numResults: 'anomalies/totalItems',
      totalPages: 'anomalies/totalPages',
      anomaliesTypes: 'anomalies/allTypes',
      anomaliesActions: 'anomalies/allActions',
      machines: 'machines/all',
      tableRows: 'settings/tableRows',
      autoRefresh: 'settings/tableAutoRefresh'
    }),
    columnsDefs () {
      return [
        {
          key: 'machineName',
          title: this.$t('layout.list.machine'),
          sortable: false,
          hidden: false
        },
        {
          key: 'anomalyType',
          title: this.$t('layout.list.anomaly-type'),
          sortable: false,
          hidden: false
        },
        {
          key: 'shortMessage',
          title: this.$t('layout.list.short-message'),
          sortable: false,
          hidden: false
        },
        {
          key: 'itemCode',
          title: this.$t('layout.list.serial-number'),
          sortable: false,
          hidden: false
        },
        {
          key: 'selectedAction',
          title: this.$t('layout.list.selected-action'),
          sortable: true,
          hidden: this.filters.Handled === false
        },
        {
          key: 'opened',
          title: this.$t('layout.list.creation-date'),
          sortable: false,
          sortField: 'OpenedFrom',
          hidden: false
        },
        {
          key: 'closed',
          title: this.$t('layout.list.closure-date'),
          sortable: false,
          hidden: this.filters.Handled === false
        }
      ]
    },
    rowsPerPage () {
      return this.tableRows(this.$options.name)
    },
    allPageIds () {
      return this.data.map((item) => item.id)
    },
    hasFilters () {
      return !(isEqual(this.filters, this.defaultFilters))
    },
    fromDatePickerOptions () {
      let now = new Date()
      let addMinutes = now.getMinutes() < this.datePickerOptions.stepping ? 30 : 0
      let addHours = now.getMinutes() > this.datePickerOptions.stepping ? 1 : 0
      let fromDatePickerOptions = clone(this.datePickerOptions)
      fromDatePickerOptions.maxDate = new Date()
      fromDatePickerOptions.maxDate.setMinutes(now.getMinutes() + addMinutes)
      fromDatePickerOptions.maxDate.setHours(now.getHours() + addHours)
      return fromDatePickerOptions
    },
    toDatePickerOptions () {
      let toDatePickerOptions = clone(this.datePickerOptions)
      if (this.filters.OpenedFrom && this.filters.OpenedFrom !== '') {
        toDatePickerOptions.minDate = this.filters.OpenedFrom
      }
      toDatePickerOptions.maxDate = this.fromDatePickerOptions.maxDate
      return toDatePickerOptions
    },
    anomaliesActionsList () {
      return this.anomaliesActions.slice().sort()
    },
    machinesList () {
      return sortBy(this.machines, 'name')
    },
    anomaliesTypesList () {
      if (this.anomaliesTypes) {
        return sortBy(this.anomaliesTypes.map((item) => {
          return {
            id: item.code,
            name: item.localizedName,
            search: item.localizedName
          }
        }), 'name')
      } else {
        return []
      }
    },
    valorizedFilters () {
      // estrae i valori che sono diversi dal default
      return Object.keys(this.filters).reduce((object, key) => {
        if (this.filters[key] !== this.defaultFilters[key]) {
          object[key] = this.filters[key]
        }
        object.Handled = this.filters.Handled ? null : this.filters.Handled
        return object
      }, {})
    }
  },
  methods: {
    ...mapMutations({
      setTableRows: 'settings/tableRows'
    }),
    ...mapActions({
      loadMachines: 'machines/load',
      loadAnomalies: 'anomalies/loadWithParms',
      loadTypes: 'anomalies/loadTypes',
      loadActions: 'anomalies/loadActions',
      handle: 'anomalies/handle'
    }),
    openDetails (id) {
      this.$router.push({ name: 'dcs.anomalies.details', params: { id: id } })
    },
    onSetFilterFromList (type, row) {
      switch (type) {
        case 'Machines':
          this.filters.MachineId = row.machineId
          break
        case 'Type':
          this.filters.Type = row.type.code
          break
        case 'ItemCode':
          this.filters.ItemCode = row.itemCode
          break
      }
      this.onFilterChange()
    },
    onRefresherTick () {
      this.listRefreshEnabled = false
      this.refresh().finally(() => {
        this.listRefreshEnabled = true
      })
    },
    toggleSelectAll (e) {
      let selectAll = e.target.checked
      if (!selectAll) {
        if (this.selectedIds.length > 0) {
          this.selectedIds = []
        }
      } else {
        this.selectedIds = this.allPageIds
      }
    },
    changeSort (column) {
      if (column.sortable) {
        let sortField = column.sortField ? column.sortField : column.key
        if (this.sortColumn === sortField) {
          if (this.sortDirection === 'DESC') {
            this.sortDirection = 'ASC'
          } else {
            this.sortDirection = 'DESC'
          }
        }
        this.sortColumn = sortField
        this.reload()
      }
    },
    resetFilters () {
      this.filters = clone(this.defaultFilters)
      this.onFilterChange()
    },
    onRowsPerPageChange (value) {
      this.setTableRows({ key: this.$options.name, value: value })
      this.currentPage = 1
      this.$nextTick(() => {
        this.reload()
      })
    },
    onPageChange (data) {
      this.currentPage = data
      this.$nextTick(() => {
        this.reload()
      })
    },
    onFilterChange () {
      this.currentPage = 1
      this.$nextTick(() => {
        this.reload()
      })
    },
    load () {
      return Promise.all([
        this.reload(),
        this.loadTypes(),
        this.loadActions(),
        this.loadMachines()
      ]).finally(() => {
        this.loaded = true
        this.listRefreshEnabled = true
      })
    },
    onRowClass (dataItem, index) {
      return (dataItem.closed !== null) ? 'disabled' : ''
    },
    actionIgnore (ids, enabled = true) {
      if (!enabled) { return }
      let parameters = {
        ids: ids,
        action: this.anomaliesActions.indexOf('Ignore') + 1
      }
      if (this.$canLog(2)) console.log('[Anomalies] action ignore', parameters)
      this.handle(parameters).then(() => {
        this.reload()
        try {
          this.$broadcast().postMessage('AnomaliesList')
        } catch (e) {
          if (this.$canLog(0)) console.error(e.message)
        }
      })
    },
    actionRetry (ids) {
      let enabled = this.$hasPerms('dcs.anomalies.actions.retry')
      if (!enabled) { return }
      let parameters = {
        ids: ids,
        action: this.anomaliesActions.indexOf('Retry') + 1
      }
      if (this.$canLog(2)) console.log('[Anomalies] action retry', parameters)
      this.handle(parameters).then(() => {
        this.reload()
        try {
          this.$broadcast().postMessage('AnomaliesList')
        } catch (e) {
          if (this.$canLog(0)) console.error(e.message)
        }
      })
    },
    refresh () {
      return this.loadAnomalies({
        filters: this.valorizedFilters,
        pageIndex: this.currentPage,
        pageSize: this.rowsPerPage,
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection
      }).then(() => {
        this.selectedIds = []
      })
    },
    reload () {
      this.loadedData = false
      this.listRefreshEnabled = false
      if (this.$canLog(4)) {
        console.log({
          filters: this.valorizedFilters,
          pageIndex: this.currentPage,
          pageSize: this.rowsPerPage,
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection
        })
      }
      return this.refresh().finally(() => {
        this.loadedData = true
        this.listRefreshEnabled = true
      })
    },
    filterData (data, filters) {
    },
    saveFilters () {
      localStorage.setItem('anomalies-list-filters', JSON.stringify(this.filters))
    },
    loadFilters () {
      this.resetFilters()
      // if (localStorage.getItem('anomalies-list-filters')) {
      //   this.filters = JSON.parse(localStorage.getItem('anomalies-list-filters'))
      //   this.closedCheckbox = (this.filters.closed === false)
      // } else {
      //   this.resetFilters()
      // }
    },
    onClickClearFilters () {
      this.resetFilters()
    }
  },
  mounted () {
    if (this.$canLog(2)) console.log('[Anomalies] init filters')
    this.loadFilters()
    this.load().catch((e) => {
      // this.$messages.error({ title: this.$t('message.error'), message: (e.response && e.response.body) ? e.response.body : e.message })
    })
    events.$on('change-language', () => {
      if (this.$canLog(2)) console.log('[Services] invoked change language')
      this.load(true)
    })
    events.$on('refresh-page', () => {
      if (this.$canLog(2)) console.log('[Services] invoked refresh page')
      this.reload()
    })
  },
  watch: {
  }
}
</script>

<style lang="scss">
  .container-fluid.anomalies-list {
    margin-top: 3em;
    .list {
      overflow-x: auto;
    }

    .filter-label {
      margin-right: 1em;
    }

    .width-auto {
      width: auto;
      display: inline-block;
    }

    .row.list, .row.clear-filters {
      margin-top: 1em;
    }

    .card-header {
      background-color: $botticelli;
    }

    .card-body {
      overflow: visible;

      &.overflow-visible {
        overflow: visible;
      }
    }

    .anomaly {
      font-weight: bold;
      color: $orange;

      &-workcompletedfailed {

      }
    }

 /*   .disabled {
      .anomaly, .vuetable-td-shortMessage, .vuetable-td-opened, .vuetable-td-closed {
        color: $dark-grey;
      }
    }*/

    .status {
      font-weight: bold;

      &-created {
        color: $navy;
      }
      &-retried {
        color: $green;
      }
      &-ignored {
        color: $red;
      }
    }

    .row-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .vuetable thead th, .vuetable tbody tr td.singleline {
      white-space: nowrap;
    }
  }
</style>