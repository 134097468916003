<template>
  <modal class="machines-edit modal-edit"
         v-show="originalMachine.id !== null && showMe"
         :requestConfirmBeforeClose="hasModification"
         :confirmCloseMessage="$t('layout.edit.save-before-exit')"
         :return-route-name="returnRouteName">
    <div slot="header">{{ $t('layout.edit.machine-edit') }}: <span v-if="mainInfos.loaded">{{ this.originalMachine.name }} {{ $t('layout.edit.configuration')}}</span></div>

    <template slot="body">
      <form class="needs-validation"
            ref="editform"
            v-on:submit.prevent="checkValidityOnSubmit"
            novalidate>

        <ul class="nav nav-tabs" role="tablist">
          <li :class="['nav-item', isDetailsEditorValid ? '': 'has-invalid-input']">
            <a :class="['nav-link', !isStatusStarted ? 'active' : '']" data-toggle="tab" href="#details" role="tab" aria-controls="details">
              {{ $t('layout.edit.machine-edit-details') }}
              <inline-help :content="helpTextContent('machines.edit.details')" v-show="true"></inline-help>
            </a>

          </li>
          <li :class="['nav-item', isMnmEditorValid ? '': 'has-invalid-input']">
            <a class="nav-link" data-toggle="tab" href="#mnm" role="tab" aria-controls="mnm">
              {{ $t('layout.edit.machine-edit-mnm') }}
              <inline-help :content="helpTextContent('machines.edit.mnm.title')" v-show="true"></inline-help>
            </a>
          </li>
          <li :class="['nav-item', isDriverEditorValid ? '': 'has-invalid-input']">
            <a class="nav-link" data-toggle="tab" href="#drvr" role="tab" aria-controls="drvr">
              {{ $t('layout.edit.machine-edit-driver') }}
              <inline-help :content="helpTextContent('machines.edit.driver.title')" v-show="true"></inline-help>
            </a>
          </li>
          <li class="nav-item" v-if="!isNew"> <!-- TAB DRIVER STATUS -->
            <a class="nav-link" data-toggle="tab" href="#status" role="tab" aria-controls="status">
              {{ $t('layout.edit.machine-edit-status') }}
              <inline-help :content="helpTextContent('machines.edit.status.title')" v-show="true"></inline-help>
            </a>
          </li>
          <li class="nav-item" v-if="!isNew && isStatusStarted">
            <a class="nav-link" data-toggle="tab" href="#operations" role="tab" aria-controls="operations">
              {{ $t('layout.edit.machine-edit-operations') }}
              <inline-help :content="helpTextContent('machines.edit.operations')" v-show="true"></inline-help>
            </a>
          </li>
          <li class="nav-item" v-if="!isNew && isStatusStarted">
            <a class="nav-link" data-toggle="tab" href="#logs" role="tab" aria-controls="logs">
              {{ $t('layout.edit.machine-edit-logs') }}
              <inline-help :content="helpTextContent('machines.edit.logs')" v-show="true"></inline-help>
            </a>
          </li>
          <li class="nav-item" v-if="!isNew && isStatusStarted">
            <a :class="['nav-link', isStatusStarted ? 'active' : '']" data-toggle="tab" href="#running-status" role="tab" aria-controls="running-status">
              {{ $t('layout.edit.machine-edit-runtime-status') }}
              <inline-help :content="helpTextContent('machines.edit.runtimestatus')" v-show="true"></inline-help>
            </a>
          </li>
          <li class="nav-item" v-if="!isNew && isStatusStarted">
            <a class="nav-link" data-toggle="tab" href="#tools" role="tab" aria-controls="tools">
              {{ $t('layout.edit.machine-edit-tools') }}
              <inline-help :content="helpTextContent('machines.edit.tools')" v-show="true"></inline-help>
            </a>
          </li>
        </ul>

        <div class="tab-content">
          <!-- PANNELLO DETAILS ************************ -->
          <div :class="['tab-pane', !isStatusStarted ? 'active' : '']" id="details" role="tabpanel">
            <div v-if="mainInfos.loaded && !mainInfos.error">
              <input-form-text :class="'form-row'"
                          v-model="mainInfos.data.name"
                          :label="$t('layout.edit.name')"
                          :name="'name'"
                          :read_only="isStatusStarted"
                          :required="true"
                          @changeValidity="checkDetailsEditorValidity($event)"></input-form-text>
              <input-form-textarea v-model="mainInfos.data.description"
                              :label="$t('layout.edit.description')"
                              :name="'description'"
                              :read_only="isStatusStarted"
                              :required="true"
                              @changeValidity="checkDetailsEditorValidity($event)"></input-form-textarea>
              <input-form-select v-if="!mnmDriverConfig.mainError"
                                 :label="$t('layout.edit.driver')"
                                 :value="mnmDriverConfig.loaded && !mnmDriverConfig.mainError ? mnmDriverConfig.data.machineDriverId : null"
                                 :name="'driver'"
                                 :read_only="isStatusStarted"
                                 :loaded="driversListLoaded"
                                 :options="driversAsOptionsforSelect"
                                 :required="true"
                                 @changeValidity="checkDetailsEditorValidity($event)"
                                 @selected="(value) => { if (mnmDriverConfig.loaded && !mnmDriverConfig.mainError) { mnmDriverConfig.data.machineDriverId = value.value }}"></input-form-select>
              <!-- errore per la select nel caso non possa essere reperito l'id driver dai dati -->
              <error-form-element v-else
                                  :label="$t('layout.edit.driver')"></error-form-element>
              <input-form-text :class="'form-row'"
                               v-model="mainInfos.data.position"
                               :label="$t('layout.edit.position')"
                               :name="'name'"
                               :read_only="isStatusStarted"
                               :required="false"
                               :type="'Numeric'"
                               @changeValidity="checkDetailsEditorValidity($event)"></input-form-text>
            </div>
            <form-row-message v-else
                              :text="!mainInfos.loaded ? $t('message.loading') : $t('message.error')"
                              :isError="mainInfos.error"></form-row-message>
          </div>
          <!-- PANNELLO MNM CONFIG ************************ -->
          <div class="tab-pane" id="mnm" role="tabpanel">
            <div class="tab-pane-content-if h-auto-less-button" v-if="mnmDriverConfig.loaded && !mnmDriverConfig.mnmError">
              <div class="row">
                <div class="col-12">
                  <code-editor class="form-control"
                               @changeValidity="(isValid) => { isMnmEditorValid = isValid }"
                               @updateCode="(code) => { if (isMnmEditorValid) { mnmDriverConfig.data.mnmConfiguration = code }}"
                               :code="mnmCode"
                               :read_only="isStatusStarted"></code-editor>
                </div>
              </div>
            </div>
            <form-row-message v-else
                              class="h-auto-less-button"
                              :text="!mnmDriverConfig.loaded ? $t('message.loading') : $t('message.error')"
                              :isError="mnmDriverConfig.mnmError"></form-row-message>
            <div class="row h-button">
              <div class="col-md-10"></div>
              <div class="col-md-2">
                <button type="button"
                        v-if="!isNew"
                        v-tooltip="helpTextContent('machines.edit.mnm.normalize')"
                        @click.prevent="callNormalizeMnmCfg"
                        class="btn btn-outline-info btn-block text-capitalize active">
                  {{ $t('layout.button.normalize') }}
                </button>
              </div>
            </div>
          </div>
          <!-- PANNELLO DRIVER CONFIG ************************ -->
          <div class="tab-pane" id="drvr" role="tabpanel">
            <div class="tab-pane-content-if h-auto-less-button"
                 v-if="mnmDriverConfig.loaded && !mnmDriverConfig.driverError">
              <div class="row">
                <div class="col-12">
                  <code-editor class="form-control"
                               @changeValidity="(isValid) => { isDriverEditorValid = isValid }"
                               @updateCode="(code) => { if (isDriverEditorValid) { mnmDriverConfig.data.driverConfiguration = code }}"
                               :code="driverCode"
                               :read_only="isStatusStarted"></code-editor>
                </div>
              </div>
              <div class="row h-button">
                <div class="col-md-10"></div>
                <div class="col-md-2">
                  <button type="button"
                          v-if="!isNew"
                          v-tooltip="helpTextContent('machines.edit.driver.normalize')"
                          @click.prevent="callNormalizeDriverCfg"
                          class="btn btn-outline-info btn-block text-capitalize active">
                    {{ $t('layout.button.normalize') }}
                  </button>
                </div>
              </div>
            </div>
            <form-row-message v-else
                              :text="!mnmDriverConfig.loaded ? $t('message.loading') : $t('message.error')"
                              :isError="mnmDriverConfig.driverError"></form-row-message>
          </div>
          <!-- PANNELLO DRIVER STATUS ************************ -->
          <div class="tab-pane" id="status" role="tabpanel" v-if="!isNew">
            <div class="tab-pane-content-if" v-if="mnmDriverConfig.loaded && !mnmDriverConfig.statusError">
              <code-editor class="form-control"
                           :code="helpers.formatJsonString(originalMachine.driverStatus)"
                           :read_only="true"></code-editor>
            </div>
            <form-row-message v-else
                              :text="!mnmDriverConfig.loaded ? $t('message.loading') : $t('message.error')"
                              :isError="mnmDriverConfig.statusError"></form-row-message>
          </div>
          <!-- PANNELLO OPERATIONS ************************ -->
          <div class="tab-pane" id="operations" role="tabpanel" v-if="!isNew && isStatusStarted">
            <div class="tab-pane-content-if" v-if="operationsLogs.loaded && !operationsLogs.error">

              <card>
                <div slot="header">
                  <refresher class="float-right"
                             :seconds="autoRefresh('MachineOperations')"
                             :enabled="enableOperationsLogsTimer"
                             @countdown="(value) => { operationsLogCountDown = value }"
                             @tick="loadOperationsLogs"></refresher>
                </div>
                <div slot="body">
                  <p class="log-entry" v-for="(log, key) in operationsLogs.data.operations" v-bind:key="key">{{ log }}</p>
                  <p class="log-entry" v-if="operationsLogs.data.operations.length === 0">{{ $t('layout.edit.no-data-to-display') }}</p>
                </div>
              </card>
            </div>
            <form-row-message v-else
                              :text="!operationsLogs.loaded ? $t('message.loading') : $t('message.error')"
                              :isError="operationsLogs.error"></form-row-message>
          </div>
          <!-- PANNELLO LOGS ************************ -->
          <div class="tab-pane" id="logs" role="tabpanel" v-if="!isNew && isStatusStarted">
            <div class="tab-pane-content-if" v-if="operationsLogs.loaded && !operationsLogs.error">
              <card>
                <div slot="header">
                  <!-- fake countdown counter -->
                  <refresher class="float-right"
                             :seconds="operationsLogCountDown"
                             :enabled="false"
                             @tick="() => { loadOperationsLogs(); }"></refresher>
                </div>
                <div slot="body">
                  <p class="log-entry" v-for="(log, key) in operationsLogs.data.logs" v-bind:key="key">{{ log }}</p>
                  <p class="log-entry" v-if="operationsLogs.data.logs.length === 0">{{ $t('layout.edit.no-data-to-display') }}</p>
                </div>
              </card>
            </div>
            <form-row-message v-else
                              :text="!operationsLogs.loaded ? $t('message.loading') : $t('message.error')"
                              :isError="operationsLogs.error"></form-row-message>
          </div>
          <!-- PANNELLO RUNTIME-STATUS ************************ -->
          <div :class="['tab-pane', isStatusStarted ? 'active' : '']" id="running-status" role="tabpanel" v-if="!isNew && isStatusStarted">
            <div class="tab-pane-content-if" v-if="runtimeStatus.loaded && !runtimeStatus.error">
              <card>
                <div slot="header">
                  <refresher class="float-right"
                             :seconds="autoRefresh('MachineRuntimeStatus')"
                             :enabled="enableRuntimeStatusTimer"
                             @tick="loadRuntimeStatus"></refresher>
                </div>
                <div slot="body">
                  <runtime-status :is-loaded="runtimeStatus.loaded"
                                  :parameters="runtimeStatus.data"></runtime-status>
                </div>
              </card>
            </div>
            <form-row-message v-else
                              :text="!operationsLogs.loaded ? $t('message.loading') : $t('message.error')"
                              :isError="operationsLogs.error"></form-row-message>
          </div>
          <!-- PANNELLO TOOLS ************************ -->
          <div class="tab-pane" id="tools" role="tabpanel" v-if="!isNew && isStatusStarted">
            <div class="tab-pane-content-if" >
              <card>
                <div slot="body">
                  <tools-list :id="parseInt(originalMachine.id)"></tools-list>
                </div>
              </card>
            </div>
          </div>
        </div>
      </form>
    </template>

    <template slot="footer">
      <div class="col-md-2">
        <button type="button"
                @click.prevent="() => { if (isNew) { callNew(); } else { callSave() }}"
                :class="[
                'btn btn-outline-success btn-block text-capitalize',
                $hasPerms('dcs.machines.actions.save') && !isStatusStarted ? 'active' : 'disabled'
              ]">
          {{ $t('layout.button.save') }}
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Modal'
import CodeEditor from '@/components/forms/CodeEditor'
import PanelGrading from '@/components/forms/PanelGrading'
import { events } from '@/lib/messages'
import InputFormText from '@/components/forms/InputFormText'
import InputFormTextarea from '@/components/forms/InputFormTextarea'
import InputFormSelect from '../../components/forms/InputFormSelect'
import Card from '../../components/forms/Card'
import Refresher from '../../components/Refresher'
import { apiConfig } from '@/config/api.config.js'
import helpers from '@/lib/helpers'
import ErrorFormElement from '../../components/forms/ErrorFormElement'
import FormRowMessage from '../../components/modal_edit/FormRowMessage'
import RuntimeStatus from '../../components/forms/RuntimeStatus'
import ToolsList from '../../components/DetailRow/toolsList'
import InlineHelp from '../../components/InlineHelp'
import HelpTextsMixin from '../../mixins/HelpTextsMixin'

export default {
  name: 'MachineEditModal',
  mixins: [ HelpTextsMixin ],
  components: { InlineHelp, ToolsList, RuntimeStatus, FormRowMessage, ErrorFormElement, Refresher, Card, InputFormSelect, InputFormTextarea, InputFormText, PanelGrading, CodeEditor, Modal },
  data () {
    return {
      showMe: true,
      helpers: helpers,
      mainInfos: {
        loaded: false,
        error: false,
        data: null
      },
      driverCode: '',
      mnmCode: '',
      mnmDriverConfig: {
        loaded: false,
        data: null,
        mainError: false,
        mnmError: false,
        driverError: false,
        statusError: false
      },
      operationsLogs: {
        loaded: false,
        data: null,
        error: false
      },
      runtimeStatus: {
        loaded: false,
        data: null,
        error: false
      },
      originalMachine: {
        id: null,
        name: '',
        description: null,
        mnmConfiguration: null,
        machineDriverId: null,
        driverConfiguration: null
      },
      driversListLoaded: false,
      id: null,
      serviceForm: null,
      isDetailsEditorValid: true,
      isMnmEditorValid: true,
      isDriverEditorValid: true,
      eventSystem: events,
      apiConfig: apiConfig,
      operationsLogCountDown: 0,
      enableOperationsLogsTimer: false,
      enableRuntimeStatusTimer: false
    }
  },
  props: {
    returnRouteName: {
      type: String,
      default: 'dcs.machines.list'
    }
  },
  computed: {
    ...mapGetters({
      driversList: 'drivers/all',
      autoRefresh: 'settings/tableAutoRefresh'
    }),
    currentMachine () {
      return {
        id: this.originalMachine.id,
        name: this.mainInfos.data ? this.mainInfos.data.name : '',
        description: this.mainInfos.data ? this.mainInfos.data.description : null,
        position: this.mainInfos.data ? this.mainInfos.data.position : null,
        mnmConfiguration: this.mnmDriverConfig.data ? helpers.stripSpacesNewlines(this.mnmDriverConfig.data.mnmConfiguration) : null,
        machineDriverId: this.mnmDriverConfig.data ? this.mnmDriverConfig.data.machineDriverId : null,
        driverConfiguration: this.mnmDriverConfig.data ? helpers.stripSpacesNewlines(this.mnmDriverConfig.data.driverConfiguration) : null
      }
    },
    hasModification () {
      return !(this.currentMachine.name === this.originalMachine.name &&
        this.currentMachine.description === this.originalMachine.description &&
        helpers.stripSpacesNewlines(this.currentMachine.mnmConfiguration) === helpers.stripSpacesNewlines(this.originalMachine.mnmConfiguration) &&
        helpers.stripSpacesNewlines(this.currentMachine.driverConfiguration) === helpers.stripSpacesNewlines(this.originalMachine.driverConfiguration))
    },
    isStatusStarted () {
      return (this.mainInfos.loaded && !this.mainInfos.error && this.mainInfos.data.status.bootStatus.toLowerCase() === 'started')
    },
    isNew () {
      return this.originalMachine.id === 'new'
    },
    hasSomethingInError () {
      return this.mainInfos.error ||
             this.mnmDriverConfig.mainError ||
             this.mnmDriverConfig.statusError ||
             this.mnmDriverConfig.driverError ||
             this.mnmDriverConfig.mnmError ||
             this.operationsLogs.error
    },
    hasEditorsInError () {
      return !this.isMnmEditorValid ||
        !this.isDetailsEditorValid ||
        !this.isDriverEditorValid
    },
    driversAsOptionsforSelect () {
      if (this.driversListLoaded) {
        return this.driversList.map((item) => {
          return {
            label: item.model,
            value: item.id,
            search: item.id + ' ' + item.model
          }
        })
      }
    }
  },
  methods: {
    ...mapActions({
      action: 'machines/action',
      getMainInfos: 'machines/get',
      getMnmDriverConfig: 'machines/details',
      getOperationsLogs: 'machines/info',
      getRuntimeStatus: 'machines/runtimeStatus',
      getDrivers: 'drivers/load',
      getNormalizedDriverCfg: 'machines/normalizedDriverConfig',
      getNormalizedMnmCfg: 'machines/normalizedMnmConfig',
      save: 'machines/save',
      new: 'machines/new'
    }),
    checkDetailsEditorValidity (e) {
      e.target.closest('form').classList.add('was-validated')
      this.isDetailsEditorValid = this.$el.querySelectorAll('#details :invalid').length === 0
    },
    saveCodeEditor (code) {
      this.mainInfos.data.parameters = code
    },
    callNormalizeDriverCfg () {
      this.getNormalizedDriverCfg(this.originalMachine.id).then((data) => {
        this.mnmDriverConfig.data.driverConfiguration = null
        this.mnmDriverConfig.data.driverConfiguration = helpers.smartJsonCheckParse(data)
        this.mnmDriverConfig.driverError = this.mnmDriverConfig.data.driverConfiguration === undefined
        this.driverCode = helpers.formatJsonString(this.mnmDriverConfig.data.driverConfiguration)
      })
    },
    callNormalizeMnmCfg () {
      this.getNormalizedMnmCfg(this.originalMachine.id).then((data) => {
        this.mnmDriverConfig.data.mnmConfiguration = null
        requestAnimationFrame(() => {
          this.mnmDriverConfig.data.mnmConfiguration = helpers.smartJsonCheckParse(data)
          this.mnmDriverConfig.mnmError = this.mnmDriverConfig.data.mnmConfiguration === undefined
          this.mnmCode = helpers.formatJsonString(this.mnmDriverConfig.data.mnmConfiguration)
          this.isMnmEditorValid = true
        })
      })
    },
    callNew () {
      if (this.hasSomethingInError) {
        this.$messages.error({ message: this.$t('message.cannot-save-data-error') })
        return false
      }
      if (this.hasEditorsInError) {
        this.$messages.error({ message: this.$t('message.please-check-your-data') })
      } else {
        let machine = {
          name: this.mainInfos.data.name,
          description: this.mainInfos.data.description,
          mnmConfiguration: this.mnmDriverConfig.data.mnmConfiguration,
          machineDriverId: this.mnmDriverConfig.data.machineDriverId,
          driverConfiguration: this.mnmDriverConfig.data.driverConfiguration
        }
        if (this.$canLog(4)) console.log('[Machine] new', machine)
        this.new(machine).then((data) => {
          this.$messages.success({ message: this.$t('message.success') })
        }).then(() => {
          this.eventSystem.$emit('refresh-page')
          this.$router.push({ name: this.returnRouteName })
        }).catch((e) => {
          // console.log(parameters)
          // this.$messages.error({
          //   title: this.$t('message.error'),
          //   message: (e.response && e.response.body) ? e.response.body : e.message
          // })
        })
      }
    },
    callSave () {
      if (this.isStatusStarted) { return false }
      if (this.hasSomethingInError) {
        this.$messages.error({ message: this.$t('message.cannot-save-data-error') })
        return false
      }
      if (this.hasEditorsInError) {
        this.$messages.error({ message: this.$t('message.please-check-your-data') })
      } else {
        let machine = this.currentMachine
        if (this.$canLog(4)) console.log('[Machine] save', machine)
        this.save(machine).then((data) => {
          this.$messages.success({ message: this.$t('message.success') })
        }).then(() => {
          this.eventSystem.$emit('refresh-page')
          try {
            this.$broadcast().postMessage('MachinesList')
          } catch (e) {
            if (this.$canLog(0)) console.error(e.message, this.$broadcast)
          }

          this.$router.push({ name: this.returnRouteName })
        }).catch((e) => {
          // console.log(parameters)
          // this.$messages.error({ title: this.$t('message.error'), message: (e.response && e.response.body) ? e.response.body : e.message })
        })
      }
    },
    loadRuntimeStatus () {
      this.enableRuntimeStatusTimer = false
      this.getRuntimeStatus(this.$route.params.id).then((data) => {
        if (this.$canLog(4)) console.log('[Machine Edit] runningStatus ', data)
        this.runtimeStatus.data = helpers.smartJsonCheckParse(data)
        if (this.runtimeStatus.data !== undefined) {
          this.runtimeStatus.error = false
          if (this.$canLog(3)) console.log('[Machine Edit] runningStatus ok')
        } else {
          this.runtimeStatus.error = true
          if (this.$canLog(2)) console.warn('[Machine Edit] cannot parse runningStatus')
        }
      }).catch((e) => {
        if (this.$canLog(1)) console.log(e)
        this.runtimeStatus.error = true
      }).finally(() => {
        this.runtimeStatus.loaded = true
        this.enableRuntimeStatusTimer = true
      })
    },
    loadOperationsLogs () {
      this.enableOperationsLogsTimer = false
      this.getOperationsLogs(this.$route.params.id).then((data) => {
        if (this.$canLog(4)) console.log('[Machine Edit] ops logs ', data)
        this.operationsLogs.data = helpers.smartJsonCheckParse(data)
        if (this.operationsLogs.data !== undefined) {
          this.operationsLogs.error = false
          if (this.$canLog(3)) console.log('[Machine Edit] ops logs ok')
        } else {
          this.operationsLogs.error = true
        }
      }).catch((e) => {
        this.operationsLogs.error = true
      }).finally(() => {
        this.operationsLogs.loaded = true
        this.enableOperationsLogsTimer = true
      })
    },
    loadDrivers () {
      this.driversListLoaded = false
      this.getDrivers().finally(() => {
        this.driversListLoaded = true
      })
    },
    loadMachine () {
      this.getMainInfos(this.$route.params.id).then((data) => {
        if (this.$canLog(4)) console.log('[Machine Edit] main infos', data)
        this.mainInfos.data = helpers.smartJsonCheckParse(data)
        if (this.mainInfos.data !== undefined) {
          this.originalMachine.name = this.originalMachine.name.toString()
          this.originalMachine.name = this.mainInfos.data.name
          this.originalMachine.description = this.mainInfos.data.description
          if (this.$canLog(3)) console.log('[Machine Edit] main infos ok')
          this.mainInfos.error = false
          this.mainInfos.loaded = true

          if (this.$canLog(3)) console.log('[Machine Edit] loadMachine', this.isStatusStarted)
          if (this.isStatusStarted) {
            this.loadRuntimeStatus()
            // Operations e Logs
            this.loadOperationsLogs()
          }
        } else {
          this.mainInfos.error = true
        }
      }).catch((e) => {
        this.mainInfos.error = true
      }).finally(() => {
        this.mainInfos.loaded = true
      })

      // MNM e Driver
      this.getMnmDriverConfig(this.$route.params.id).then((data) => {
        if (this.$canLog(4)) console.log('[Machine Edit] mnm driver cfg', data)
        this.mnmDriverConfig.data = helpers.smartJsonCheckParse(data)
        if (this.mnmDriverConfig.data !== undefined) {
          this.mnmDriverConfig.mainError = false
          this.originalMachine.mnmConfiguration = this.mnmDriverConfig.data.mnmConfiguration
          this.originalMachine.machineDriverId = this.mnmDriverConfig.data.machineDriverId
          this.originalMachine.driverConfiguration = this.mnmDriverConfig.data.driverConfiguration
          this.originalMachine.driverStatus = this.mnmDriverConfig.data.driverStatus
          this.driverCode = helpers.formatJsonString(this.originalMachine.driverConfiguration)
          // anche le due proprietà dell'oggetto devono contenere stringhe JSON valide
          this.mnmDriverConfig.driverError = helpers.smartJsonCheckParse(this.mnmDriverConfig.data.driverConfiguration) === undefined
          this.mnmDriverConfig.mnmError = helpers.smartJsonCheckParse(this.mnmDriverConfig.data.mnmConfiguration) === undefined
          this.mnmDriverConfig.statusError = helpers.smartJsonCheckParse(this.mnmDriverConfig.data.driverStatus) === undefined
          this.mnmCode = helpers.formatJsonString(this.originalMachine.mnmConfiguration)
          if (this.$canLog(3)) console.log('[Machine Edit] mnm driver cfg ok')
        } else {
          this.mnmDriverConfig.mainError = true
        }
      }).catch((e) => {
        if (this.$canLog(0)) console.error('[Machine]', e)
        this.mnmDriverConfig.mainError = true
      }).finally(() => {
        this.mnmDriverConfig.loaded = true
      })
    },
    createMachine () {
      this.mainInfos = {
        loaded: true,
        error: false,
        data: {
          name: '',
          description: '',
          status: {
            bootStatus: ''
          }
        }
      }
      this.mnmDriverConfig = {
        loaded: true,
        data: {
          machineDriverId: null,
          mnmConfiguration: null,
          driverConfiguration: null,
          driverStatus: null
        },
        mainError: false,
        mnmError: false,
        driverError: false,
        statusError: false
      }
      this.operationsLogs = {
        loaded: true,
        data: {
          operations: [],
          logs: []
        },
        error: false
      }
    }
  },
  created () {
    this.originalMachine.id = this.$route.params.id
  },
  mounted () {
    this.mainInfos.data = null
    this.mnmDriverConfig.data = null
    this.operationsLogs.data = null

    if (this.originalMachine.id !== null) {
      if (this.originalMachine.id !== 'new') {
        this.loadMachine()
      } else {
        this.createMachine()
      }
      this.loadDrivers()
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-pane-content-if {
  .row {
    height: 100%;
  }
}

.log-entry {
  font-size: .9em;
  line-height: normal;
  margin-bottom: 0;
}

.h-button {
  height: calc(1.5em + .375rem * 2);
  margin-top: 1em;
}
</style>