<template>
  <div class="form-row">
    <label :for="name" class="col-2 col-form-label">{{ label }}:</label>
    <div class="col-md-10 mb-4">
      <textarea name="description" :id="name" class="form-control"
                ref="input"
                :readonly="read_only"
                :value="value"
                @input="checkValidity" :required="required"></textarea>
      <div class="invalid-feedback position-absolute">
        {{ $t('message.please-provide-valid-value') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputFormTextarea',
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: Math.random().toString()
    },
    read_only: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isValid: true
    }
  },
  methods: {
    checkValidity () {
      this.isValid = this.$refs.input.checkValidity()
      this.$emit('changeValidity', { target: this.$refs.input, value: this.isValid })
      this.$emit('input', this.$refs.input.value)
    }
  },
  mounted () {
    this.checkValidity()
  }
}
</script>

<style scoped>

</style>