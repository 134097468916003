import { render, staticRenderFns } from "./ErrorFormElement.vue?vue&type=template&id=7fa8cbec&scoped=true&"
import script from "./ErrorFormElement.vue?vue&type=script&lang=js&"
export * from "./ErrorFormElement.vue?vue&type=script&lang=js&"
import style0 from "./ErrorFormElement.vue?vue&type=style&index=0&id=7fa8cbec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fa8cbec",
  null
  
)

component.options.__file = "ErrorFormElement.vue"
export default component.exports