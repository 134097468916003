import { render, staticRenderFns } from "./DCSFooter.vue?vue&type=template&id=5e6c3926&scoped=true&"
import script from "./DCSFooter.vue?vue&type=script&lang=js&"
export * from "./DCSFooter.vue?vue&type=script&lang=js&"
import style0 from "./DCSFooter.vue?vue&type=style&index=0&id=5e6c3926&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e6c3926",
  null
  
)

component.options.__file = "DCSFooter.vue"
export default component.exports