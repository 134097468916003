<template>
  <div class="sidebar">
    <nav class="sidebar-nav" v-autohidesidebar>
      <ul class="nav" v-if="isAuthenticated">
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'dashboard'}">
            <speedometer-icon :title="$t('menu.dashboard')"></speedometer-icon>
            {{ $t('menu.dashboard') }}
          </router-link>
        </li>
        <sidebar-group-language class="d-block d-md-none"></sidebar-group-language>
        <sidebar-group-status></sidebar-group-status>
        <sidebar-group-system-configuration></sidebar-group-system-configuration>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'dcs.anomalies.list'}">
            <alert-decagram-icon :title="$t('menu.anomalies')"></alert-decagram-icon>
            {{ $t('menu.anomalies') }}
          </router-link>
        </li>
        <sidebar-group-services></sidebar-group-services>
        <li class="nav-item">
          <router-link class="nav-link" :to="{ name: 'dcs.data-checks.list'}">
            <format-list-checks-icon :title="$t('menu.data-checks')"></format-list-checks-icon>
            {{ $t('menu.data-checks') }}
          </router-link>
        </li>
        <sidebar-group-queries></sidebar-group-queries>
      </ul>
    </nav>
  </div>
</template>

<script>
import SpeedometerIcon from 'vue-material-design-icons/Speedometer'
import SidebarGroupLanguage from './SideBarGroups/SidebarGroupLanguage'
import SidebarGroupStatus from './SideBarGroups/SidebarGroupStatus'
import SidebarGroupSystemConfiguration from './SideBarGroups/SidebarGroupSystemConfiguration'
import AlertDecagramIcon from 'vue-material-design-icons/AlertDecagram'
import SidebarGroupServices from './SideBarGroups/SidebarGroupServices'
import FormatListChecksIcon from 'vue-material-design-icons/FormatListChecks'
import { Autohidesidebar } from '@/directives/autohidesidebar'
import SidebarGroupQueries from './SideBarGroups/SidebarGroupQueries'

export default {
  name: 'SideBar',
  components: { SidebarGroupQueries, FormatListChecksIcon, SidebarGroupServices, AlertDecagramIcon, SidebarGroupLanguage, SidebarGroupSystemConfiguration, SidebarGroupStatus, SpeedometerIcon },
  directives: { Autohidesidebar },
  methods: {
  }
}
</script>

<style lang="scss">
  .sidebar {
    // min-height: calc(100vh - 55px);
    background-color: $slate-grey;

    margin-left: -100%;

    .sidebar-lg-show &, .sidebar-show &, .sidebar-sm-show & {
      margin-left: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-left: 0 !important;
    }

    .nav {
      .nav-item {
        .nav-dropdown-items {
          .nav-link {
            padding-left: 2em;
          }
        }

        .nav-link {
          text-transform: capitalize;

          &:hover {
            background-color: $navy;
          }

          &.nav-dropdown-toggle {
            /*text-transform: uppercase;*/
            /*font-weight: bold;*/
          }

          &.router-link-active {
            color: $white;
            background-color: $cerulean;
          }
        }
      }
    }
  }
</style>