<template>
  <footer class="dsc-footer">
    <div class="container-fluid">
      <div class="row align-items-center no-gutters">
        <div class="col">
          Ecoprogetti DCS Client v. {{ appVersion }} | © Ecoprogetti srl 2018 - {{ thisYear }}
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import { i18nConfig } from '@/config/i18n.config.js'

export default {
  name: 'DCSFooter',
  components: {},
  data () {
    return {
      locales: i18nConfig.locales
    }
  },
  computed: {
    appVersion () {
      return this.$store.getters.appVersion
    },
    thisYear () {
      return new Date().getFullYear()
    }
  },
  methods: {
  },
  mounted () {
    // this.getUser().catch((error) => { console.warn('Requested User Infos but seems you\'re not logged-in', error.message) })
  }
}
</script>

<style lang="scss" scoped>
  .dsc-footer {
    border-top: 2px solid $orange;
    display: flex;
    justify-content: center;
    background-color: $navy;
    color: $white;
    height: 48px;
    box-sizing: border-box;

    .container-fluid {
      margin: 0;
    }

    .row {
      height: 100%;
    }
  }
</style>