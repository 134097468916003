<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.8 21.67">
    <g id="ada3ace7-9428-49f9-8db6-215b024420b8" data-name="Layer 2"><g id="b0373be9-414c-4a72-9339-d99df5c0de96" data-name="Livello 1"><path class="a3d5ead8-ca44-41b1-b18f-5e0e016e655d" d="M13.75,0a.53.53,0,0,0-.32.48V6.18H.52A.51.51,0,0,0,0,6.69V15a.52.52,0,0,0,.52.52H13.43v5.68a.53.53,0,0,0,.32.48.51.51,0,0,0,.56-.12l10.34-10.3a.51.51,0,0,0,.15-.37.49.49,0,0,0-.15-.36L14.31.15A.51.51,0,0,0,13.75,0Z"/></g></g></svg>
</template>
<script>
export default {
  name: 'RightArrowIcon',
  props: {
    title: {
      type: String,
      default: 'Basket icon'
    },
    decorative: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.a3d5ead8-ca44-41b1-b18f-5e0e016e655d{fill: $danger;}
</style>