import { render, staticRenderFns } from "./BasketLeftIcon.vue?vue&type=template&id=23a0fd62&scoped=true&"
import script from "./BasketLeftIcon.vue?vue&type=script&lang=js&"
export * from "./BasketLeftIcon.vue?vue&type=script&lang=js&"
import style0 from "./BasketLeftIcon.vue?vue&type=style&index=0&id=23a0fd62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23a0fd62",
  null
  
)

component.options.__file = "BasketLeftIcon.vue"
export default component.exports