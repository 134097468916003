<script>
import { codemirror } from 'vue-codemirror/src'

export default {
  extends: codemirror,
  methods: {
    handerCodeChange (newVal) {
      // eslint-disable-next-line
      const cm_value = this.cminstance.getValue()
      newVal = newVal === null ? '' : newVal
      // eslint-disable-next-line
      if (newVal !== cm_value) {
        const scrollInfo = this.cminstance.getScrollInfo()
        this.cminstance.setValue(newVal)
        this.content = newVal
        this.cminstance.scrollTo(scrollInfo.left, scrollInfo.top)
      }
      this.unseenLineMarkers()
    }
  }
}
</script>