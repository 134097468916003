<template>
  <div class="record-counter" v-if="canShow">
    {{ $t('layout.header.showing') }} {{ showing }} {{ $t('layout.header.showing-of') }} {{ numResults }}
  </div>
</template>
<script>
export default {
  name: 'RecordCounter',
  props: {
    canShow: {
      type: Boolean,
      default: true
    },
    rowsPerPage: {
      type: Number,
      required: true
    },
    numResults: {
      type: Number,
      required: true
    }
  },
  computed: {
    showing () {
      return this.numResults < this.rowsPerPage ? this.numResults : this.rowsPerPage
    }
  }
}
</script>

<style scoped>

</style>