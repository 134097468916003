import ApiReusableVuexModule from '@/lib/api/ApiReusableVuexModule'

export default {
  namespaced: true,
  state: () => {
    return {
      versionInfos: null,
      processInfos: null,
      monitoredMachinesList: null,
      last_update: null
    }
  },
  getters: {
    allMachines: (state) => {
      return state.monitoredMachinesList
    },
    processInfos: (state) => {
      return state.processInfos
    },
    versionInfos: (state) => {
      return state.versionInfos
    },
    lastUpdate: (state) => {
      return ApiReusableVuexModule.getters.lastUpdate(state)
    }
  },
  mutations: {
    setMachines (state, payload) {
      state.monitoredMachinesList = payload
    },
    setProcessInfos (state, payload) {
      state.processInfos = payload
    },
    setVersionInfos (state, payload) {
      state.versionInfos = payload
    },
    last_update (state, payload) {
      state.last_update = payload
    }
  },
  actions: {
    loadProcessInfos: (context, force = false) => {
      return ApiReusableVuexModule.actions.load(context, { path: 'Status.Status_SystemInfo', method: 'setProcessInfos', force: force })
    },
    loadMonitoredMachines: (context, force = false) => {
      return ApiReusableVuexModule.actions.load(context, { path: 'Status.Status_MonitoredMachines', method: 'setMachines', force: force })
    },
    loadVersionInfos: (context, force = false) => {
      return ApiReusableVuexModule.actions.load(context, { path: 'Status.Status_VersionInfo', method: 'setVersionInfos', force: force })
    }
  }
}