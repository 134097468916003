const helpers = {
  install: (Vue) => {
    Vue.prototype.$canLog = helpers.canLog
  },
  canLog: (messageLevel) => {
    return /* process.env.NODE_ENV === 'development' && */ window.dcsClientCfg.logLevel > messageLevel
  },
  createTitle: (dataset, field, baseText) => {
    let title = baseText
    if (dataset.filter((itm) => itm[field] === title).length > 0) {
      let i = 1
      while (dataset.filter((itm) => itm[field] === title + ' - ' + i).length > 0) {
        i++
      }
      title += ' - ' + i
    }
    return title
  },
  smartJsonCheckParse: (str) => {
    try {
      return JSON.parse(str)
    } catch (e) {
      return undefined
    }
  },
  stripSpacesNewlines: (text) => {
    return text
    // if (typeof text !== 'string') return text
    // if (!text) return text
    // return text.replace(/(\r\n|\n|\r|\s+)/gm, '')
  },
  cleanJsonString: (text) => {
    // eslint-disable-next-line
    let s = text.replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, '\\&')
      .replace(/\\r/g, '\\r')
      .replace(/\\t/g, '\\t')
      .replace(/\\b/g, '\\b')
      .replace(/\\f/g, '\\f')
    // remove non-printable and other non-valid JSON chars
    // eslint-disable-next-line
    s = s.replace(/[\u0000-\u0019]+/g, '')
    return s
  },
  formatJsonString: (text) => {
    if (text === null) {
      return null
    }
    let parsed = helpers.smartJsonCheckParse(text)
    // if (helpers.canLog(2)) console.log('[Helpers] formatting JSON', parsed)
    // console.trace()
    if (parsed) {
      return JSON.stringify(parsed
        , null, 2)
    }
    return ''
  }
}

export default helpers