<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.69 34.48">
    <title></title>
    <g id="be5a0ff5-55b1-4798-903c-18d2885b5a2a" data-name="Layer 2"><g id="bce88038-dc50-45e0-b5d9-6c9960dcf41b" data-name="Livello 1"><path d="M64.12,34.46a1.59,1.59,0,0,0,1.45-1,1.56,1.56,0,0,0-.34-1.7L34,.46a1.57,1.57,0,0,0-2.22,0L.46,31.8a1.57,1.57,0,0,0,1.11,2.68Z"/></g></g></svg>
</template>

<script>
export default {
  name: 'Pyramid'
}
</script>

<style lang="scss" scoped>
</style>