import { controlApi, dataApi } from '@/lib/api'
import { messages } from '@/lib/messages'
import { get } from 'lodash'
import { isAuthenticated } from '../oauth2'
import { accessToken } from '../oauth2/helpers'
import helpers from '@/lib/helpers'

const dcsError = {
  extractError: (e) => {
    if (e.response && e.response.body) {
      if (helpers.canLog(2)) console.log('[API] Error', e.response.body)
      if (e.response.body.Detail) {
        return {
          title: e.response.body.Title,
          message: e.response.body.Detail
        }
      } else {
        return {
          message: e.response.body
        }
      }
    } else {
      if (e.message) {
        return {
          message: e.message
        }
      } else {
        if (helpers.canLog(2)) console.log('[API] Error', e)
        return {
          message: 'An error has occoured'
        }
      }
    }
  }
}

export default {
  namespaced: true,
  state: () => {
    return {
      list: null,
      last_update: null
    }
  },
  getters: {
    lastUpdate: (state) => {
      return state.last_update
    },
    one: (state) => (index) => {
      return state.list[index]
    },
    all: (state) => {
      return state.list ? state.list : []
    }
  },
  mutations: {
    add (state, payload) {
      state.list = payload
    },
    last_update (state, payload) {
      state.last_update = parseInt(Date.now() / 1000)
    }
  },
  actions: {
    load: ({ commit, state, getters }, { path, method = 'add', force = false, api = 'control' }) => { // ex Machines.Machines_List
      return new Promise((resolve, reject) => {
        if (helpers.canLog(4)) console.log('[API] load: ', path, method, force)
        if (!force && method === 'add' && state.list && state.list.length > 0) {
          if (helpers.canLog(3)) console.log('[Api] data from vuex')
          return resolve(getters.all)
        } else {
          if (isAuthenticated()) {
            let theAccessToken = accessToken()
            // console.log('/load', theAccessToken)
            let apiObj = controlApi
            if (api === 'data') {
              apiObj = dataApi
            }
            apiObj(theAccessToken).then((apiClient) => {
              let f = get(apiClient.apis, path, null)
              // console.log(path)
              if (helpers.canLog(2)) console.log('[Api] call', path)
              f().then((data) => { // apiClient.machines.list
                if (helpers.canLog(3)) console.log('[Api] data from Remote')
                // if (helpers.canLog(4)) console.log('[Api] data', data.data)
                let decodedData = JSON.parse(data.data)
                commit(method, decodedData)
                commit('last_update')
                resolve(decodedData)
              }).catch((e) => {
                let message = dcsError.extractError(e)
                messages.error(message)
                if (helpers.canLog(0)) console.log('[Api] error', e)
                commit(method, [])
                commit('last_update')
                reject(e)
              })
            }).catch((e) => {
              if (e instanceof TypeError) {
                if (helpers.canLog(0)) console.error(e)
                if (helpers.canLog(0)) console.error('Potrebbe essere dovuto alle definizioni delle API obsolete, usa il tool nel menù in alto per cancellarle e ricarica la pagina (' + path + ')')
              } else {
                commit(method, [])
                commit('last_update')
              }
              let message = dcsError.extractError(e)
              if (helpers.canLog(0)) console.log('error', e)
              messages.error(message)
              reject(e)
            })
          } else {
            reject(new Error('Must be authenticated'))
          }
        }
      })
    },
    action: ({ commit, state, rootGetters }, { path, parameters = null, api = 'contol' }) => {
      return new Promise((resolve, reject) => {
        if (isAuthenticated()) {
          let theAccessToken = accessToken()
          // console.log('/action', theAccessToken)
          let apiObj = controlApi
          if (api === 'data') {
            apiObj = dataApi
          }
          apiObj(theAccessToken).then((apiClient) => {
            if (helpers.canLog(2)) console.log('[Api] call apis.' + path, parameters)

            get(apiClient.apis, path)(parameters).then((data) => {
              if (helpers.canLog(2)) console.log('[Api] response from ' + path, data)
              if (typeof data.data !== 'undefined') {
                resolve(data.data)
              } else {
                resolve(data)
              }
            }).catch((e) => {
              let message = dcsError.extractError(e)
              if (helpers.canLog(0)) console.error('[Api] error', path, message, parameters)
              messages.error(message)
              reject(message)
            })
          }).catch((e) => {
            let message = dcsError.extractError(e)
            if (helpers.canLog(0)) console.error('[Api] error', path, (e.response && e.response.body) ? e.response.body : e.message)
            messages.error(message)
            reject(message)
          })
        } else {
          reject(new Error('Must be authenticated'))
        }
      })
    }
  }
}