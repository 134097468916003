import vuexModule from './vuex-module'
import routerMiddleware from './router-middleware'
import { generateCode } from './helpers'
import axios from 'axios'
import Cookies from 'js-cookie'
import store from '@/store'
import helpers from '@/lib/helpers'
require('formdata-polyfill')

function clientOAuth2 (config) {
  this.config = config
  this.state = null
  this.currentBaseUrl = () => {
    // let base = window.location.protocol + '//' + window.location.hostname
    // if (window.location.port !== '') {
    //   base += ':' + window.location.port
    // }
    // base += process.env.NODE_ENV === 'production' ? window.dcsClientCfg.BaseURL : '/'
    // console.log('[oauth2] baseurl', base)
    return window.location.href.substring(0, window.location.href.lastIndexOf('/')) + '/'
  }
  this.getRedirectUri = () => {
    return this.currentBaseUrl() + config.redirectUri
  }
  this.getPostLogoutUri = () => {
    return this.currentBaseUrl() + config.postLogoutUri
  }
  // this.getLoginUri = () => {
  //   return this.currentBaseUrl() + config.loginUri
  // }
  this.getAuthUrl = () => {
    let urlParms = new URLSearchParams()
    urlParms.append('scope', config.scopes)
    urlParms.append('response_type', 'code')
    urlParms.append('client_id', config.clientId)
    urlParms.append('redirect_uri', this.getRedirectUri())
    // urlParms.append('prompt', 'none')
    let state = generateCode()
    urlParms.append('state', state)
    Cookies.set('oastate', state)
    let url = this.config.authorizationUri + '?' + urlParms.toString()
    return url
  }

  this.processCallback = (url) => {
    return new Promise((resolve, reject) => {
      let urlParms = new URLSearchParams(url.split('?')[1])
      if (urlParms.get('state') === Cookies.get('oastate')) {
        Cookies.remove('oastate')
        if (helpers.canLog(2)) console.log('[oAuth] callback is ok')

        let code = urlParms.get('code')
        let urlReqParms = new FormData()
        urlReqParms.set('grant_type', 'authorization_code')
        urlReqParms.set('code', code)
        urlReqParms.set('redirect_uri', this.getRedirectUri())
        let url = this.config.accessTokenUri
        let config = {
          auth: {
            username: this.config.clientId,
            password: this.config.clientSecret
          }
        }
        axios.post(url, urlReqParms, config)
          .then((data) => {
            if (helpers.canLog(2)) console.log('[oAuth] flow finish', data)
            return data.data
          })
          .then((tokenData) => {
            if (helpers.canLog(2)) console.log('[oAuth]', tokenData)
            let url = this.config.userInfoUri
            let config = {
              headers: { 'Authorization': 'Bearer ' + tokenData.access_token }
            }
            if (helpers.canLog(2)) console.log('[oAuth] get user infos', url, config)
            axios.get(url, config)
              .then((userData) => {
                if (helpers.canLog(2)) console.log('[oAuth] UserInfo received', userData)
                resolve({ user: userData.data, tokens: tokenData })
              })
          })
          .catch(function (error) {
            if (error.response) {
              if (helpers.canLog(0)) console.error('[oAuth]', error.response.data)
            } else {
              if (helpers.canLog(0)) console.error('[oAuth]', error.config)
            }
            reject(error)
          })
      } else {
        if (helpers.canLog(0)) console.error('[oAuth] callback is NOT OK', urlParms.get('state'), this.state)
        reject(new Error('callback not ok'))
      }
    })
  }

  this.getTokenResponse = () => {
    return new Promise(() => {
    })
  }

  this.logout = (idToken) => {
    let urlParms = new URLSearchParams()
    urlParms.append('id_token_hint', idToken)
    urlParms.append('post_logout_redirect_uri', this.getPostLogoutUri())
    let url = this.config.endSessionUri + '?' + urlParms.toString()
    if (helpers.canLog(2)) console.log('[oAuth] logout url', url)
    window.location = url
  }
}

const pluginOAuth2 = {
  install (Vue, options) {
    Vue.mixin({
      computed: {
        user () { return store.getters['oauth2/user'] },
        isAuthenticated () { return store.getters['oauth2/isAuthOk'] }
      }
    })
  }
}

export const OAuth2Client = clientOAuth2
export const OAuth2Plugin = pluginOAuth2
export const oAuth2StoreModule = vuexModule
export const oAuth2RouterMiddleware = routerMiddleware

export * from './helpers'
