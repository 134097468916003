<template>
  <div class="article-select">
    <input name="article-code" type="hidden" v-model="currentItem.value" />
    <input name="article-label" type="hidden" v-model="currentItem.label" />
    <input name="article-search" type="text"
           class="form-control article-current"
           v-model="searchString"
           @focus="showOptions = !showOptions"/>
    <action-icon :class="['options-command', showOptions ? 'opened' : 'closed']"
                 @click="showOptions = !showOptions">
      <chevron-down-icon></chevron-down-icon>
    </action-icon>
    <div :class="['article-options', showOptions ? '' : 'hidden']">
      <div v-if="filteredOptions.length > 0"
           v-for="option in filteredOptions"
           v-bind:key="option.value"
           @click="select(option)"
           class="article-option">{{ option.label }}</div>
      <div v-else
           class="article-option no-results">
        {{ $t('layout.list.no-results') }}
      </div>
    </div>
  </div>
</template>

<script>
import { includes } from 'lodash'
import ActionIcon from '../layout/ActionIcon'
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown'

export default {
  name: 'ArticleSelect',
  components: { ChevronDownIcon, ActionIcon },
  data () {
    return {
      searchString: '',
      currentItem: {
        value: null,
        label: ''
      },
      showOptions: false,
      options: [
        {
          value: 0,
          label: 'zero 00000000 000000000 00000000000'
        },
        {
          value: 1,
          label: 'one'
        },
        {
          value: 2,
          label: 'one2'
        }
      ]
    }
  },
  props: {
    currentValue: {
      type: Number,
      default: null
    }
  },
  computed: {
    filteredOptions () {
      return this.options.filter((opt) => {
        if (this.searchString === this.currentItem.label) {
          return true
        } else {
          return includes(opt.label, this.searchString) ||
            includes(opt.id, this.searchString)
        }
      })
    }
  },
  methods: {
    select (item) {
      this.currentItem = item
      this.searchString = item.label
      this.showOptions = false
      this.$emit('selected', this.currentItem)
    }
  },
  watch: {
    currentValue: {
      handler: function (value) {
        let result = this.options.filter((opt) => opt.value === value)
        this.currentItem = result.length > 0 ? result[0] : { value: '', label: '' }
        this.searchString = this.currentItem.label
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>

  .article-select {
    position: relative;

    .options-command {
      position: absolute;
      right: .2em;
      top: 50%;
      transform: translateY(-50%);

      font-size: 1.4em;
      background-color: white;

      &.opened {
        transform: rotate(180deg) translateY(50%);
      }
    }

    .article-options {
      position: absolute;
      z-index: $zindexInteraction;

      background-color: white;
      border: 1px solid #E4E7EA;
      border-top: 0;
      width: 100%;

      max-height: 250px;
      overflow-y: auto;

      &.hidden {
        display: none;
      }

      .article-option {
        padding: .5em 1em;

        &:not(.no-results) {
          cursor: pointer;

          &:hover {
            background-color: $light-grey;
          }
        }

        &.no-results {
          font-style: italic;
          color: $light-grey;
        }
      }
    }
  }
</style>