<template>
  <div class="container-fluid settings">
    <div class="row">
      <div class="col-12 mb-2">
        <h3>{{ $t('layout.settings.general') }}</h3>
      </div>
    </div>
    <div class="row">
      <b-list-group class="col-md-6 settings-fontsize">
        <b-list-group-item class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{ $t('layout.settings.font-size.title') }}</h5>
            <small>
              <action-icon :active="fontSize !== 'xsmall'" class="fontsize xsmall" @click="onFontChange('xsmall')">
                <format-letter-case-icon :title="'12px'"></format-letter-case-icon> xsmall
              </action-icon>
              <action-icon :active="fontSize !== 'small'" class="fontsize small" @click="onFontChange('small')">
                <format-letter-case-icon :title="'14px'"></format-letter-case-icon> small
              </action-icon>
              <action-icon :active="fontSize !== 'normal'" class="fontsize normal" @click="onFontChange('normal')">
                <format-letter-case-icon :title="'16px'"></format-letter-case-icon> normal
              </action-icon>
            </small>
          </div>
          <p class="mb-1">
            {{ $t('layout.settings.font-size.explain') }}
          </p>
          <small>{{ $t('layout.settings.font-size.actual-value') }} <b>{{ fontSize }}</b></small>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div class="row">
      <div class="col-12 mt-5 mb-2">
        <h3>{{ $t('layout.settings.rows-per-page.title') }}</h3>
      </div>
    </div>
    <div class="row">
      <b-list-group class="col-md-6 settings-fontsize" v-for="(table, index) in tables.sort()" v-bind:key="index" v-if="table !== 'undefined'">
        <b-list-group-item class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{ table.replace('List', '') }}</h5>
            <small>
              <rows-quantity-select :rows-per-page-list="rowsPerPageList"
                                    :value="tableRowsPerPage(table)"
                                    @change="(value) => onTableRowsChange(value, table)"></rows-quantity-select>
            </small>
          </div>
          <p class="mb-1">
            {{ $t('layout.settings.rows-per-page.explain').replace('%table%', table.replace('List', '')) }}
          </p>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div class="row">
      <div class="col-12 mt-5 mb-2">
        <h3>{{ $t('layout.settings.api-poller.title') }}</h3>
        <small>{{ $t('layout.settings.api-poller.moreinfos') }}</small>
      </div>
    </div>
    <div class="row">
      <b-list-group class="col-md-6 settings-fontsize" v-for="(value, table, index) in autoRefresh" v-bind:key="index" v-if="table !== 'undefined'">
        <b-list-group-item class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{ table }}</h5>
            <small>
              <input :type="'number'"
                     class="form-control d-inline"
                     :value="tableAutoRefresh(table)"
                     @keyup="(e) => { if (e.keyCode === 13) setAutoRefresh (table, e) }"
                     @blur="(e) => { setAutoRefresh (table, e) }"
              ><span>secs</span>
            </small>
          </div>
          <p class="mb-1">
            {{ $t('layout.settings.api-poller.explain').replace('%table%', table) }}
          </p>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div class="row">
      <div class="col-12 mt-5 mb-2">
        <h3>{{ $t('layout.settings.other.title') }}</h3>
      </div>
    </div>
    <div class="row">
      <b-list-group class="col-md-6 settings-fontsize">
        <b-list-group-item class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{ $t('layout.settings.other.loglevel.title') }}</h5>
            {{ $logLevel }}
          </div>
          <p class="mb-1">
            {{ $t('layout.settings.other.loglevel.explain') }}
          </p>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import bListGroup from 'bootstrap-vue/es/components/list-group/list-group'
import bListGroupItem from 'bootstrap-vue/es/components/list-group/list-group-item'
import ActionIcon from '../components/layout/ActionIcon'
import FormatFontIcon from 'vue-material-design-icons/FormatFont'
import FormatLetterCaseIcon from 'vue-material-design-icons/FormatLetterCase'
import RowsQuantitySelect from '../components/forms/RowsQuantitySelect'

export default {
  name: 'Settings',
  data: () => {
    return {
      fontSize: '',
      rowsPerPageList: [ 5, 15, 30, 60, 120 ]
    }
  },
  components: { RowsQuantitySelect, FormatLetterCaseIcon, FormatFontIcon, ActionIcon, 'b-list-group': bListGroup, 'b-list-group-item': bListGroupItem },
  computed: {
    ...mapGetters({
      loadedFontSize: 'settings/fontSize',
      tables: 'settings/tables',
      tableRowsPerPage: 'settings/tableRows',
      autoRefresh: 'settings/autoRefresh',
      tableAutoRefresh: 'settings/tableAutoRefresh'
    }),
    apiPollerConfig () {
      return this.$apiPollerConfig
    }
  },
  methods: {
    ...mapMutations({
      saveFontSize: 'settings/fontSize',
      setTableRows: 'settings/tableRows',
      saveAutoRefresh: 'settings/autoRefresh'
    }),
    onFontChange (size) {
      this.fontSize = size
      this.saveFontSize(this.fontSize)
      document.body.classList.remove('fontxsmall', 'fontsmall', 'fontnormal')
      document.body.classList.add(`font${size}`)
      document.documentElement.classList.remove('fontxsmall', 'fontsmall', 'fontnormal')
      document.documentElement.classList.add(`font${size}`)
    },
    onTableRowsChange (value, table) {
      if (value !== this.tableRowsPerPage(table)) {
        this.setTableRows({ key: table, value: value })
        this.$messages.success({ message: this.$t('message.success') })
      }
    },
    setAutoRefresh (table, e) {
      console.log(e.target.value)
      if (e.target.value === '') {
        e.target.value = 0
      }
      let value = parseInt(e.target.value)
      if (value > 0 && value < 10) {
        value = 10
        e.target.value = 10
      }
      this.saveAutoRefresh({ key: table, value: value })
      this.$messages.success({ message: this.$t('message.success') })
    }
  },
  mounted () {
    this.fontSize = this.loadedFontSize
  }
}
</script>

<style lang="scss" scoped>
.settings-fontsize {

  .fontsize {
    padding: 0 .5rem;
    &.disabled {
      color: $black;

      &:hover {
        text-decoration: none;
        cursor: default;
      }
    }
  }

  input {
    width: 100px;
    margin-right: 3px;
  }

  .xsmall {
    font-size: 12px;
  }

  .small {
    font-size: 14px;
  }

  .normal {
    font-size: 16px;
  }
}
.list-group {
  margin-top: .5rem;
}
</style>