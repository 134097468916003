import store from '@/store'
import Vue from 'vue'
import helpers from '@/lib/helpers'

const _messageTypes = {
  ERROR: 0,
  WARNING: 1,
  SUCCESS: 2
}

const messageSystem = {
  install (Vue, options) {
    Vue.prototype.$messages = messageSystem
    Vue.prototype.$messagesTypes = _messageTypes
  },
  success: ({ message, title }) => {
    store.commit('messages/addMessage', { level: 2, text: message, title: title })
  },
  warning: ({ message, title }) => {
    store.commit('messages/addMessage', { level: 1, text: message, title: title })
  },
  error: ({ message, title }) => {
    if (store.getters['messages/oneByMessage'](0, message) === 0) {
      store.commit('messages/addMessage', { level: 0, text: message, title: title })
    } else {
      if (helpers.canLog(2)) console.log('[Message] Suppressed duplicated message')
    }
  }
}

export const events = new Vue()

export const messages = messageSystem
export const messageTypes = _messageTypes