<template>
  <div class="row bulks my-3" v-show="show">
    <div class="col-md-2 bulk-actions">
      <button type="button"
              v-tooltip="helpTextContent('anomalies.button.retry')"
              @click.prevent="() => { if (canRetry) { $emit('bulk-action-retry') } }"
              :class="[
                  'btn btn-outline-success action-retry btn-block text-capitalize',
                  canRetry ? 'active' : 'disabled'
                ]">
        {{ $t('layout.button.retry') }}
      </button>
    </div>
    <div class="col-md-2 bulk-actions">
      <button type="button"
              v-tooltip="helpTextContent('anomalies.button.ignore')"
              @click.prevent="() => { if (canIgnore) { $emit('bulk-action-ignore') } }"
              :class="[
                'btn btn-outline-danger action-ignore btn-block text-capitalize',
                canIgnore ? 'active' : 'disabled'
              ]">
        {{ $t('layout.button.ignore') }}
      </button>
    </div>
    <div class="col-auto"></div>
  </div>
</template>

<script>
import HelpTextsMixin from '@/mixins/HelpTextsMixin'
export default {
  name: 'ListBulkActions',
  mixins: [ HelpTextsMixin ],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    canRetry () {
      return (!this.disabled) && this.$hasPerms('dcs.anomalies.actions.retry')
    },
    canIgnore () {
      return (!this.disabled) && this.$hasPerms('dcs.anomalies.actions.ignore')
    }
  }
}
</script>

<style scoped>

</style>