<template>
  <div class="container-fluid drivers-list">
    <div class="card">
      <div class="card-header text-uppercase">
        <format-list-bulleted-icon></format-list-bulleted-icon>
        {{ $t('menu.drivers') }}
        &nbsp;
        <inline-help :content="helpTextContent('drivers.list.title')"></inline-help>
        <record-counter :can-show="loaded"
                        :rows-per-page="rowsPerPage"
                        :num-results="data.length"></record-counter>
      </div>
      <div class="card-body">
        <div class="card">
          <div class="card-body overflow-visible">
            <div class="row clear-filters justify-content-between" v-show="loaded">
              <div class="col-md-12">
                <rows-quantity-select :rows-per-page-list="rowsPerPageList"
                                      :value="rowsPerPage"
                                      @change="(value) => { setTableRows ({ key: $options.name, value: value }) }"></rows-quantity-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row bulks my-3" v-show="loaded">
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="callNew"
                    v-tooltip="helpTextContent('drivers.button.new')"
                    :class="[
                      'btn btn-outline-primary btn-block text-capitalize',
                      $hasPerms('dcs.drivers.actions.new') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.new') }}
            </button>
          </div>
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="wantsToDelete = true"
                    v-tooltip="helpTextContent('drivers.button.delete')"
                    :class="[
                      'btn btn-outline-danger btn-block text-capitalize',
                      (selectedIds.length > 0) && $hasPerms('dcs.services.actions.delete') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.delete') }}
            </button>
          </div>
          <div class="col-auto"></div>
          <div class="col-auto"></div>
        </div>
        <div class="row list">
          <div class="col-12">
            <div class="text-center loading" v-show="!loaded">{{ $t('message.loading') }}</div>
            <vuetable
                    v-if="loaded"
                    ref="vuetable"
                    :css="tableStyle.table"
                    :api-mode="false"
                    :per-page="rowsPerPage"
                    :fields="fieldsDefinition"
                    :data-manager="dataManager"
                    :displayEmptyDataRow="true"
                    :sort-order="sortOrder"
                    pagination-path="pagination"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:cell-clicked="onCellClicked"
                    @vuetable:checkbox-toggled="onCheckboxToggled"
                    @vuetable:checkbox-toggled-all="onCheckboxToggledAll">
              <div slot="model-slot" slot-scope="props">
                <span class="mobile-caption d-block d-md-none"></span>
                <router-link :to="{ name: 'dcs.drivers.edit', params: { id: props.rowData.id } }">
                  {{ props.rowData.model }}
                </router-link>
              </div>
              <div slot="signature-slot" slot-scope="props">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.signature') }}</span>
                {{ props.rowData.signature }}
              </div>
            </vuetable>
          </div>
        </div>
        <div class="row paging">
          <div class="col-12">
          <vuetable-pagination-custom ref="pagination"
                                      :css="tableStyle.pagination"
                                      @vuetable-pagination:change-page="onChangePage">
          </vuetable-pagination-custom>
          </div>
        </div>
        <div class="row bulks my-3" v-show="loaded">
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="callNew"
                    v-tooltip="helpTextContent('drivers.button.new')"
                    :class="[
                      'btn btn-outline-primary btn-block text-capitalize',
                      $hasPerms('dcs.drivers.actions.new') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.new') }}
            </button>
          </div>
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="wantsToDelete = true"
                    v-tooltip="helpTextContent('drivers.button.delete')"
                    :class="[
                      'btn btn-outline-danger btn-block text-capitalize',
                      (selectedIds.length > 0) && $hasPerms('dcs.services.actions.delete') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.delete') }}
            </button>
          </div>
          <div class="col-auto"></div>
          <div class="col-auto"></div>
        </div>
      </div>
    </div>
    <!-- modale conferma cancellazione ************************************ -->
    <modal-yes-no v-show="wantsToDelete"
                  :confirmCloseMessage="$t('layout.delete.are-you-sure').replace('%n%', selectedIds.length)"
                  @click-yes="() => { callDelete(); wantsToDelete = false }"
                  @click-no="wantsToDelete = false">
    </modal-yes-no>
    <router-view></router-view>
  </div>
</template>

<script>
import { events } from '@/lib/messages'
import { mapGetters, mapActions } from 'vuex'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationCustom from '@/components/VuetablePaginationCustom'
import VuetableFieldCheckboxCustom from '@/components/VuetableFieldCheckboxCustom'
import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted'
import VuetableMixin from '@/mixins/VuetableMixin'
import RowsQuantitySelect from '../../components/forms/RowsQuantitySelect'
import ActionIcon from '../../components/layout/ActionIcon'
import ModalYesNo from '../../components/ModalYesNo'
import CRUDListMixin from '@/mixins/CRUDListMixin'
import RecordCounter from '../../components/RecordCounter'
import InlineHelp from '../../components/InlineHelp'
import HelpTextsMixin from '../../mixins/HelpTextsMixin'

export default {
  name: 'DriversList',
  components: { InlineHelp, RecordCounter, ModalYesNo, ActionIcon, RowsQuantitySelect, VuetableFieldCheckboxCustom, FormatListBulletedIcon, VuetablePaginationCustom, Vuetable },
  mixins: [ HelpTextsMixin, VuetableMixin, CRUDListMixin ],
  data () {
    return {
      eventSystem: events,
      closedCheckbox: false,
      wantsToDelete: false,
      showDetails: null,
      defaultFilters: {},
      fieldsDefinition: [
        {
          name: VuetableFieldCheckboxCustom,
          title: '',
          dataClass: 'align-middle'
        },
        {
          name: 'model-slot',
          title: this.$t('layout.list.model'),
          sortField: 'model'
        },
        {
          name: 'signature-slot',
          title: this.$t('layout.list.signature'),
          sortField: 'signature'
        }
      ],
      sortOrder: [{
        field: 'model',
        direction: 'asc'
      }]
    }
  },
  computed: {
    ...mapGetters({
      data: 'drivers/all',
      lastUpdate: 'drivers/lastUpdate'
    })
  },
  methods: {
    ...mapActions({
      load: 'drivers/load',
      delete: 'drivers/delete'
    }),
    onCellClicked () {

    },
    callNew () {
      if (this.$hasPerms('dcs.drivers.actions.new')) {
        this.$router.push({ name: 'dcs.drivers.edit', params: { id: 'new' } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .status {
    font-weight: bold;

    &.status-started {
      color: $green;
    }
    &.status-stopped,
    &.status-disabled {
      color: $slate-grey
    }
  }

  /deep/ .details-enabled {
    cursor: context-menu;
  }

  /deep/ .vuetable-detail-row {
    background-color: rgba(0, 0, 0, 0.075);
    &:hover {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }

  /deep/ tr.selected {
    background-color: rgba(0, 0, 0, 0.075);
  }

  tr {
    .action-icon {
      padding: 2px;
      line-height: 1.2;
      &.start.color-green {
        &.started, &.disabled {
          color: $heater;
          cursor: default;
        }
      }

      &.stop.color-red {
        &.stopped, &.disabled {
          color: $heater;
          cursor: default;
        }
      }

      &.tools {
        font-size: 1.5em;
      }
    }

    &.disabled {

    }
  }

</style>