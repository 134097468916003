export default {
  table: {
    tableWrapper: '',
    tableHeaderClass: 'mb-0',
    tableBodyClass: 'mb-0',
    tableClass: 'table table-bordered table-hover table-sm',
    loadingClass: 'loading',
    ascendingIcon: 'mi expand_less em-09',
    descendingIcon: 'mi expand_more em-09',
    // ascendingClass: 'mi sort',
    // descendingClass: 'mi sort r180',
    sortableIcon: 'mi unfold_more em-09',
    detailRowClass: 'vuetable-detail-row',
    handleIcon: 'fa fa-bars text-secondary',
    renderIcon (classes, options) {
      return `<i class="material-icons align-middle ${classes.join(' ')}"></i>`
    }
  },
  pagination: {
    wrapperClass: 'pagination',
    activeClass: 'active',
    disabledClass: 'disabled',
    pageClass: 'page-item',
    linkClass: 'page-link',
    paginationClass: 'pagination',
    paginationInfoClass: 'float-left',
    dropdownClass: 'form-control',
    icons: {
      first: 'material-design-icon format-list-bulleted-icon',
      prev: 'fa fa-chevron-left',
      next: 'fa fa-chevron-right',
      last: 'fa fa-chevron-right'
    }
  }
}