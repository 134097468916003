import helpers from '@/lib/helpers'

export default {
  namespaced: true,
  state: {
    dcs: {}
  },
  getters: {
    get (state) {
      if (helpers.canLog(3)) console.log('[Perms] getPermissions', localStorage.getItem('dcs-permissions'))
      state.dcs = JSON.parse(localStorage.getItem('dcs-permissions'))
      return state
    }
  },
  mutations: {
    set (state, value) {
      if (helpers.canLog(3)) console.log('[Perms] setPrmissions', value)
      localStorage.setItem('dcs-permissions', JSON.stringify(value))
      state.dcs = value
    }
  },
  actions: {
    list (context) {
      return new Promise((resolve, reject) => {
        let mockup = {
          anomalies: {
            list: true,
            details: true,
            actions: {
              ignore: true,
              retry: true
            }
          },
          machines: {
            actions: {
              stop: true,
              save: true
            }
          }
        }
        context.commit('set', mockup)
        resolve(mockup)
      })
    }
  }
}