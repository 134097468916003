<template>
  <div class="container-fluid mappings-list">
    <div class="card">
      <div class="card-header text-uppercase">
        <format-list-bulleted-icon></format-list-bulleted-icon>
        {{ $t('menu.mappings') }}
        &nbsp;
        <inline-help :content="helpTextContent('mappings.list.title')"></inline-help>
        <record-counter :can-show="loaded"
                        :rows-per-page="rowsPerPage"
                        :num-results="data.length"></record-counter>
      </div>
      <div class="card-body">
        <div class="card">
          <div class="card-body overflow-visible">
            <div class="row filters" v-show="loaded">
              <div class="col-md-6">
                <input-form-select :label-class="'col-md-4'"
                                   :input-class="'col-auto'"
                                   :value="filters.mappedEntityType"
                                   :options="localEntityTypesAsOptions"
                                   :label="$t('layout.list.local-entity-type')"
                                   :loaded="loaded"
                                   @selected="(item) => { filters.mappedEntityType = item.value }"></input-form-select>
              </div>
              <div class="col-md-6">
                <input-form-select :label-class="'col-md-4'"
                                   :input-class="'col-auto'"
                                   :value="filters.mappedExternalDomain"
                                   :options="externalDomainsAsOptions"
                                   :label="$t('layout.list.external-domain')"
                                   :loaded="loaded"
                                   @selected="(item) => { filters.mappedExternalDomain = item.value }"></input-form-select>
              </div>
            </div>
            <div class="row clear-filters justify-content-between" v-show="loaded">
              <div class="col-md-10">
                <rows-quantity-select :rows-per-page-list="rowsPerPageList"
                                      :value="rowsPerPage"
                                      @change="(value) => { setTableRows ({ key: $options.name, value: value }) }"></rows-quantity-select>
              </div>
              <div class="col-md-2">
                <button type="button"
                        @click.prevent="resetFilters"
                        :class="[
                          'btn btn-outline-secondary action-reset btn-block text-capitalize',
                          hasFilters ? 'active' : 'disabled'
                        ]">
                  {{ $t('layout.button.reset') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row bulks my-3" v-show="loaded">
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="callNew"
                    v-tooltip="helpTextContent('mappings.button.new')"
                    :class="[
                      'btn btn-outline-primary btn-block text-capitalize',
                      $hasPerms('dcs.drivers.actions.new') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.new') }}
            </button>
          </div>
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="wantsToDelete = true"
                    v-tooltip="helpTextContent('mappings.button.delete')"
                    :class="[
                      'btn btn-outline-danger btn-block text-capitalize',
                      (selectedIds.length > 0) && $hasPerms('dcs.services.actions.delete') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.delete') }}
            </button>
          </div>
          <div class="col-auto"></div>
        </div>
        <div class="row list">
          <div class="col-12">
            <div class="text-center loading" v-show="!loaded">{{ $t('message.loading') }}</div>
            <vuetable
                    v-if="loaded"
                    ref="vuetable"
                    :css="tableStyle.table"
                    :api-mode="false"
                    :per-page="rowsPerPage"
                    :fields="fieldsDefinition"
                    :data-manager="dataManager"
                    :displayEmptyDataRow="true"
                    :sort-order="sortOrder"
                    pagination-path="pagination"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:cell-clicked="onCellClicked"
                    @vuetable:checkbox-toggled="onCheckboxToggled"
                    @vuetable:checkbox-toggled-all="onCheckboxToggledAll">
              <div slot="id-slot" slot-scope="props">
                <span class="mobile-caption d-block d-md-none"></span>
                <router-link :to="{ name: 'dcs.mappings.edit', params: { id: props.rowData.id } }">
                  {{ props.rowData.id }}
                </router-link>
              </div>
              <div slot="local_entity_type-slot" slot-scope="props">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.local-entity-type') }}</span>
                {{ props.rowData.mappedEntityType }}
              </div>
              <div slot="local_entity_id-slot" slot-scope="props">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.local-entity-ref') }}</span>
                {{ props.rowData.localEntityLabel }} ({{ props.rowData.localEntityId }})
              </div>
              <div slot="external_domain-slot" slot-scope="props">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.external-domain') }}</span>
                {{ props.rowData.mappedExternalDomain }}
              </div>
              <div slot="external_entity_id-slot" slot-scope="props">
                <span class="mobile-caption d-block d-md-none">{{ $t('layout.list.external-entity-ref') }}</span>
                {{ props.rowData.externalEntityLabel }} ({{ props.rowData.externalEntityId }})
              </div>
            </vuetable>
          </div>
        </div>
        <div class="row paging">
          <div class="col-12">
          <vuetable-pagination-custom ref="pagination"
                                      :css="tableStyle.pagination"
                                      @vuetable-pagination:change-page="onChangePage">
          </vuetable-pagination-custom>
          </div>
        </div>
        <div class="row bulks my-3" v-show="loaded">
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="callNew"
                    v-tooltip="helpTextContent('mappings.button.new')"
                    :class="[
                      'btn btn-outline-primary btn-block text-capitalize',
                      $hasPerms('dcs.drivers.actions.new') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.new') }}
            </button>
          </div>
          <div class="col-md-2 bulk-actions">
            <button type="button"
                    @click.prevent="wantsToDelete = true"
                    v-tooltip="helpTextContent('mappings.button.delete')"
                    :class="[
                      'btn btn-outline-danger btn-block text-capitalize',
                      (selectedIds.length > 0) && $hasPerms('dcs.services.actions.delete') ? 'active' : 'disabled'
                    ]">
              {{ $t('layout.button.delete') }}
            </button>
          </div>
          <div class="col-auto"></div>
        </div>
      </div>
    </div>
    <!-- modale conferma cancellazione ************************************ -->
    <modal-yes-no v-show="wantsToDelete"
                  :confirmCloseMessage="$t('layout.delete.are-you-sure').replace('%n%', selectedIds.length)"
                  @click-yes="() => { callDelete(); wantsToDelete = false }"
                  @click-no="wantsToDelete = false">
    </modal-yes-no>
    <router-view></router-view>
  </div>
</template>

<script>
import { events } from '@/lib/messages'
import { mapGetters, mapActions } from 'vuex'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationCustom from '@/components/VuetablePaginationCustom'
import VuetableFieldCheckboxCustom from '@/components/VuetableFieldCheckboxCustom'
import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted'
import VuetableMixin from '@/mixins/VuetableMixin'
import RowsQuantitySelect from '../../components/forms/RowsQuantitySelect'
import ActionIcon from '../../components/layout/ActionIcon'
import ModalYesNo from '../../components/ModalYesNo'
import CRUDListMixin from '@/mixins/CRUDListMixin'
import { clone } from 'lodash'
import InputFormSelect from '../../components/forms/InputFormSelect'
import RecordCounter from '../../components/RecordCounter'
import InlineHelp from '../../components/InlineHelp'
import HelpTextsMixin from '../../mixins/HelpTextsMixin'

export default {
  name: 'MappingsList',
  components: { InlineHelp, RecordCounter, InputFormSelect, ModalYesNo, ActionIcon, RowsQuantitySelect, VuetableFieldCheckboxCustom, FormatListBulletedIcon, VuetablePaginationCustom, Vuetable },
  mixins: [ HelpTextsMixin, VuetableMixin, CRUDListMixin ],
  data () {
    return {
      eventSystem: events,
      defaultFilters: {
        mappedEntityType: '',
        mappedExternalDomain: ''
      },
      filters: {},
      fieldsDefinition: [
        {
          name: VuetableFieldCheckboxCustom,
          title: '',
          dataClass: 'align-middle'
        },
        {
          name: 'id-slot',
          title: this.$t('layout.list.id')
        },
        {
          name: 'local_entity_type-slot',
          title: this.$t('layout.list.local-entity-type')
        },
        {
          name: 'local_entity_id-slot',
          title: this.$t('layout.list.local-entity-ref')
        },
        {
          name: 'external_domain-slot',
          title: this.$t('layout.list.external-domain')
        },
        {
          name: 'external_entity_id-slot',
          title: this.$t('layout.list.external-entity-ref')
        }
      ],
      sortOrder: [{
        field: 'id',
        direction: 'asc'
      }]
    }
  },
  computed: {
    ...mapGetters({
      data: 'mappings/all',
      localEntityTypes: 'mappings/allLocalEntityTypes',
      localEntities: 'mappings/allLocalEntities',
      externalDomains: 'mappings/allExternalDomains',
      externalEntities: 'mappings/allExternalEntities',
      lastUpdate: 'mappings/lastUpdate'
    }),
    localEntityTypesAsOptions () {
      if (this.localEntityTypes) {
        return [
          {
            value: '',
            label: '-',
            search: ''
          }
        ].concat(this.localEntityTypes.map((item) => {
          return {
            value: item.type,
            label: item.localizedName,
            search: item.localizedName
          }
        }))
      }
    },
    externalDomainsAsOptions () {
      if (this.externalDomains) {
        return [
          {
            value: '',
            label: '-',
            search: ''
          }
        ].concat(this.externalDomains.map((item) => {
          return {
            value: item,
            label: item,
            search: item
          }
        }))
      }
    }
  },
  methods: {
    ...mapActions({
      load: 'mappings/load',
      loadLocalEntityTypes: 'mappings/loadLocalEntityTypes',
      loadExternalDomains: 'mappings/loadExternalDomains',
      delete: 'mappings/delete'
    }),
    onCellClicked () {

    },
    callNew () {
      if (this.$hasPerms('dcs.mappings.actions.new')) {
        this.$router.push({ name: 'dcs.mappings.edit', params: { id: 'new' } })
      }
    },
    saveFilters () {
      localStorage.setItem('mappings-list-filters', JSON.stringify(this.filters))
    },
    loadFilters () {
      if (localStorage.getItem('mappings-list-filters')) {
        this.filters = JSON.parse(localStorage.getItem('anomalies-list-filters'))
      } else {
        this.resetFilters()
      }
    },
    resetFilters () {
      this.filters = clone(this.defaultFilters)
    },
    reload (force = false) {
      return Promise.all([
        this.load(force),
        this.loadLocalEntityTypes(),
        this.loadExternalDomains()
      ]).finally(() => {
        this.loaded = true
      })
    }
  },
  mounted () {
    if (this.$canLog(2)) console.log('[Mappings] init filters')
    this.loadFilters()
  }
}
</script>

<style lang="scss" scoped>
  .status {
    font-weight: bold;

    &.status-started {
      color: $green;
    }
    &.status-stopped,
    &.status-disabled {
      color: $slate-grey
    }
  }

  /deep/ .details-enabled {
    cursor: context-menu;
  }

  /deep/ .vuetable-detail-row {
    background-color: rgba(0, 0, 0, 0.075);
    &:hover {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }

  /deep/ tr.selected {
    background-color: rgba(0, 0, 0, 0.075);
  }

  tr {
    .action-icon {
      padding: 2px;
      line-height: 1.2;
      &.start.color-green {
        &.started, &.disabled {
          color: $heater;
          cursor: default;
        }
      }

      &.stop.color-red {
        &.stopped, &.disabled {
          color: $heater;
          cursor: default;
        }
      }

      &.tools {
        font-size: 1.5em;
      }
    }

    &.disabled {

    }
  }

</style>