<template>
  <div>
    <component :is="detailsComponent"
               :id="id"></component>
  </div>
</template>

<script>
import logsOperations from './logsOperations'
import toolsList from './toolsList'

export default {
  name: 'DetailRowMachine',
  components: { logsOperations, toolsList },
  data () {
    return {
      detailsComponent: '',
      operations: [],
      logs: [],
      loaded: false,
      enableTimer: false
    }
  },
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    },
    options: {
      type: Object,
      required: true
    }
  },
  computed: {
    id () {
      return this.rowData.id
    }
  },
  mounted () {
    this.loaded = false
    this.detailsComponent = this.options.component
  }
}
</script>

<style lang="scss" scoped>

</style>