import ApiReusableVuexModule from '@/lib/api/ApiReusableVuexModule'

const machines = {
  namespaced: true,
  state: () => {
    return {
      list: null,
      last_update: null,
      drivers: null
    }
  },
  getters: {
    one: (state) => (index) => { return ApiReusableVuexModule.getters.one(state)(index) },
    all: (state) => { return ApiReusableVuexModule.getters.all(state) },
    lastUpdate: (state) => { return ApiReusableVuexModule.getters.lastUpdate(state) },
    allDrivers: (state) => {
      return state.drivers
    },
    oneById: (state) => (id) => {
      return state.list.filter((machine) => { return machine.id === id })
    },
    oneByName: (state) => (name) => {
      return state.list.filter(itm => itm.name === name)
    }
  },
  mutations: { ...ApiReusableVuexModule.mutations,
    ...{
      addDrivers (state, payload) {
        state.drivers = payload
      }
    }
  },
  actions: {
    load: (context, force = false) => {
      return ApiReusableVuexModule.actions.load(context, { path: 'Machines.Machines_List', force: force })
    },
    loadDrivers: (context) => {
      if (context.state.drivers && context.state.drivers.length > 0) {
        return new Promise((resolve) => {
          resolve(context.state.drivers)
        })
      }
      return ApiReusableVuexModule.actions.load(context, { path: 'Machines.Machines_DriverList', method: 'addDrivers' })
    },
    get: (context, id) => {
      return context.dispatch('action', { path: 'Machines.Machines_Get', parameters: { id: id } })
    },
    details: (context, id) => {
      return context.dispatch('action', { path: 'Machines.Machines_Details', parameters: { id: id } })
    },
    info: (context, id) => {
      return context.dispatch('action', { path: 'Machines.Machines_Info', parameters: { id: id } })
    },
    tools: (context, id) => {
      return context.dispatch('action', { path: 'Machines.Machines_ToolLinks', parameters: { id: id, machineId: id } })
    },
    runtimeStatus: (context, id) => {
      // console.log('[api] getRuntimeStatus', id)
      return context.dispatch('action', { path: 'Machines.Machines_RuntimeStatus', parameters: { id: id } })
    },
    normalizedDriverConfig: (context, id) => {
      return context.dispatch('action', { path: 'Machines.Machines_NormalizedDriverConfiguration', parameters: { machineId: id } })
    },
    normalizedMnmConfig: (context, id) => {
      return context.dispatch('action', { path: 'Machines.Machines_NormalizedMnmConfiguration', parameters: { machineId: id } })
    },
    save: (context, machine) => {
      return context.dispatch('action', { path: 'Machines.Machines_Update', parameters: { machine: machine } })
    },
    new: (context, machine) => {
      return context.dispatch('action', { path: 'Machines.Machines_Create', parameters: { machine: machine } })
    },
    delete: (context, id) => {
      return context.dispatch('action', { path: 'Machines.Machines_Delete', parameters: { id: id } })
    },
    action: (context, { path, parameters = null }) => {
      return ApiReusableVuexModule.actions.action(context, { path, parameters })
    }
  }
}

export default machines