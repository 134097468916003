import ApiReusableVuexModule from '@/lib/api/ApiReusableVuexModule'

const mappings = {
  namespaced: true,
  state: () => {
    return {
      list: null,
      last_update: null,
      localEntityTypes: null,
      localEntities: null,
      externalDomains: null,
      externalEntities: null
    }
  },
  getters: {
    one: (state) => (index) => { return ApiReusableVuexModule.getters.one(state)(index) },
    all: (state) => { return ApiReusableVuexModule.getters.all(state) },
    lastUpdate: (state) => { return ApiReusableVuexModule.getters.lastUpdate(state) },
    allLocalEntityTypes: (state) => {
      return state.localEntityTypes ? state.localEntityTypes : []
    },
    allLocalEntities: (state) => {
      return state.localEntities ? state.localEntities : []
    },
    allExternalDomains: (state) => {
      return state.externalDomains ? state.externalDomains : []
    },
    allExternalEntities: (state) => {
      return state.externalEntities ? state.externalEntities : []
    },
    oneLocalEntityType: (state) => (type) => {
      if (state.localEntityTypes && state.localEntityTypes.length > 0) {
        return state.localEntityTypes.filter(itm => itm.type === type)[0]
      } else {
        return null
      }
    }
  },
  mutations: { ...ApiReusableVuexModule.mutations,
    ...{
      addLocalEntityTypes (state, payload) {
        state.localEntityTypes = payload
      },
      addLocalEntities (state, payload) {
        state.localEntities = payload
      },
      addExternalDomains (state, payload) {
        state.externalDomains = payload
      },
      addExternalEntities (state, payload) {
        state.externalEntities = payload
      }
    }
  },
  actions: {
    get: (context, id) => {
      return context.dispatch('action', { path: 'ExternalResourceMappings.ExternalResourceMappings_Get', parameters: { id: id } })
    },
    load: (context, force = false) => {
      return ApiReusableVuexModule.actions.load(context, { path: 'ExternalResourceMappings.ExternalResourceMappings_List', force: force })
    },
    loadLocalEntityTypes: (context, force = false) => {
      return ApiReusableVuexModule.actions.load(context, {
        path: 'ExternalResourceMappings.ExternalResourceMappings_LocalEntityTypeList',
        method: 'addLocalEntityTypes',
        force: force
      })
    },
    loadLocalEntities: (context, entityType) => {
      return ApiReusableVuexModule.actions.action(context, {
        path: 'ExternalResourceMappings.ExternalResourceMappings_LocalEntityList',
        parameters: { entityType: entityType }
      }).then((data) => {
        let decodedData = JSON.parse(data)
        context.commit('addLocalEntities', decodedData)
      })
    },
    loadExternalEntities: (context, { entityType, externalDomain }) => {
      return ApiReusableVuexModule.actions.action(context, {
        path: 'ExternalResourceMappings.ExternalResourceMappings_ExternalEntityList',
        parameters: { entityType: entityType, domain: externalDomain }
      }).then((data) => {
        let decodedData = JSON.parse(data)
        context.commit('addExternalEntities', decodedData)
      })
    },
    loadExternalDomains: (context, force = false) => {
      return ApiReusableVuexModule.actions.load(context, {
        path: 'ExternalResourceMappings.ExternalResourceMappings_ExternalDomainList',
        method: 'addExternalDomains',
        force: force
      })
    },
    save: (context, driver) => {
      return context.dispatch('action', { path: 'ExternalResourceMappings.ExternalResourceMappings_Update', parameters: { mapping: driver } })
    },
    new: (context, driver) => {
      return context.dispatch('action', { path: 'ExternalResourceMappings.ExternalResourceMappings_Create', parameters: { mapping: driver } })
    },
    delete: (context, id) => {
      return context.dispatch('action', { path: 'ExternalResourceMappings.ExternalResourceMappings_Delete', parameters: { id: id } })
    },
    action: (context, { path, parameters = null }) => {
      // console.log('[Store] invoke action', path, parameters)
      return ApiReusableVuexModule.actions.action(context, { path, parameters })
    }
  }
}

export default mappings