<template>
  <div class="runtime-status-form">
    <div class="row">
      <div class="col-12" v-for="(caption, index) in captions" v-bind:key="index">
        <div v-b-toggle="caption + index" class="status-element__caption">{{ caption }}</div>
        <b-collapse visible :id="caption + index" class="status-element__items">
          <table class="vuetable table table-bordered table-hover table-sm mb-0">
            <tbody>
              <tr v-for="(item, i) in data[index].entries" v-bind:key="i">
                <td>
                  {{ item.label }}
                </td>
                <td :style="cellStyle(item)">
                  {{ item.value }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import bCollapse from 'bootstrap-vue/es/components/collapse/collapse'
import bButton from 'bootstrap-vue/es/components/button/button'
import bToggleDirective from 'bootstrap-vue/es/directives/toggle/toggle'

export default {
  name: 'RuntimeStatus',
  components: { 'b-btn': bButton, 'b-collapse': bCollapse },
  mixins: [ ],
  directives: {
    'b-toggle': bToggleDirective
  },
  props: {
    parameters: {
      type: Array,
      required: true
    },
    isLoaded: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loaded: false,
      rowsPerPage: 5,
      data: []
    }
  },
  computed: {
    captions () {
      return this.data.map((item) => item.label)
    }
  },
  methods: {
    cellStyle ({ backgroundColor, foregroundColor, bold }) {
      let style = ''
      if (backgroundColor) {
        style += ' background-color: #' + backgroundColor + '; '
      }
      if (foregroundColor) {
        style += 'color: #' + foregroundColor + '; '
      }
      if (bold) {
        style += 'font-weight: bold; '
      }
      return style
    }
  },
  watch: {
    parameters: {
      handler: function (value, oldValue) {
        if (this.$canLog(2)) console.log('[RuntimeStatus] prop changed')
        this.data = value
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .status-element__caption {
    background-color: $botticelli;
    display: block;
    padding: 0.2em 1em;

    &.collapsed {
      margin-bottom: 1em;
    }
  }

  .status-element__items {
    margin-bottom: 1em;
  }

  .vuetable {
    td:first-of-type {
      @include media-breakpoint-up(md) {
        width: 1px;
        white-space: nowrap;
      }
    }
  }

</style>