<template>
  <li :class="['nav-item nav-dropdown', '']">
    <a class="nav-link nav-dropdown-toggle" v-toggle-open>
      <database-search-icon :title="$t('menu.queries')"></database-search-icon>
      {{ $t('menu.queries') }}
    </a>
    <ul class="nav-dropdown-items" v-if="loaded">
      <li class="nav-item" v-for="category in data" v-bind:key="category.type">
        <router-link class="nav-link small" :to="{ name: 'dcs.queries.list' , params: { category: category.key }}">
          <table-search-icon :title="category.name"></table-search-icon>
          {{ category.name }}
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script>
import SidebarGroupToggler from '@/mixins/SidebarGroupToggler'
import TableSearchIcon from 'vue-material-design-icons/TableSearch'
import DatabaseSearchIcon from 'vue-material-design-icons/DatabaseSearch'
import { events } from '@/lib/messages'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SidebarGroupQueries',
  components: { DatabaseSearchIcon, TableSearchIcon },
  mixins: [ SidebarGroupToggler ],
  data () {
    return {
      loaded: false,
      eventSystem: events
    }
  },
  computed: {
    ...mapGetters({
      data: 'queries/allCategories'
    })
  },
  methods: {
    ...mapActions({
      load: 'queries/loadCategories'
    }),
    reload (force = false) {
      this.loaded = false
      this.load(true).finally(() => {
        this.loaded = true
      })
    }
  },
  mounted () {
    this.reload()
    this.eventSystem.$on('change-language', () => {
      if (this.$canLog(2)) console.log('[SidebarGroupQueries] invoked change language')
      this.reload(true)
    })
  }
}
</script>

<style>

</style>