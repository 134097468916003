<template>
  <div class="row detail-row">
    <div class="col-12">
      <div class="card">
        <div class="card-header text-uppercase">
          <div>{{ $t('layout.details.message') }}</div>
        </div>
        <div class="card-body list">
          <p class="operation-entry" v-html="rowData.resultLong"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DetailRowDataChecks',
  data () {
    return {
      messages: []
    }
  },
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    },
    options: {
      type: Object,
      required: false
    }
  },
  computed: {

  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>

</style>