import ApiReusableVuexModule from '@/lib/api/ApiReusableVuexModule'

const queries = {
  namespaced: true,
  state: () => {
    return {
      list: null,
      categories: null,
      last_update: null,
      drivers: null
    }
  },
  getters: {
    one: (state) => (index) => {
      return ApiReusableVuexModule.getters.one(state)(index)
    },
    all: (state) => {
      return ApiReusableVuexModule.getters.all(state)
    },
    lastUpdate: (state) => {
      return ApiReusableVuexModule.getters.lastUpdate(state)
    },
    allCategories: (state) => {
      return state.categories
    }
  },
  mutations: { ...ApiReusableVuexModule.mutations,
    ...{
      addCategories (state, payload) {
        state.categories = payload
      }
    }
  },
  actions: {
    loadCategories: (context, { force = false }) => {
      if (!force && context.state.categories && context.state.categories.length > 0) {
        return new Promise((resolve) => {
          resolve(context.state.categories)
        })
      }
      return ApiReusableVuexModule.actions.load(context, { path: 'Queries.Queries_Categories', method: 'addCategories', api: 'data' })
    },
    load: (context, { force = false }) => {
      return ApiReusableVuexModule.actions.load(context, { path: 'Queries.Queries_List', force: force, api: 'data' })
    },
    send: (context, query) => {
      return ApiReusableVuexModule.actions.action(context, { path: 'Queries.Queries_Send', parameters: { query: query }, api: 'data' })
    },
    action: (context, { path, parameters = null }) => {
      return ApiReusableVuexModule.actions.action(context, { path, parameters, api: 'data' })
    }
  }
}

export default queries