<template>
  <button type="button" class="btn btn-labeled btn-info refresher" @click="refreshNow">
    <action-icon class="btn-label">
      <refresh-icon :title="$t('layout.button.refresh-now')"></refresh-icon>
    </action-icon>
    <div class="btn-text" v-if="seconds > 0">
    {{ $t('layout.text.refreshing-data-in') }}
      <span class="transparent" v-if="countDown < 10">0</span>
      <span>{{ countDown }}</span>
      {{ $t('layout.text.refreshing-data-secs')}}
    </div>
    <div class="btn-text" v-else>
      {{ $t('layout.button.refresh-now') }}
    </div>
  </button>
</template>

<script>
import ActionIcon from './layout/ActionIcon'
import RefreshIcon from 'vue-material-design-icons/Refresh'

export default {
  name: 'Refresher',
  components: { RefreshIcon, ActionIcon },
  data () {
    return {
      countDown: 0,
      timer: undefined
    }
  },
  props: {
    seconds: {
      type: Number,
      default: 15
    },
    enabled: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    refreshNow () {
      this.clearTimer()
      this.countDown = this.seconds
      this.$emit('tick')
    },
    startTimer () {
      if (this.enabled && this.seconds === 0) {
        if (this.$canLog(3)) console.log('[Refresher] timer disabled')
        return
      }
      if (this.timer === undefined && this.enabled) {
        this.countDown = this.seconds
        this.timer = setInterval(() => {
          if (this.countDown === 0) {
            if (this.enabled) {
              this.refreshNow()
            }
          } else {
            this.countDown -= 1
          }
        }, 1000)
      }
    },
    clearTimer () {
      clearTimeout(this.timer)
      this.timer = undefined
      this.countDown = 0
    }
  },
  mounted () {
    this.startTimer()
  },
  beforeDestroy () {
    this.clearTimer()
  },
  watch: {
    enabled: {
      handler: function (value, oldValue) {
        // all'attivazione
        if (value && !oldValue) {
          this.startTimer()
        }
        if (!value && oldValue) {
          this.clearTimer()
          this.countDown = this.seconds
        }
      }
    },
    seconds: {
      handler: function (value, oldValue) {
        if (value !== oldValue && !this.enabled) {
          this.countDown = value
        }
      }
    },
    countDown: {
      handler: function (value, oldValue) {
        if (value !== oldValue && this.enabled) {
          this.$emit('countdown', value)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  button {
    &.btn {
      color: white;
      padding-top: 0;
      padding-bottom: 0;
      position: relative;

      display: flex;
      align-items: center;
    }

    .btn-label {
      position: relative;
      left: -.75rem;
      background: rgba(0, 0, 0, 0.15);
      color: white;
      /*line-height: 100%;*/

      &.action-icon {
        font-size: 1.5em;
        padding: 0 .3em;
      }
    }

    .transparent {
      opacity: 0;
    }
  }
</style>