// import { messages } from '@/lib/messages'
import Cookies from 'js-cookie'
import { idToken, isAuthenticated } from './helpers'
import { events } from '@/lib/messages'
import helpers from '../helpers'

export default ({ store, vuexNamespace, oauth2Cli }, to, from, next) => {
  return new Promise((resolve, reject) => {
    if (to.meta.isAuthCallback) {
      /* CALLBACK */
      console.log('[oAuth mdlware] callback')
      oauth2Cli.processCallback(to.fullPath)
        .then((authResult) => {
          if (helpers.canLog(4)) console.log('[oAuth mdlware] authresult', authResult)
          if (authResult !== false) {
            if (helpers.canLog(4)) console.log('[oAuth mdlware] storing auth infos')
            store.commit((vuexNamespace ? vuexNamespace + '/' : '') + 'setAuth', authResult.tokens)
            store.commit((vuexNamespace ? vuexNamespace + '/' : '') + 'setUser', authResult.user)
            events.$emit('logged-in')
            resolve(true)
          } else {
            if (helpers.canLog(3)) console.log('[oAuth mdlware] authresult false')
            Cookies.remove('requested_url')
            next({ name: 'home' })
            reject(new Error('Auth fail'))
          }
        })
        .catch((error) => {
          if (helpers.canLog(0)) console.error('[oAuth mdlware] processCallback rejected: ', error.message)
          Cookies.remove('requested_url')
          next({ name: 'home' })
          reject(new Error('callback not valid'))
        })
    } else {
      if (to.meta.isPublic) {
        if (helpers.canLog(4)) console.log('[oAuth mdlware] public path', to.fullPath)

        /* LOGOUT */
        if (to.name === 'logout') {
          let theIdToken = idToken()
          // let accessToken = store.getters['oauth2/access_token']
          store.commit((vuexNamespace ? vuexNamespace + '/' : '') + 'resetAuth')
          oauth2Cli.logout(theIdToken)
          if (helpers.canLog(2)) console.log('[oAuth mdlware] logout (should not see this?)')
          resolve(false)
        } else {
          /* END */
          if (to.name === 'endSession') {
            store.commit((vuexNamespace ? vuexNamespace + '/' : '') + 'resetAuth')
            events.$emit('logged-out')
            next({ name: 'home' })
            if (helpers.canLog(3)) console.log('[oAuth mdlware] logged out')
            resolve(true)
          } else {
            if (helpers.canLog(4)) console.log('[oAuth mdlware] ok, can proceed')
            resolve(true)
          }
        }
      } else {
        if (helpers.canLog(4)) console.log('[oAuth mdlware] protected path', to.fullPath)
        if (isAuthenticated()) {
          if (helpers.canLog(4)) console.log('[oAuth mdlware] User is authenticated, can proceed')
          Cookies.remove('requested_url')
          resolve(true)
        } else {
          /* LOGIN */
          if (helpers.canLog(3)) console.log('[oAuth mdlware] Not authenticated, storing requested url in cookie', to.fullPath)
          store.commit((vuexNamespace ? vuexNamespace + '/' : '') + 'resetAuth')
          Cookies.set('requested_url', to.fullPath)
          window.location = oauth2Cli.getAuthUrl()
          if (helpers.canLog(2)) console.log('[oAuth mdlware] login redirect (should not see this?)')
          reject(new Error('login redirect'))
        }
      }
    }
  })
}