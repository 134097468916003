<template>
  <li class="nav-item nav-dropdown">
    <a class="nav-link nav-dropdown-toggle" href="#" v-toggle-open>
      <settings-icon :title="$t('menu.system-configuration')"></settings-icon>
      {{ $t('menu.system-configuration') }}
    </a>
    <ul class="nav-dropdown-items">
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'dcs.machines.list'}">
          <robot-industrial-icon :title="$t('menu.machines')"></robot-industrial-icon>
          {{ $t('menu.machines')}}
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'dcs.drivers.list'}">
          <settings-box-icon :title="$t('menu.drivers')"></settings-box-icon>
          {{ $t('menu.drivers')}}
        </router-link>
      </li>
 <!--     <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'dcs.devices.list'}">
          <cellphone-link-icon :title="$t('menu.devices')"></cellphone-link-icon>
          {{ $t('menu.devices')}}
        </router-link>
      </li>-->
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'dcs.mappings.list'}">
          <compass-icon :title="$t('menu.mappings')"></compass-icon>
          {{ $t('menu.mappings')}}
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script>
import RobotIndustrialIcon from 'vue-material-design-icons/RobotIndustrial'
import SettingsIcon from 'vue-material-design-icons/Settings'
import SettingsBoxIcon from 'vue-material-design-icons/SettingsBox'
import CellphoneLinkIcon from 'vue-material-design-icons/CellphoneLink'
import CompassIcon from 'vue-material-design-icons/Compass'
import SidebarGroupToggler from '@/mixins/SidebarGroupToggler'

export default {
  name: 'SidebarGroupSystemConfiguration',
  components: {
    CompassIcon,
    CellphoneLinkIcon,
    SettingsBoxIcon,
    SettingsIcon,
    RobotIndustrialIcon
  },
  mixins: [ SidebarGroupToggler ]
}
</script>

<style>

</style>