// import { sidebarCssClasses, validBreakpoints, checkBreakpoint } from '@coreui/vue/src/shared/classes'
// import toggleClasses from '@coreui/vue/src/shared/toggle-classes'
import { events } from '@/lib/messages'
import Vue from 'vue'
import helpers from '@/lib/helpers'

export const Autohidesidebar = {
  handleClick: (e) => {
    if (helpers.canLog(3)) console.log('[Sidebar] emit hide-sidebar')
    events.$emit('hide-sidebar')
  },
  bind: (el) => {
    el.querySelectorAll('a:not(.nav-dropdown-toggle)').forEach((link) => {
      link.addEventListener('click', Autohidesidebar.handleClick)
    })
  },
  unbind: (el) => {
    el.querySelectorAll('a:not(.nav-dropdown-toggle)').forEach((link) => {
      link.removeEventListener('click', Autohidesidebar.handleClick)
    })
  }
}

Vue.directive('autohidesidebar', Autohidesidebar)