<template>
  <modal class="mappings-edit modal-edit"
         v-show="originalMapping.id !== null"
         :requestConfirmBeforeClose="hasModification"
         :confirmCloseMessage="$t('layout.edit.save-before-exit')"
         :return-route-name="'dcs.mappings.list'">
    <div slot="header">{{ $t('layout.edit.mappings-edit') }}: <span v-if="mainInfos.loaded">id #{{ this.originalMapping.id }} {{ $t('layout.edit.configuration')}}</span></div>

    <div slot="body">
      <form class="needs-validation"
            ref="editform"
            v-on:submit.prevent="() => { return false }"
            novalidate>
        <div class="">
          <div v-if="mainInfos.loaded && !mainInfos.error">
            <input-form-select :label="$t('layout.edit.local-entity-type')"
                               :tooltip="helpTextContent('mappings.edit.localEntity')"
                               :value="mainInfos.data.mappedEntityType"
                               :options="localEntityTypesAsOptions"
                               :loaded="localEntityTypesLoaded"
                               :required="true"
                               @changeValidity="checkDetailsEditorValidity($event)"
                               @selected="onLocalEntityTypeChanged">
            </input-form-select>
            <input-form-select :label="$t('layout.edit.local-entity-ref')"
                               :tooltip="helpTextContent('mappings.edit.localEntityRef')"
                               :value="localEntitiesLoaded ? mainInfos.data.localEntityId : null"
                               :options="localEntitiesAsOptions"
                               :loaded="true"
                               :read_only="!(mainInfos.data.mappedEntityType)"
                               :required="true"
                               @changeValidity="checkDetailsEditorValidity($event)"
                               @selected="(item) => { mainInfos.data.localEntityId = item.value }">
            </input-form-select>
            <input-form-select :label="$t('layout.edit.external-domain')"
                               :tooltip="helpTextContent('mappings.edit.externalDomain')"
                               :value="mainInfos.data.mappedExternalDomain"
                               :options="externalDomainsAsOptions"
                               :loaded="externalDomainsLoaded"
                               :required="true"
                               @changeValidity="checkDetailsEditorValidity($event)"
                               @selected="onMappedExternalDomainChanged">
            </input-form-select>
            <input-form-select :label="$t('layout.edit.external-entity-ref')"
                               :tooltip="helpTextContent('mappings.edit.externalEntity')"
                               :value="externalEntitiesLoaded ? mainInfos.data.externalEntityId : null"
                               :options="externalEntitiesAsOptions"
                               :loaded="true"
                               :read_only="!(mainInfos.data.mappedExternalDomain)"
                               :required="true"
                               @changeValidity="checkDetailsEditorValidity($event)"
                               @selected="(item) => { mainInfos.data.externalEntityId = item.value }">
            </input-form-select>
          </div>
          <form-row-message v-else
                            :text="!mainInfos.loaded ? $t('message.loading') : $t('message.error')"
                            :isError="mainInfos.error"></form-row-message>
        </div>
      </form>
    </div>

    <template slot="footer">
      <div class="col-md-2">
        <button type="button"
                @click.prevent="() => { if (isNew) { callNew(); } else { callSave() }}"
                :class="[
                'btn btn-outline-success btn-block text-capitalize',
                $hasPerms('dcs.mappings.actions.save') ? 'active' : 'disabled'
              ]">
          {{ $t('layout.button.save') }}
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Modal'
import { events } from '@/lib/messages'
import InputFormText from '@/components/forms/InputFormText'
import { apiConfig } from '@/config/api.config.js'
import helpers from '@/lib/helpers'
import InputFormSelect from '../../components/forms/InputFormSelect'
import FormRowMessage from '../../components/modal_edit/FormRowMessage'
import HelpTextsMixin from '../../mixins/HelpTextsMixin'

export default {
  name: 'MappingsEditModal',
  components: { FormRowMessage, InputFormSelect, InputFormText, Modal },
  mixins: [ HelpTextsMixin ],
  data () {
    return {
      helpers: helpers,
      mainInfos: {
        loaded: false,
        error: false,
        data: {
          localEntityId: null,
          externalEntityId: null
        }
      },
      originalMapping: {
        id: null,
        mappedEntityType: null,
        localEntityId: null,
        mappedExternalDomain: null,
        externalEntityId: null
      },
      localEntityTypesLoaded: false,
      localEntitiesLoaded: false,
      externalDomainsLoaded: false,
      externalEntitiesLoaded: false,
      id: null,
      isDetailsEditorValid: true,
      eventSystem: events,
      apiConfig: apiConfig
    }
  },
  props: {
  },
  computed: {
    ...mapGetters({
      localEntityTypes: 'mappings/allLocalEntityTypes',
      localEntities: 'mappings/allLocalEntities',
      externalDomains: 'mappings/allExternalDomains',
      externalEntities: 'mappings/allExternalEntities'
    }),
    localEntityTypesAsOptions () {
      if (this.localEntityTypes) {
        return [
          {
            value: '',
            label: '-',
            search: ''
          }
        ].concat(this.localEntityTypes.map((item) => {
          return {
            value: item.type,
            label: item.localizedName,
            search: item.localizedName
          }
        }))
      }
    },
    localEntitiesAsOptions () {
      if (this.localEntities) {
        return this.localEntities.map((item) => {
          return {
            value: item.id,
            label: item.name,
            search: item.name
          }
        })
      }
    },
    externalDomainsAsOptions () {
      if (this.externalDomains) {
        return [
          {
            value: '',
            label: '-',
            search: ''
          }
        ].concat(this.externalDomains.map((item) => {
          return {
            value: item,
            label: item,
            search: item
          }
        }))
      }
    },
    externalEntitiesAsOptions () {
      if (this.externalEntities) {
        return this.externalEntities.map((item) => {
          return {
            value: item.id,
            label: item.name,
            search: item.name
          }
        })
      }
    },
    currentMapping () {
      return {
        id: this.originalMapping.id,
        mappedEntityType: this.mainInfos.data ? this.mainInfos.data.mappedEntityType : null,
        localEntityId: this.mainInfos.data ? this.mainInfos.data.localEntityId : null,
        mappedExternalDomain: this.mainInfos.data ? this.mainInfos.data.mappedExternalDomain : null,
        externalEntityId: this.mainInfos.data ? this.mainInfos.data.externalEntityId : null
      }
    },
    hasModification () {
      return !(helpers.stripSpacesNewlines(this.currentMapping.mappedEntityType) === helpers.stripSpacesNewlines(this.originalMapping.mappedEntityType) &&
        helpers.stripSpacesNewlines(this.currentMapping.localEntityId) === helpers.stripSpacesNewlines(this.originalMapping.localEntityId) &&
        helpers.stripSpacesNewlines(this.currentMapping.mappedExternalDomain) === helpers.stripSpacesNewlines(this.originalMapping.mappedExternalDomain) &&
        helpers.stripSpacesNewlines(this.currentMapping.externalEntityId) === helpers.stripSpacesNewlines(this.originalMapping.externalEntityId))
    },
    mappedEntityType () {
      return this.currentMapping.mappedEntityType
    },
    mappedExternalDomain () {
      return this.currentMapping.mappedExternalDomain
    },
    isNew () {
      return this.originalMapping.id === 'new'
    },
    hasSomethingInError () {
      return this.mainInfos.error
    }
  },
  methods: {
    ...mapActions({
      loadMainInfos: 'mappings/get',
      _loadLocalEntityTypes: 'mappings/loadLocalEntityTypes',
      _loadLocalEntities: 'mappings/loadLocalEntities',
      _loadExternalDomains: 'mappings/loadExternalDomains',
      _loadExternalEntities: 'mappings/loadExternalEntities',
      save: 'mappings/save',
      new: 'mappings/new'
    }),
    onMappedExternalDomainChanged (item) {
      this.mainInfos.data.mappedExternalDomain = item.value
      this.mainInfos.data.externalEntityId = null
    },
    onLocalEntityTypeChanged (item) {
      this.mainInfos.data.mappedEntityType = item.value
      this.mainInfos.data.localEntityId = null
      this.mainInfos.data.externalEntityId = null
    },
    checkDetailsEditorValidity (e) {
      e.target.closest('form').classList.add('was-validated')
      this.isDetailsEditorValid = this.$el.querySelectorAll('form :invalid').length === 0
    },
    callNew () {
      if (this.hasSomethingInError) {
        this.$messages.error({ message: this.$t('message.cannot-save-data-error') })
        return false
      }
      if (!this.isDetailsEditorValid) {
        this.$messages.error({ message: this.$t('message.please-check-your-data') })
      } else {
        let mapping = this.currentMapping
        delete mapping.id
        if (this.$canLog(4)) console.log('[Mapping] new', mapping)
        this.new(mapping).then((data) => {
          this.$messages.success({ message: this.$t('message.success') })
        }).then(() => {
          this.eventSystem.$emit('refresh-page')
          this.$router.push({ name: 'dcs.mappings.list' })
        }).catch((e) => {
          // console.log(parameters)
          // this.$messages.error({
          //   title: this.$t('message.error'),
          //   message: (e.response && e.response.body) ? e.response.body : e.message
          // })
        })
      }
    },
    callSave () {
      if (this.hasSomethingInError) {
        this.$messages.error({ message: this.$t('message.cannot-save-data-error') })
        return false
      }
      if (!this.isDetailsEditorValid) {
        this.$messages.error({ message: this.$t('message.please-check-your-data') })
      } else {
        let mapping = this.currentMapping
        if (this.$canLog(4)) console.log('[Mapping] save', mapping)
        this.save(mapping).then((data) => {
          this.$messages.success({ message: this.$t('message.success') })
        }).then(() => {
          this.eventSystem.$emit('refresh-page')
          try {
            this.$broadcast().postMessage('MappingsList')
          } catch (e) {
            if (this.$canLog(0)) console.error(e.message)
          }
          this.$router.push({ name: 'dcs.mappings.list' })
        }).catch((e) => {
          // console.log(parameters)
          // this.$messages.error({ title: this.$t('message.error'), message: (e.response && e.response.body) ? e.response.body : e.message })
        })
      }
    },
    loadExternalEntities (entityType, externalDomain) {
      this.externalEntitiesLoaded = false
      this._loadExternalEntities({ entityType: entityType, externalDomain: externalDomain }).finally(() => {
        this.externalEntitiesLoaded = true
      })
    },
    loadExternalDomains () {
      this.externalDomainsLoaded = false
      return this._loadExternalDomains().finally(() => {
        this.externalDomainsLoaded = true
      })
    },
    loadLocalEntities (entityType) {
      this.localEntitiesLoaded = false
      if (this.$canLog(4)) console.log('local entities load ', entityType)
      // if (!entityType) console.trace()
      this._loadLocalEntities(entityType).finally(() => {
        this.localEntitiesLoaded = true
      })
    },
    loadLocalEntityTypes (entityType) {
      this.localEntityTypesLoaded = false
      return this._loadLocalEntityTypes(entityType).finally(() => {
        this.localEntityTypesLoaded = true
      })
    },
    loadMapping () {
      return this.loadMainInfos(this.$route.params.id).then((data) => {
        if (this.$canLog(4)) console.log('[Mapping Edit] main infos', data)
        this.mainInfos.data = helpers.smartJsonCheckParse(data)
        if (this.mainInfos.data !== undefined) {
          this.originalMapping.id = this.mainInfos.data.id
          this.originalMapping.mappedEntityType = this.mainInfos.data.mappedEntityType
          this.originalMapping.localEntityId = this.mainInfos.data.localEntityId
          this.originalMapping.mappedExternalDomain = this.mainInfos.data.mappedExternalDomain
          this.originalMapping.externalEntityId = this.mainInfos.data.externalEntityId
          if (this.$canLog(3)) console.log('[Mapping Edit] main infos ok')
          this.mainInfos.error = false
        } else {
          this.mainInfos.error = true
        }
      }).catch((e) => {
        this.mainInfos.error = true
      }).finally(() => {
        this.mainInfos.loaded = true
      })
    },
    createMapping () {
      this.mainInfos = {
        loaded: true,
        error: false,
        data: {
          id: '',
          mappedEntityType: '',
          localEntityId: '',
          mappedExternalDomain: '',
          externalEntityId: ''
        }
      }
    }
  },
  created () {
    this.originalMapping.id = this.$route.params.id
  },
  mounted () {
    this.mainInfos.data = null
    if (this.originalMapping.id !== null) {
      this.loadLocalEntityTypes().then(() => {
        this.loadExternalDomains().then(() => {
          if (this.originalMapping.id !== 'new') {
            this.loadMapping().then(() => {
              this.loadLocalEntities(this.mainInfos.data.mappedEntityType)
              this.loadExternalEntities(this.mainInfos.data.mappedEntityType, this.mainInfos.data.mappedExternalDomain)
            })
          } else {
            this.createMapping()
          }
        })
      })
    }
  },
  watch: {
    mappedEntityType: {
      handler: function (value, oldValue) {
        if (this.$canLog(2)) console.log('[Mappings Edit] watch mappedEntityType', value, oldValue)
        if (value !== oldValue && value) {
          this.loadLocalEntities(value)
          if (this.mappedExternalDomain) {
            this.loadExternalEntities(value, this.mappedExternalDomain)
          }
        }
      }
    },
    mappedExternalDomain: {
      handler: function (value, oldValue) {
        if (value !== oldValue && value) {
          this.loadExternalEntities(this.mappedEntityType, value)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-pane-content-if {
  .row {
    height: 100%;
  }
}

/*.h-auto-less-button {*/
/*  height: calc(100% - 1.5em - .375rem * 2 - 1em);*/
/*}*/

.h-button {
  height: calc(1.5em + .375rem * 2);
  margin-top: 1em;
}
</style>