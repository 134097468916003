<script>
import { Line } from 'vue-chartjs'
import zoom from 'chartjs-plugin-zoom'
import { hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import { events } from '@/lib/messages'

export default {
  name: 'TimeSerie',
  mixins: [ ],
  extends: Line,
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        // animation: {
        //   duration: 0
        // },
        title: {
          display: true,
          text: this.title
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              minUnit: 'minute'
            },
            // distribution: 'data',
            display: true
          }, {
            type: 'time',
            time: {
              minUnit: 'day'
            },
            ticks: {
              autoSkip: true,
              maxRotation: 0,
              minRotation: 0
            },
            scaleLabel: {
              display: true,
              labelString: this.xLabel
            }
            // distribution: 'data'
          }],
          yAxes: [{
            ticks: {
              min: 0
              //  max: 100
            },
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.yLabel
            }
          }]
        },
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'x'
            },
            zoom: {
              enabled: true,
              // drag: true,
              mode: 'x'
            }
          }
        }
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    points: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    xLabel: {
      type: String,
      required: true
    },
    yLabel: {
      type: String,
      required: true
    }
  },
  computed: {
    lastLabel () {
      return this.datasets.labels[this.datasets.labels.length - 1]
    },
    datasets () {
      if (this.points.length > 0) {
        let retObj = {
          labels: [],
          datasets: [
            {
              label: this.points.length > 0 ? this.$t('layout.chart.min') : this.$t('layout.chart.val'),
              data: [],
              borderColor: this.points.length > 0 ? '#DBE7F0' : '#20a8d8',
              fill: this.points.length === 1,
              lineTension: 0,
              pointRadius: this.points.length > 0 ? 0 : 3
            },
            {
              label: this.$t('layout.chart.avg'),
              data: [],
              borderColor: '#20a8d8',
              fill: false,
              lineTension: 0
            },
            {
              label: this.$t('layout.chart.max'),
              data: [],
              borderColor: '#DBE7F0',
              backgroundColor: hexToRgba('#DBE7F0'),
              fill: '-2',
              lineTension: 0,
              pointRadius: 0
            }
          ]
        }
        for (let point of this.points) {
          // eslint-disable-next-line
          retObj.labels.push(point.dateTime)
          retObj.datasets[0].data.push(point.values[0])
          if (this.points[0].values.length > 1) {
            retObj.datasets[1].data.push(point.values[1])
            retObj.datasets[2].data.push(point.values[2])
          }
        }
        if (this.points[0].values.length === 1) {
          delete retObj.datasets[1]
          delete retObj.datasets[2]
        }
        return retObj
      }
    }
  },
  methods: {
  },
  mounted () {
    this.addPlugin(zoom)
    this.renderChart(
      this.datasets,
      this.chartOptions
    )
    events.$on('resetZoom', (id) => {
      if (id === this.id) {
        this.$data._chart.resetZoom()
      }
    })
    events.$on('refresh-page', () => {
      // this.$data._chart.destroy();
      // this.renderChart(
      //   this.datasets,
      //   this.chartOptions
      // )
    })
  },
  watch: {
    lastLabel: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.$data._chart.destroy()
          this.renderChart(
            this.datasets,
            this.chartOptions
          )
        }
      }
    }
  }
}
</script>

<style scoped>

</style>