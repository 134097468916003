<template>
  <a href="#"
     :class="['action-icon', active ? '' : 'disabled']"
     @click.prevent="onClick">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'ActionIcon',
  props: {
    active: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onClick (e) {
      if (this.active) {
        this.$emit('click', e)
      } else {
        if (this.$canLog(2)) console.log('[ActionIcon] not allowed to perform this action')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  a {
    color: $black;
  }
</style>