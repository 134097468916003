<template>
  <div class="rows-quantity-select">
    <label class="filter-label text-capitalize">{{ $t('layout.filter.number-of-lines-pre') }}&nbsp;</label>
    <select class="form-control width-auto" v-model="rowsPerPage">
      <option v-for="rowsPerPageSelect in rowsPerPageList"
              v-bind:key="rowsPerPageSelect" :value="rowsPerPageSelect"
              :selected="rowsPerPageSelect === rowsPerPage">
        {{ rowsPerPageSelect }}
      </option>
    </select>
    <span>{{ $t('layout.filter.number-of-lines-post') }}</span>
  </div>
</template>

<script>
export default {
  name: 'RowsQuantitySelect',
  data () {
    return {
      rowsPerPage: 5
    }
  },
  props: {
    value: {
      type: Number,
      default: 5
    },
    rowsPerPageList: {
      type: Array,
      required: true
    }
  },
  mounted () {
    this.rowsPerPage = this.value
  },
  watch: {
    rowsPerPage: {
      handler: function (value) {
        this.$emit('change', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .rows-quantity-select {
    margin-top: 1em;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
  .width-auto {
    width: auto;
    display: inline-block;
  }
</style>