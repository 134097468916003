<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.8 21.67">
    <g id="b8a3b3f4-dc6f-4474-a4c6-6fd35d2ef174" data-name="Layer 2"><g id="e48e5211-ab64-4473-ab1c-5e3caac6fb96" data-name="Livello 1"><path class="e78f613c-d067-4a80-8d24-abb8608f552b" d="M11.05,21.64a.52.52,0,0,0,.32-.48V15.5H24.29A.51.51,0,0,0,24.8,15V6.72a.51.51,0,0,0-.51-.52H11.37V.52a.52.52,0,0,0-.88-.37L.15,10.46a.48.48,0,0,0-.15.36.51.51,0,0,0,.15.37L10.49,21.52A.51.51,0,0,0,11.05,21.64Z"/></g></g></svg>
</template>

<script>
export default {
  name: 'LeftArrowIcon',
  props: {
    title: {
      type: String,
      default: 'Basket icon'
    },
    decorative: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.e78f613c-d067-4a80-8d24-abb8608f552b{fill: $cerulean;}
</style>