<template>
  <div class="panelgrading-form">
    <div class="row">
      <div class="col-10">
        {{ $t('layout.edit.configuration-properties') }}
      </div>
      <div class="col-2 text-right">
        <action-icon v-if="fullyLoaded" class="add-action" @click="addRow">
          <plus-circle-icon></plus-circle-icon>
        </action-icon>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="text-center loading" v-show="!fullyLoaded">{{ $t('message.loading') }}</div>
        <div class="text-center loading text-error" v-show="parametersError">{{ $t('message.malformed-data') }}</div>
        <vuetable
          v-if="fullyLoaded"
          ref="vuetable"
          :css="tableStyle.table"
          :api-mode="false"
          :per-page="rowsPerPage"
          :fields="fieldsDefinition"
          :row-class="rowClass"
          :data-manager="dataManager"
          :displayEmptyDataRow="true"
          pagination-path="pagination"
          @vuetable:loading="vuetableloading = true"
          @vuetable:loaded="vuetableloading = false"
          @vuetable:pagination-data="onPaginationData">
          <div slot="powerbin-slot"
               slot-scope="props"
               @dblclick="(e) => { beginEdit(e, props.rowIndex) }"
               @keyup="finishEdit">
            <span class="mobile-caption d-block d-md-none">PowerBin</span>
            <span class="on-display">{{ props.rowData.Bins.PowerBin }}</span>
            <input class="form-control on-edit"
                   type="text"
                   name="powerbin"
                   v-model="props.rowData.Bins.PowerBin"/>
          </div>
          <div slot="currentbin-slot"
               slot-scope="props"
               @dblclick="(e) => { beginEdit(e, props.rowIndex) }"
               @keyup="finishEdit">
            <span class="mobile-caption d-block d-md-none">CurrentBin</span>
            <span class="on-display">{{ props.rowData.Bins.CurrentBin }}</span>
            <input class="form-control on-edit" type="text" name="currentbin" v-model="props.rowData.Bins.CurrentBin" />
          </div>
          <div slot="qualitybin-slot"
               slot-scope="props"
               @dblclick="(e) => { beginEdit(e, props.rowIndex) }"
               @keyup="finishEdit">
            <span class="mobile-caption d-block d-md-none">QualityBin</span>
            <span class="on-display">{{ props.rowData.Bins.QualityBin }}</span>
            <input class="form-control on-edit" type="text" name="qualitybin" v-model="props.rowData.Bins.QualityBin" />
          </div>
          <div slot="articlecode-slot"
               slot-scope="props"
               @dblclick="(e) => { beginEdit(e, props.rowIndex) }"
               @focus="beginEdit"
               @blur="finishEdit"
               @keyup="finishEdit">
            <span class="mobile-caption d-block d-md-none">ArticleId</span>
            <span class="on-display" v-html="getArticleLabel(props.rowData.ArticleId)"></span>
            <input-select :currentValue="props.rowData.ArticleId"
                          :options="articlesAsOptionsforSelect"
                          class="on-edit"
                          @selected="(item) => { props.rowData.ArticleId = item.value }"></input-select>
          </div>
          <template slot="actions-slot" slot-scope="props">
            <action-icon
                  class="row-action action-edit on-display"
                  :active="$hasPerms('dcs.services.configuration.edit')"
                  @click.native="(e) => { beginEdit(e, props.rowIndex) }">
              <pencil-circle-icon :title="$t('layout.button.edit')"></pencil-circle-icon>
            </action-icon>
            <action-icon
                  class="row-action action-finish-edit on-edit"
                  :active="$hasPerms('dcs.services.configuration.delete')"
                  @click="finishEdit">
              <check-circle-icon :title="$t('layout.button.confirm')"></check-circle-icon>
            </action-icon>
            <action-icon
                  class="row-action action-delete"
                  :active="$hasPerms('dcs.services.configuration.delete')"
                  @click="removeRow(props.rowIndex, props.rowData)">
              <minus-circle-icon :title="$t('layout.button.delete')"></minus-circle-icon>
            </action-icon>

          </template>
        </vuetable>
      </div>
    </div>
    <div class="row paging">
      <div class="col-md-12">
        <vuetable-pagination-custom v-if="rowsPerPage > 0"
                                    ref="pagination"
                                    :css="tableStyle.pagination"
                                    @vuetable-pagination:change-page="onChangePage">
        </vuetable-pagination-custom>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetableRowHeaderCustom from '@/components/VuetableRowHeaderCustom'
import VuetablePaginationCustom from '@/components/VuetablePaginationCustom'
import VuetableMixin from '@/mixins/VuetableMixin'
import ActionIcon from '../layout/ActionIcon'
import { events } from '@/lib/messages'
import { mapGetters, mapActions } from 'vuex'
import { pullAt } from 'lodash'
import ArticleSelect from './ArticleSelect'
import PencilCircleIcon from 'vue-material-design-icons/PencilCircle'
import CheckCircleIcon from 'vue-material-design-icons/CheckCircle'
import MinusCircleIcon from 'vue-material-design-icons/MinusCircle'
import PlusCircleIcon from 'vue-material-design-icons/PlusCircle'
import InputSelect from './InputSelect'

export default {
  name: 'PanelGrading',
  components: {
    InputSelect,
    PlusCircleIcon,
    MinusCircleIcon,
    CheckCircleIcon,
    PencilCircleIcon,
    ArticleSelect,
    VuetablePaginationCustom,
    ActionIcon,
    VuetableRowHeaderCustom,
    Vuetable
  },
  mixins: [ VuetableMixin ],
  props: {
    parameters: {
      validator: function (val) {
        return val === null || typeof val === 'string'
      }
    },
    isLoaded: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loaded: false,
      articlesLoaded: false,
      eventSystem: events,
      editing: false,
      parametersError: false,
      data: [],
      dataRowTemplate: {
        Bins: {
          PowerBin: '*',
          CurrentBin: '*',
          QualityBin: '*'
        },
        ArticleId: 0
      },
      fieldsDefinition: [
        {
          name: 'powerbin-slot',
          title: 'PowerBin',
          dataClass: 'align-middle'

        },
        {
          name: 'currentbin-slot',
          title: 'CurrentBin',
          dataClass: 'align-middle'
        },
        {
          name: 'qualitybin-slot',
          title: 'QualityBin',
          dataClass: 'align-middle'
        },
        {
          name: 'articlecode-slot',
          title: 'ArticleCode',
          dataClass: 'align-middle md-30-perc'
        },
        {
          name: 'actions-slot',
          title: '',
          titleClass: 'center aligned',
          dataClass: 'text-center p-0 row-actions md-10-perc'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      articles: 'articles/all',
      oneArticleById: 'articles/oneById'
    }),
    fullyLoaded () {
      return this.loaded && this.articlesLoaded
    },
    articlesAsOptionsforSelect () {
      if (this.articles === null) {
        return []
      }
      return this.articles.map((itm) => {
        return {
          value: itm.id,
          label: '[' + itm.code + '] ' + itm.description,
          search: itm.code + ' ' + itm.description
        }
      })
    }
  },
  methods: {
    ...mapActions({
      loadArticles: 'articles/load'
    }),
    getArticleLabel (id) {
      let itm = this.oneArticleById(id)
      if (itm) {
        return '[' + itm.code + '] ' + itm.description
      } else {
        this.$emit('changeValidity', false)
        return '<span class="text-error">' + this.$t('layout.edit.article-id-not-found') + ': ' + id + '</span>'
      }
    },
    validateParameters (value) {
      let parmObj = JSON.parse(value)
      if (this.$canLog(2)) console.log('[PanelGrading] validating JSON', parmObj)
      if (parmObj === null) {
        console.log('[PanelGrading] missing parameters obj (or malformed json)', parmObj)
        this.parametersError = false
        this.data = []
        if (this.$refs.vuetable) {
          if (this.$refs.vuetable) this.$refs.vuetable.reload()
        }
        return true
      }
      if (!parmObj.hasOwnProperty('Options')) {
        console.log('[PanelGrading] missing Options', parmObj)
        this.parametersError = true
        return false
      }
      parmObj = parmObj.Options
      for (let parm in parmObj) {
        if (!parmObj[parm].hasOwnProperty('ArticleId') || !parmObj[parm].hasOwnProperty('Bins')) {
          console.log('[PanelGrading] missing ArticleId or Bins', parmObj)
          this.parametersError = true
          this.data = []
          return false
        }
        let deepObj = parmObj[parm].Bins
        if (!deepObj.hasOwnProperty('PowerBin') || !deepObj.hasOwnProperty('CurrentBin') || !deepObj.hasOwnProperty('QualityBin')) {
          console.log('[PanelGrading] missing Bins', deepObj)
          this.parametersError = true
          this.data = []
          return false
        }
      }
      this.parametersError = false
      this.data = parmObj
      if (this._isMounted && this.$refs.vuetable) {
        if (this.$refs.vuetable) this.$refs.vuetable.reload()
      }
      console.log('[PanelGrading] json validated')
      return true
    },
    beginEdit (event, rowIndex) {
      this.editing = rowIndex
      if (this.$refs.vuetable) this.$refs.vuetable.reload()
      console.log(event)
      let input = event.target.parentNode.querySelector('.form-control')
      if (input) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            input.focus()
          })
        })
      }
    },
    finishEdit (event) {
      if (event && event.type === 'keyup') {
        if (event.keyCode !== 13) {
          return false
        }
      }
      // let editingRows = this.$refs.vuetable.$el.querySelectorAll('tr.editing')
      // for (let i = 0; i < editingRows.length; i++) {
      //   editingRows[i].classList.remove('editing')
      // }
      this.editing = false
      console.log('refresg')
      if (this.$refs.vuetable) this.$refs.vuetable.refresh()
      let isValid = this.rebuildJson()
      if (isValid !== false) {
        console.log('[PanelGrading] updateCode ', isValid)
        this.$emit('updateCode', isValid)
      } else {
        console.log('[PanelGrading] data is not valid')
      }
      this.$emit('changeValidity', isValid !== false)
    },
    rebuildJson () {
      try {
        let parameters = {
          Options: this.data
        }
        return JSON.stringify(parameters)
        // return parameters
      } catch (e) {
        if (this.$canLog(0)) console.error('[PanelGrading]', e, this.data)
        return false
      }
    },
    addRow () {
      this.data.push(this.dataRowTemplate)
      this.parametersError = false
      this.pendingoperation = 'gotoLastPage'
      this.finishEdit()
    },
    gotoLastPage () {
      if (this.$refs.pagination) {
        this.$refs.vuetable.changePage(this.$refs.pagination.lastPage)
      }
    },
    leaveAtPageOrGoLast () {
      if (this.$refs.pagination) {
        let page = this.$refs.vuetable.currentPage
        let lastPage = this.$refs.pagination.lastPage
        this.$refs.vuetable.changePage(lastPage > page ? page : lastPage)
      }
    },
    removeRow (rowIndex, rowData) {
      let page = this.$refs.vuetable.currentPage
      let itemIndex = rowIndex + ((page - 1) * this.rowsPerPage)
      // console.log(rowIndex, page, rowIndex + ((page - 1) * this.rowsPerPage))
      console.log('[PanelGrading] deleting item #' + itemIndex)
      pullAt(this.data, itemIndex)
      this.pendingoperation = 'leaveAtPageOrGoLast'
      this.finishEdit()
      console.log(this.data)
    },
    rowClass (dataItem, index) {
      return (this.editing !== false && this.editing === index) ? 'editing' : ''
    },
    reload () {}
  },
  mounted () {
    this.loadArticles().finally(() => {
      this.articlesLoaded = true
    })
  },
  beforeDestroy () {
  },
  watch: {
    isLoaded: {
      handler: function (value) {
        this.loaded = value
      },
      immediate: true
    },
    parameters: {
      handler: function (value, oldValue) {
        if (this.$canLog(4)) console.log('prop changed')
        this.$emit('changeValidity', this.validateParameters(value))
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.panelgrading-form {
  .add-action {
    font-size: 2.2em;
    margin-right: .5em;
    cursor: pointer;
  }

  .vuetable-body {
    tr {
      td {
        position: relative;

        .text-error {
          color: $red;
        }

        &.row-actions {

          @include media-breakpoint-up(md) {
            width: 8em;
          }

          .row-action {
            font-size: 2.2em;
            margin-right: .5em;
            cursor: pointer;

            &:last-of-type {
              margin-right: 0;
            }

            &.on-display {
              display: inline-block;
            }
            &.on-edit {
              display: none;
            }

            &.action-finish-edit {
              color: $green;
              span {
                border-bottom: 4px solid;
              }
            }

            &.action-delete {
              color: $red;
            }
          }
        }
      }

      .on-edit {
        display: none;
      }

      .on-display {
        display: block;
      }

      &.editing {
        background-color: $light-grey;

        .on-edit {
          display: block;
        }
        .on-display {
          display: none;
        }

        td {
          &.row-actions {
            .row-action {

              &.on-edit {
                display: inline-block;
              }
              &.on-display {
                display: none;
              }

            }
          }
        }
      }
    }
  }

  .pagination {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;

    .page-link {
      border: 0;

      &:hover {
        background-color: $botticelli;
      }
    }

    .page-item {
      border: 0;
      display: flex;
      align-items: center;
      padding: 0 0.75rem;

      flex-grow:1;
      flex-basis: 0;
      cursor: pointer;

      &:hover {
        background-color: $botticelli;
      }
    }
  }
}
</style>