<template>
  <div class="component-logs-operations">
    <div v-if="loaded">
      <div class="row">
        <div class="offset-8 col-4">
          <refresher class="float-right"
                     :seconds="autoRefresh('MachineOperations')"
                     :enabled="enableTimer"
                     @tick="loadData"></refresher>
        </div>
      </div>
      <div class="row detail-row">
        <div class="col-md-6 operations">
          <div class="card">
            <div class="card-header text-uppercase">
              <div>{{ $t('layout.details.operations') }}</div>
            </div>
            <div class="card-body list" v-if="operations.length > 0">
              <p class="operation-entry" v-for="(operation, key) in operations" v-bind:key="key">{{ operation }}</p>
            </div>
            <div class="card-body list" v-else>
              <p class="operation-entry">{{ $t('layout.list.no-data') }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 logs">
          <div class="card">
            <div class="card-header text-uppercase">
              <div>{{ $t('layout.details.logs') }}</div>
            </div>
            <div class="card-body list" v-if="logs.length">
              <p class="log-entry" v-for="(log, key) in logs" v-bind:key="key">{{ log }}</p>
            </div>
            <div class="card-body list" v-else>
              <p class="operation-entry">{{ $t('layout.list.no-data') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12 text-center">
        <p class="loading">{{ $t('layout.text.loading') }}...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Refresher from '@/components/Refresher'
import { apiConfig } from '@/config/api.config.js'

export default {
  name: 'logsOperations',
  components: { Refresher },
  data () {
    return {
      operations: [],
      logs: [],
      loaded: false,
      enableTimer: false,
      apiConfig: apiConfig
    }
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      autoRefresh: 'settings/tableAutoRefresh'
    })
  },
  methods: {
    ...mapActions({
      getInfos: 'machines/info'
    }),
    loadData () {
      this.enableTimer = false
      this.getInfos(this.id).then((data) => {
        let parsedData = JSON.parse(data)
        this.operations = parsedData.operations
        this.logs = parsedData.logs
      }).finally(() => {
        this.$emit('loaded')
        this.enableTimer = true
        this.loaded = true
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
  .loading {
    margin: 3em 0;
  }

  .card-body {
    height: 10em;
    padding: 5px;

    p {
      margin-bottom: 10px;
    }
  }

  .operations {
    .card-header {
      background-color: $heater;
    }
  }

  .logs {
    .card-header {
      background-color: $dark-grey;
    }
  }

  .list {
    overflow-y: scroll;
  }

  /deep/ .refresher {
    margin-bottom: .3rem;
  }

</style>