import BroadcastChannel from 'broadcast-channel'

const broadcast = {
  sender: null,
  receiver: null,
  channel: null,
  install (Vue, options) {
    // this.channel = new BroadcastChannel('dcs-client')
    Vue.prototype.$broadcast = this.getChannel
  },
  getChannel () {
    console.warn(this.channel)
    if (!this.channel) {
      this.channel = new BroadcastChannel('dcs-client')
    }
    return this.channel
  }
}
export default broadcast