import ApiReusableVuexModule from '@/lib/api/ApiReusableVuexModule'

const datachecks = {
  namespaced: true,
  state: () => {
    return {
      list: null,
      last_update: null
    }
  },
  getters: {
    one: (state) => (index) => { return ApiReusableVuexModule.getters.one(state)(index) },
    all: (state) => { return ApiReusableVuexModule.getters.all(state) },
    lastUpdate: (state) => { return ApiReusableVuexModule.getters.lastUpdate(state) }
  },
  mutations: {
    ...ApiReusableVuexModule.mutations
  },
  actions: {
    loadByPanel: (context, serialNumber, force = false) => {
      return ApiReusableVuexModule.actions.action(context, {
        path: 'DataChecks.DataChecks_OnPanel',
        parameters: { serialNumber: serialNumber },
        force: force
      }).then((data) => {
        let decodedData = JSON.parse(data)
        context.commit('add', decodedData)
      })
    },
    loadByProductionOrder: (context, code, force = false) => {
      return ApiReusableVuexModule.actions.action(context, {
        path: 'DataChecks.DataChecks_OnProductionOrder',
        parameters: { code: code },
        force: force
      }).then((data) => {
        let decodedData = JSON.parse(data)
        context.commit('add', decodedData)
      })
    }
  }
}

export default datachecks