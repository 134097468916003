<template>
  <div class="pagination">
    <a :class="['btn-nav page-link', currentPage === 1 || numResult === 0 ? 'disabled' : '']"
       @click="changeCurrentPage(1)">
      <skip-backward-icon></skip-backward-icon>
    </a>
    <a :class="['btn-nav page-link', currentPage === 1 || numResult === 0 ? 'disabled' : '']"
       @click="changeCurrentPage(currentPage-1)">
      <skip-previous-icon></skip-previous-icon>
    </a>

    <!--<a class="page-item disabled" v-if="currentPage - pagesToDisplayBeforeAndAfter - 1 > 0">...</a>-->
    <a v-for="n in howManyNumbersToShow"
       v-bind:key="n"
       :data-pageid="n + startNumber - 1"
       :class="['page-item', currentPage === (n + startNumber - 1) ? 'active' : '']"
       @click="changeCurrentPage(n + startNumber - 1)">
      {{ numberToDisplay(n) }}
    </a>
    <!--<a class="page-item disabled" v-if="currentPage + pagesToDisplayBeforeAndAfter < numPages">...</a>-->

    <a :class="['btn-nav page-link', currentPage === numPages || numResult === 0 ? 'disabled' : '']"
       @click="changeCurrentPage(currentPage + 1)">
      <skip-next-icon></skip-next-icon>
    </a>
    <a :class="['btn-nav page-link', currentPage === numPages || numResult === 0 ? 'disabled' : '']"
       @click="changeCurrentPage(numPages)">
      <skip-forward-icon></skip-forward-icon>
    </a>
  </div>
</template>

<script>
import SkipBackwardIcon from 'vue-material-design-icons/SkipBackward'
import SkipPreviousIcon from 'vue-material-design-icons/SkipPrevious'
import SkipForwardIcon from 'vue-material-design-icons/SkipForward'
import SkipNextIcon from 'vue-material-design-icons/SkipNext'
export default {
  name: 'QueryTablePaging',
  components: { SkipNextIcon, SkipForwardIcon, SkipPreviousIcon, SkipBackwardIcon },
  data () {
    return {
      pagesToDisplayBeforeAndAfter: 3,
      currentPage: 1
    }
  },
  props: {
    rowsPerPage: {
      type: Number,
      required: true
    },
    numResult: {
      type: Number,
      required: true
    }
  },
  computed: {
    numPages () {
      return Math.ceil(this.numResult / this.rowsPerPage)
    },
    startNumber () {
      if (this.currentPage - this.pagesToDisplayBeforeAndAfter < this.numPages - this.howManyNumbersToShow + 1) {
        return Math.max(1, this.currentPage - this.pagesToDisplayBeforeAndAfter)
      }
      return this.numPages - this.howManyNumbersToShow + 1
    },
    endNumber () {
      return Math.min(this.numPages, this.currentPage + this.pagesToDisplayBeforeAndAfter)
    },
    howManyNumbersToShow () {
      return Math.min(this.numPages, this.pagesToDisplayBeforeAndAfter * 2 + 1)
      // return Math.min(this.endNumber - this.startNumber + 1, this.pagesToDisplayBeforeAndAfter * 2 + 1)
    }
  },
  methods: {
    numberToDisplay (n) {
      if (n === 1 && this.currentPage - this.pagesToDisplayBeforeAndAfter > 1) {
        return '...'
      }
      if (n === this.howManyNumbersToShow && this.currentPage + this.pagesToDisplayBeforeAndAfter < this.numPages) {
        return '...'
      }
      return n + this.startNumber - 1
    },
    changeCurrentPage (page) {
      if (page >= 1 && page <= this.numPages) {
        this.currentPage = page
        this.$emit('change-page', page)
      }
    }
  },
  watch: {
    rowsPerPage: {
      handler: function () {
        this.currentPage = 1
      }
    },
    numResult: {
      handler: function () {
        this.currentPage = 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .pagination {
    a.disabled {
      color: $dark-grey;

      &:hover {
        color: $dark-grey;
        background-color: transparent;
      }
    }
  }
</style>