<template>
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.67 24.81">
      <title></title><g id="a5a5e6c8-f41a-4750-a79c-f485d4788544" data-name="Layer 2"><g id="bba90026-bf68-409d-ad27-f00abcedc727" data-name="Livello 1"><path class="e0bde2d1-9de8-4c96-8fb0-e7f073983af2" d="M0,11a.53.53,0,0,0,.48.32H6.18l0,12.91a.52.52,0,0,0,.51.52h8.27a.51.51,0,0,0,.51-.52l0-12.91h5.68a.54.54,0,0,0,.48-.32.51.51,0,0,0-.12-.56L11.24.15A.56.56,0,0,0,10.87,0a.51.51,0,0,0-.37.15L.15,10.47A.52.52,0,0,0,0,11Z"/></g></g></svg>
</template>

<script>
export default {
  name: 'TopArrowIcon',
  props: {
    title: {
      type: String,
      default: 'Basket icon'
    },
    decorative: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.e0bde2d1-9de8-4c96-8fb0-e7f073983af2{fill: $gold;}
</style>