const messages = {
  namespaced: true,
  state: [],
  getters: {
    one: (state) => (index) => {
      return state[index]
    },
    oneByMessage: (state) => (level, text) => {
      return state.filter((item) => {
        return item.level === level && item.text === text
      }).length
    },
    all: (state) => {
      return state
    },
    hasMessages: (state) => {
      return state.length
    }
  },
  mutations: {
    reset (state) {
      state = []
    },
    removeMessage (state, { index }) {
      state = state.splice(index, 1)
    },
    addMessage (state, { level, text, title = null }) {
      state.push({ level: level, text: text, title: title })
    }
  }
}

export default messages