<template>
  <button class="navbar-toggler sidebar-toggler" type="button" data-toggle="sidebar-lg-show" @click="sidebarToggle">
    <backburger-icon v-if="status" :title="$t('menu.toggle-sidebar')"></backburger-icon>
    <menu-icon v-else :title="$t('menu.toggle-sidebar')"></menu-icon>
  </button>
</template>

<script>
import { sidebarCssClasses, validBreakpoints, checkBreakpoint } from '@coreui/vue/src/shared/classes'
import toggleClasses from '@coreui/vue/src/shared/toggle-classes'
import MenuIcon from 'vue-material-design-icons/Menu'
import BackburgerIcon from 'vue-material-design-icons/Backburger'
import { events } from '@/lib/messages'

export default {
  name: 'SidebarToggler',
  components: { BackburgerIcon, MenuIcon },
  data () {
    return {
      status: true
    }
  },
  props: {
    defaultOpen: {
      type: Boolean,
      default: true
    },
    display: {
      type: String,
      default: 'lg'
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classList () {
      return [
        'navbar-toggler'
      ]
    }
  },
  mounted () {
    this.toggle(this.defaultOpen)
    events.$on('hide-sidebar', () => {
      if (this.$canLog(3)) console.log('[SidebarToggler] invoked hide sidebar')
      this.toggle()
    })
  },
  methods: {
    toggle (force) {
      const [display, mobile] = [this.display, this.mobile]
      let cssClass = sidebarCssClasses[0]
      if (!mobile && display && checkBreakpoint(display, validBreakpoints)) {
        // cssClass = `sidebar-${display}-show`
        cssClass = 'sidebar-lg-show'
      }
      if (this.$canLog(3)) console.log('[SidebarToggler]', checkBreakpoint(display, validBreakpoints), document.body.classList, cssClass)
      toggleClasses(cssClass, sidebarCssClasses, force)
      // document.body.classList.toggle(cssClass)
      this.status = document.body.classList.contains('sidebar-lg-show')
    },
    sidebarToggle (e) {
      e.preventDefault()
      e.stopPropagation()
      this.toggle()
    }
  }
}

</script>

<style lang="scss">
.sidebar-toggler {
  &:focus {
    outline:none;
  }
}

</style>