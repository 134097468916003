import ApiReusableVuexModule from '@/lib/api/ApiReusableVuexModule'
// import { anomaliesActions } from '@/lib/bitactions'
// import { invert } from 'lodash'

const anomalies = {
  namespaced: true,
  state: () => {
    return {
      filters: null,
      list: null,
      last_update: null,
      anomaliesTypes: null,
      anomaliesActions: null
    }
  },
  getters: {
    one: (state) => (index) => { return ApiReusableVuexModule.getters.one(state)(index) },
    all: (state) => state.list ? state.list.rows.map((item) => {
      return {
        ...item,
        anomalyType: item.type.localizedName
      }
    }) : [],
    lastUpdate: (state) => { return ApiReusableVuexModule.getters.lastUpdate(state) },
    totalItems: (state) => state.list ? state.list.pagingHeader.totalItemsCount : 0,
    totalPages: (state) => state.list ? state.list.pagingHeader.totalPages : 0,
    pageIndex: (state) => state.list ? state.list.pagingHeader.pageIndex : 1,
    pageSize: (state) => state.list ? state.list.pagingHeader.pageSize : 15,
    filters: (state) => state.filters,
    allTypes: (state) => {
      return state.anomaliesTypes
    },
    allActions: (state) => {
      return state.anomaliesActions
    }
  },
  mutations: { ...ApiReusableVuexModule.mutations,
    ...{
      addTypes (state, payload) {
        state.anomaliesTypes = payload
      },
      addActions (state, payload) {
        state.anomaliesActions = payload
      },
      setFilters (state, payload) {
        state.filters = payload
      }
    }
  },
  actions: {
    // metodo richiesto dal poller
    load: (context) => {
      return context.dispatch('loadWithParms', {
        filters: context.getters.filters,
        pageIndex: context.getters.pageIndex,
        pageSize: context.getters.pageSize
      })
    },
    loadWithParms: (context, { filters, pageIndex, pageSize, sortColumn, sortDirection }) => {
      context.commit('setFilters', filters)
      return ApiReusableVuexModule.actions.action(context, {
        path: 'Anomalies.Anomalies_List',
        parameters: {
          PageIndex: pageIndex,
          PageSize: pageSize,
          IsSortDescending: sortDirection === 'DESC',
          SortFieldKey: sortColumn,
          ...filters
        }
      }).then((data) => {
        let parsed = JSON.parse(data)
        context.commit('add', parsed)
      })
    },
    loadAnomaly: (context, id) => {
      return ApiReusableVuexModule.actions.action(context, { path: 'Anomalies.Anomalies_Get', parameters: { id: id } })
    },
    loadTypes: (context, force = false) => {
      if (context.state.serviceTypes && context.state.serviceTypes.length > 0 && !force) {
        return new Promise((resolve) => {
          resolve(context.state.serviceTypes)
        })
      }
      return ApiReusableVuexModule.actions.load(context, { path: 'Anomalies.Anomalies_AnomalyTypeList', method: 'addTypes' })
    },
    loadActions: (context, force = false) => {
      if (context.state.serviceTypes && context.state.serviceTypes.length > 0 && !force) {
        return new Promise((resolve) => {
          resolve(context.state.serviceTypes)
        })
      }
      return ApiReusableVuexModule.actions.load(context, { path: 'Anomalies.Anomalies_AnomalyActionList', method: 'addActions' })
    },
    handle: (context, { ids, action }) => {
      return ApiReusableVuexModule.actions.action(context, {
        path: 'Anomalies.Anomalies_Handle',
        parameters: {
          ids: ids, action: action
        }
      })
    }
  }
}

export default anomalies