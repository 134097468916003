<template>
  <div v-show="tablePagination && lastPage > firstPage" :class="$_css.wrapperClass">
    <a @click="loadPage(firstPage)"
       :class="['btn-nav', $_css.linkClass, isOnFirstPage ? $_css.disabledClass : '']">
      <skip-backward-icon></skip-backward-icon>
    </a>
    <a @click="loadPage('prev')"
       :class="['btn-nav', $_css.linkClass, isOnFirstPage ? $_css.disabledClass : '']">
      <skip-previous-icon></skip-previous-icon>
    </a>
    <template v-if="notEnoughPages">
      <template v-for="(n, i) in totalPage">
        <a @click="loadPage(i+firstPage)" :key="i"
           :class="[$_css.pageClass, isCurrentPage(i+firstPage) ? $_css.activeClass : '']"
           v-html="n">
        </a>
      </template>
    </template>
    <template v-else>
      <template v-for="(n, i) in windowSize">
        <a @click="loadPage(windowStart+i+firstPage-1)" :key="i"
           :class="[$_css.pageClass, isCurrentPage(windowStart+i+firstPage-1) ? $_css.activeClass : '']"
           v-html="windowStart+n-1">
        </a>
      </template>
    </template>
    <a @click="loadPage('next')"
       :class="['btn-nav', $_css.linkClass, isOnLastPage ? $_css.disabledClass : '']">
      <skip-next-icon></skip-next-icon>
    </a>
    <a @click="loadPage(lastPage)"
       :class="['btn-nav', $_css.linkClass, isOnLastPage ? $_css.disabledClass : '']">
      <skip-forward-icon></skip-forward-icon>
    </a>
  </div>
</template>

<script>
import PaginationMixin from 'vuetable-2/src/components/VuetablePaginationMixin.vue'
import SkipBackwardIcon from 'vue-material-design-icons/SkipBackward'
import SkipPreviousIcon from 'vue-material-design-icons/SkipPrevious'
import SkipNextIcon from 'vue-material-design-icons/SkipNext'
import SkipForwardIcon from 'vue-material-design-icons/SkipForward'

export default {
  components: { SkipForwardIcon, SkipNextIcon, SkipPreviousIcon, SkipBackwardIcon },
  mixins: [PaginationMixin]
}
</script>
<style lang="scss">
  .vuetable-pagination {
    background: #f9fafb !important;
  }

  .pagination {
    margin-top: 1em;

    .page-item {
      &.active {
        /*text-decoration: underline;*/
        background-color: $light-grey;
        border-radius: 5px;
      }
    }
  }

  .page-link, .page-item {
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*border-width: 1px 0 1px 0;*/
    /*border-style: solid;*/
    /*border-color: #c8ced3;*/
    /*padding: 0.5rem 0.75rem;*/
  }
</style>
