import { events } from '@/lib/messages'
import helpers from '@/lib/helpers'

const CRUDListMixin = {
  data () {
    return {
      wantsToDelete: false,
      showDetails: null
    }
  },
  computed: {
    selectedIds () {
      if (this.data) {
        let ids = this.data.filter((elm) => {
          return this.selectedRows.indexOf(elm.id) >= 0
        })
          .map((elm) => {
            return elm.id
          })
        if (helpers.canLog(3)) console.log('[' + this.$options.name + '] selectedIds', ids)
        return ids
      }
      return []
    }
  },
  methods: {
    callDelete () {
      let calls = []
      let numTodelete = this.selectedIds.length
      let successes = 0
      for (let i of this.selectedIds) {
        calls.push(new Promise((resolve, reject) => {
          this.delete(i)
            .then(() => {
              if (numTodelete === 1) {
                this.$messages.success({ message: this.$t('message.success-deleted-item').replace('%id%', i) })
              }
              successes++
              resolve()
            })
            .catch((e) => {
              this.$messages.error({
                title: this.$t('message.error'),
                message: (e.response && e.response.body) ? e.response.body : e.message
              })
              reject(e)
            })
        }))
      }
      Promise.all(calls)
        .then(() => {
          this.loaded = false
          this.reload(true)
        })
        .catch((e) => {
          this.loaded = true
        })
        .finally(() => {
          if (numTodelete > 1 && successes > 0) {
            this.$messages.success({ message: this.$t('message.success-deleted-items').replace('%n%', successes) })
          }
          this.loaded = true
        })
    },
    reload (force = false) {
      return new Promise((resolve, reject) => {
        this.load(force)
          .then(() => {
            if (helpers.canLog(3)) console.log('[' + this.$options.name + '] load solved')
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            this.loaded = true
          })
      })
    }
  },
  mounted () {
    if (helpers.canLog(2)) console.log('[' + this.$options.name + '] mounted')
    this.loaded = false
    this.reload().catch((e) => {
      this.$messages.error({ title: this.$t('message.error'), message: (e.response && e.response.body) ? e.response.body : e.message })
    })
    events.$on('change-language', () => {
      if (helpers.canLog(2)) console.log('[' + this.$options.name + '] invoked change language')
      this.reload(true)
        .then(() => { if (this.$refs.vuetable) this.$refs.vuetable.reload() })
    })
    events.$on('refresh-page', () => {
      if (helpers.canLog(2)) console.log('[' + this.$options.name + '] invoked refresh page')
      this.reload(true)
    })
    // window.onstorage = (e) => {
    //   if (helpers.canLog(2)) console.log('[' + this.$options.name + '] invoked storage event')
    //   this.reload(true)
    // }
  },
  watch: {
    lastUpdate: {
      handler: function () {
        if (this.loaded) {
          if (helpers.canLog(2)) console.log('[' + this.$options.name + '] reload due data timestamp update')
          if (this.$refs.vuetable) this.$refs.vuetable.reload()
        }
      }
    }
  }
}

export default CRUDListMixin