import { isAuthenticated } from './helpers'
import Cookies from 'js-cookie'
import axios from 'axios'
import { oAuth2Config } from '@/config/oauth2.config'
// import { events } from '@/lib/messages'

export default {
  namespaced: true,
  state: {
    authOk: false,
    user: null
  },
  getters: {
    isAuthOk: (state) => {
      if (!state.authOk && isAuthenticated()) {
        // controllo di non provenire da un F5 (reload pagina = perdita polling queue, etc)
        // events.$emit('logged-in')
        state.authOk = true
      }
      return state.authOk
    },
    id_token: (state) => {
      return Cookies.get('id_token')
    },
    access_token: (state, mutations) => {
      return Cookies.get('access_token')
    },
    user: (state) => {
      let user = JSON.parse(localStorage.getItem('oauth-user'))
      state.user = user
      return state.user
    },
    code: (state) => {
      return state.code
    },
    sessionState: (state) => {
      return state.session_state
    }
  },

  mutations: {
    resetAuth (state) {
      Cookies.remove('id_token')
      Cookies.remove('access_token')
      Cookies.remove('expires_in')
      state.user = null
      localStorage.removeItem('oauth-user')
      // state.session_state = null
      state.authOk = isAuthenticated()
    },
    setUser (state, value) {
      localStorage.setItem('oauth-user', JSON.stringify(value))
      state.user = value
    },
    // eslint-disable-next-line
    setAuth (state, { id_token, access_token, expires_in }) {
      let expireDays = parseInt(expires_in) / 60 / 24
      // console.log('[store] setting auth', /* id_token, access_token, */ expires_in, expireDays)
      // eslint-disable-next-line
      // state.id_token = id_token
      Cookies.set('id_token', id_token, { expires: expireDays, secure: false })
      // eslint-disable-next-line
      // state.access_token = access_token
      Cookies.set('access_token', access_token, { expires: expireDays, secure: false })
      // eslint-disable-next-line
      // state.expires_in = expires_in
      Cookies.set('expires_in', expires_in, { expires: expireDays, secure: false })
      // eslint-disable-next-line
      // state.session_state = (session_state) ? session_state : null
      state.authOk = isAuthenticated()
      // console.log('check', Cookies.get('id_token'), Cookies.get('access_token'))
    }
  },

  actions: {
    checkAccess (context, route) {
      return new Promise(resolve => {
        let hasAccess = true
        let isTokenExpired = isAuthenticated()
        let user = context.getters.user
        if (!user) {
          if (isTokenExpired) {
            context.commit('unsetAuth')
          }
          if (!route.meta.isPublic) {
            console.log('route is public')
            hasAccess = false
          }
        }
        resolve(hasAccess)
      })
    },
    userInfo (context) {
      return new Promise((resolve, reject) => {
        if (context.getters.user !== null) {
          resolve(context.getters.user)
        } else {
          let url = oAuth2Config.userInfoUri
          let config = {
            headers: { 'Authorization': 'Bearer ' + context.getters.access_token }
          }
          console.log(url, config)
          axios.get(url, config)
            .then((userData) => {
              console.log('UserInfo', userData)
              context.commit('setUser', userData.data)
              resolve(userData.data)
            }).catch((e) => {
              reject(e)
            })
        }
      })
    }
  }
}