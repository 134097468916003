import ApiReusableVuexModule from '@/lib/api/ApiReusableVuexModule'

const services = {
  namespaced: true,
  state: () => {
    return {
      list: null,
      last_update: null,
      serviceTypes: null,
      routingSystem: null
    }
  },
  getters: {
    one: (state) => (index) => { return ApiReusableVuexModule.getters.one(state)(index) },
    all: (state) => { return ApiReusableVuexModule.getters.all(state) },
    lastUpdate: (state) => { return ApiReusableVuexModule.getters.lastUpdate(state) },
    allTypes: (state) => {
      return state.serviceTypes
    },
    allRoutingSystem: (state) => {
      return state.routingSystem
    },
    oneServiceTypeByType: (state) => (type) => {
      if (state.serviceTypes && state.serviceTypes.length > 0) {
        return state.serviceTypes.filter(serviceType => serviceType.type === type)[0]
      }
      return null
    },
    oneRoutingSystemByValue: (state) => (routingSystem) => {
      if (state.routingSystem && state.routingSystem.length > 0) {
        return state.routingSystem.filter(item => item.routingSystem === routingSystem)[0]
      }
      return null
    },
    oneServiceById: (state) => (id) => {
      if (state.list && state.list.length > 0) {
        return state.list.filter(service => service.id === id)[0]
      }
      return null
    },
    serviceByName: (state) => (name) => {
      return state.list.filter(itm => itm.name === name)
    }
  },
  mutations: { ...ApiReusableVuexModule.mutations,
    ...{
      addTypes (state, payload) {
        state.serviceTypes = payload
      },
      addRoutingSystem (state, payload) {
        state.routingSystem = payload
      }
    }
  },
  actions: {
    load: (context, force = false) => {
      return ApiReusableVuexModule.actions.load(context, { path: 'Services.Services_List', force: force })
    },
    loadTypes: (context, force = false) => {
      if (context.state.serviceTypes && context.state.serviceTypes.length > 0 && !force) {
        return new Promise((resolve) => {
          resolve(context.state.serviceTypes)
        })
      }
      return ApiReusableVuexModule.actions.load(context, { path: 'Services.Services_ServiceTypeList', method: 'addTypes' })
    },
    loadRoutingSystem: (context, force = false) => {
      if (context.state.routingSystem && context.state.routingSystem.length > 0 && !force) {
        return new Promise((resolve) => {
          resolve(context.state.routingSystem)
        })
      }
      return ApiReusableVuexModule.actions.load(context, { path: 'Services.Services_RoutingSystemList', method: 'addRoutingSystem' })
    },
    clone: (context, service) => {
      return context.dispatch('action', { path: 'Services.Services_Clone', parameters: { service: service } })
    },
    update: (context, service) => {
      return context.dispatch('action', { path: 'Services.Services_Update', parameters: { service: service } })
    },
    new: (context, service) => {
      return context.dispatch('action', { path: 'Services.Services_Create', parameters: { service: service } })
    },
    action: (context, { path, parameters = null }) => {
      return ApiReusableVuexModule.actions.action(context, { path, parameters })
    }
  }
}

export default services