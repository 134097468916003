<template>
  <v-popover :placement="placement">
    <help-circle-outline-icon class="inline-help-icon tooltop-target"
                              :title="''"></help-circle-outline-icon>
    <template slot="popover">
      <div v-html="content"></div>
    </template>
  </v-popover>
</template>

<script>
import HelpCircleOutlineIcon from 'vue-material-design-icons/HelpCircleOutline'
import HelpIcon from 'vue-material-design-icons/Help'

export default {
  name: 'InlineHelp',
  components: { HelpIcon, HelpCircleOutlineIcon },
  data: () => {
    return {
      show: false
    }
  },
  props: {
    content: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      default: 'auto-start'
    },
    isHover: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
  .v-popover {
    line-height: 1rem;
    display: inline;

    .inline-help-icon {
      color: $slate-grey;
      cursor: pointer;

      &:hover {
        color: $black;
      }

      &.small {
        font-size: .8rem;
        margin-bottom: .1rem;
      }

      &.material-design-icon svg {
        margin-bottom: 0!important;
      }
    }

    &.text-white {
      .inline-help-icon {
        color: $white;
      }
    }
  }
</style>