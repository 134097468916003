<template>
  <div class="container home">
    <div class="row">
      <div class="col-sm">
        <img class="logo" alt="ecoprogetti" src="~@/assets/logo.svg" />
      </div>
      <div class="col-sm">
      </div>
      <div class="col-sm">
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-auto text-center">
        <h1>Ecoprogetti DCS Client</h1>
        <h4>v. {{ cliVersion }}</h4>
        <!--<h5>DCS Server v. {{ srvVersion }}</h5>-->
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-auto text-center">
        <router-link :to="{ name: 'login' }" class="login-message" v-if="!isAuthenticated">
          <login-variant-icon></login-variant-icon>{{ $t('message.login') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginVariantIcon from 'vue-material-design-icons/LoginVariant'
import { clearApiDef } from '../lib/api'
import { isAuthenticated } from '../lib/oauth2'

export default {
  name: 'Home',
  components: { LoginVariantIcon },
  computed: {
    ...mapGetters({
      cliVersion: 'appVersion'
    }),
    srvVersion: () => { return process.env.VUE_APP_SRV_VERSION }
  },
  mounted () {
    if (!isAuthenticated()) {
      clearApiDef()
    }
  }
}
</script>

<style lang="scss">
.home {
  min-height: calc(100vh - 105px);

  .row {
    padding: 3vw 0;
  }

  .logo {
    max-width: 250px;
  }
  .login-message {
    font-size: 2em;
    text-transform: capitalize;
  }

}
</style>