<template>
  <div class="component-toolslist">
    <div class="row detail-row" v-if="loaded">
      <div class="col-md-12 tools">
        <div class="card" v-if="tools.length > 0">
          <div class="card-header text-uppercase">
            <div>{{ $t('layout.edit.machine-edit-tools') }}</div>
          </div>
          <div class="card-body list nopadding">
            <table class="table table-bordered table-hover table-sm">
              <tbody>
              <tr v-for="(tool, key) in tools" v-bind:key="key">
                <td @click="visit(tool.url)">
                  {{ tool.text }}
                </td>
                <td @click="visit(tool.url)">
                  {{ tool.url }}
                </td>
              </tr>
              </tbody>
            </table>
            <p class="tools-entry" ></p>
          </div>
        </div>
        <p class="loading text-center" v-if="tools.length === 0">{{ $t('layout.list.no-data') }}</p>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12 text-center">
        <p class="loading">{{ $t('layout.text.loading') }}...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'toolsList',
  data () {
    return {
      tools: [],
      loaded: false
    }
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions({
      getTools: 'machines/tools'
    }),
    visit (url) {
      window.open(url, '_blank')
    },
    loadData () {
      this.getTools(this.id).then((data) => {
        this.tools = JSON.parse(data)
      }).finally(() => {
        this.$emit('loaded')
        this.loaded = true
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
  .loading {
    margin: 3em 0;
  }

  .card-body {
    height: 10em;
  }

  .operations {
    .card-header {
      background-color: $heater;
    }
  }

  .logs {
    .card-header {
      background-color: $dark-grey;
    }
  }

  .list {
    overflow-y: scroll;
  }

  table {
    td {
      cursor: pointer;
    }
  }

  .card {
    margin-top: 1.5rem;
  }

  /deep/ .refresher {
    margin-bottom: .3rem;
  }

</style>