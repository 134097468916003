import vuetablecss from '@/config/vuetable2.config.js'
import { has, isEqual, isMatch, orderBy, slice } from 'lodash'
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue'
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { events } from '@/lib/messages'

Vue.component('vuetable-field-checkbox', VuetableFieldCheckbox)
const VuetableMixin = {
  data () {
    return {
      rowsPerPageList: [ 5, 15, 30, 60, 120 ],
      vuetableloading: false,
      pendingoperation: null,
      tableStyle: vuetablecss,
      selectedRows: [],
      loaded: false,
      filters: {}, // { 'machineId': 1, 'type': 'WorkCompletedFailed' }
      sortOrder: []
    }
  },
  computed: {
    ...mapGetters({
      tableRows: 'settings/tableRows'
    }),
    rowsPerPage () {
      return this.tableRows(this.$options.name)
    },
    // restituisce un oggetto con le sole chiavi settate
    valorizedFilters () {
      let normalized = this.findValorized(this.filters, this.defaultFilters)
      if (this.$canLog(2)) console.log('[VueTableMixin] applied filters', normalized)
      return normalized
    },
    hasFilters () {
      return !(isEqual(this.filters, this.defaultFilters))
    }
  },
  methods: {
    ...mapMutations({
      setTableRows: 'settings/tableRows'
    }),
    findValorized (filters, defaultFilters) {
      if (this.filters === {} && defaultFilters === {}) {
        return []
      }
      // estrae i valori che sono diversi dal default
      return Object.keys(filters).reduce((object, key) => {
        if (filters[key] !== defaultFilters[key]) {
          object[key] = this.filters[key]
        }
        return object
      }, {})
    },
    onCheckboxToggled (event) {
      if (this.$canLog(2)) console.log('[VueTableMixin] checkboxToggled', this.$refs.vuetable.selectedTo)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    onCheckboxToggledAll () {
      if (this.$canLog(2)) console.log('[VueTableMixin] checkboxToggledAll', this.$refs.vuetable.selectedTo)
      this.selectedRows = this.$refs.vuetable.selectedTo
    },
    onPaginationData (paginationData) {
      if (this.$refs.pagination) {
        this.$refs.pagination.setPaginationData(paginationData)
      }
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onRowClass (dataItem, index) {
      return ''
    },
    filterData (data, filters) {
      if (this.$canLog(2)) console.log('[VueTableMixin] filterdatamixin')
      return data.filter((el) => {
        return isMatch(el, filters)
      })
    },
    dataManager (sortOrder, pagination) {
      console.log(this.data)
      if (typeof this.data === 'undefined' || this.data === null) {
        return []
      }
      if (this.data.length < 1) return []

      // filters and copy
      let local = this.filterData(this.data, this.valorizedFilters)
      if (this.$canLog(4)) console.log(this.data, local)

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        if (this.$canLog(2)) console.log('[VueTableMixin] orderBy:', sortOrder[0].sortField, sortOrder[0].direction)
        local = orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        )
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.rowsPerPage
      )
      if (this.$canLog(2)) console.log('[VueTableMixin] pagination:', pagination)
      let from = pagination.from - 1
      let to = from + this.rowsPerPage
      if (this.rowsPerPage === 0) {
        from = 0
        to = local.length - 1
      }

      return {
        pagination: pagination,
        data: this.rowsPerPage === 0 ? local : slice(local, from, to)
      }
    }
  },
  mounted () {
    if (has(this, 'reload')) {
      events.$on('change-language', () => {
        if (this.$canLog(2)) console.log('[' + this.$options.name + '] invoked change language')
        this.reload(true)
          .then(() => {
            if (this.$refs.vuetable) this.$refs.vuetable.reload()
          })
      })
      events.$on('refresh-page', () => {
        if (this.$canLog(2)) console.log('[' + this.$options.name + '] invoked refresh page')
        this.reload(true)
      })
    }
    if (has(this, 'load')) {
      this.$broadcast().onmessage = (message) => {
        if (message === this.$options.name) {
          this.load(true)
            .then(() => {
              if (this.$refs.vuetable) this.$refs.vuetable.refresh()
            })
        }
      }
    }
  },
  watch: {
    valorizedFilters: {
      handler: function () {
        Vue.nextTick(() => {
          if (this.loaded) {
            if (this.$refs.vuetable) this.$refs.vuetable.refresh()
          }
        })
      }
    },
    rowsPerPage: {
      handler: function () {
        Vue.nextTick(() => {
          if (this.loaded) {
            this.$refs.vuetable.changePage(1)
          }
        })
      }
    },
    vuetableloading: {
      handler: function (value, oldValue) {
        if (!value && oldValue && this.pendingoperation) {
          if (this.$canLog(2)) console.log('[VueTableMixin] performing ' + this.pendingoperation)
          this[this.pendingoperation]()
          this.pendingoperation = null
        }
      }
    },
    lastUpdate: {
      handler: function (value, oldValue) {
        if (this.$canLog(2)) console.log('[VueTableMixin] lastUpdate changed')
        if (value !== oldValue) {
          if (this.$refs.vuetable) this.$refs.vuetable.refresh()
        }
      }
    }
  }
}

export default VuetableMixin